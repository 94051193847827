import React from "react";
import classes from "./styles.module.css";
import { Text, Card, Layout } from "@shopify/polaris";
function GetInTouchBar() {
  return (
    <Layout>
      <Layout.Section>
        <Card sectioned>
          <div className={classes.getInTouchBarCss}>
            <Text variant="headingXs" as="h6" fontWeight="semibold">
            Would you like to remove the branding? Contact our 24x7 Live Chat team below
            </Text>
          </div>
        </Card>
      </Layout.Section>
    </Layout>
  );
}

export default GetInTouchBar;
