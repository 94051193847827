import {
    Card,
    Icon,
    InlineStack,
    Layout,
    Page,
    Text,
    Tooltip,
} from "@shopify/polaris";
import React, { useEffect } from "react";
import CountWidget from "./CountWidget/index";
import CountDownWidget from "./CountDownWidget/index";
import { InfoIcon, LockFilledIcon } from "@shopify/polaris-icons";
import WidgetTemplates from "./WidgetTemplates";
import Tabs from "../Tabs";
import { useAppBridge } from "@shopify/app-bridge-react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardData } from "../../slices/SoldCounterSlices/dashboardSlice";
import { get } from "lodash";

const Customize = () => {
    const app = useAppBridge();
    const dispatch = useDispatch();
    const dashboardData = useSelector((state) => state.dashboard);
    const dashboardFetchStatus = get(dashboardData, "fetchStatus", "IDLE");
    const plan = get(dashboardData, "data.shop.plan", "");

    useEffect(() => {
        dispatch(fetchDashboardData({ app }));
    }, []);

    console.log(dashboardData, dashboardFetchStatus, plan, "from customize>>")

    const decideTabsToRender = () => {
        const tabData = [
            {
                label: "Customize count widget",
                content: (
                    <CountWidget
                        dashboardData={dashboardData}
                        dashboardFetchStatus={dashboardFetchStatus}
                    />
                ),
                disabled: false,
                icon: null,
                tooltip: false,
            },
            {
                label: "Customize countdown widget",
                content: (
                    <CountDownWidget
                        dashboardData={dashboardData}
                        dashboardFetchStatus={dashboardFetchStatus}
                    />
                ),
                disabled: plan === "BASIC" ? true : false,
                icon: plan === "BASIC" ? <Icon source={LockFilledIcon} /> : null,
                tooltip: true,
            },
        ];

        return tabData;
    };

    return (
        <Page>
            <Layout>
                <Layout.Section>
                    <Card>
                        <InlineStack gap={200}>
                            <Text variant="headingMd" as="h6">
                                Pre-configured Templates
                            </Text>
                            <Tooltip content="The numbers in this template are placeholders and will be updated with your inventory">
                                <Icon source={InfoIcon} />
                            </Tooltip>
                        </InlineStack>
                        <WidgetTemplates />
                    </Card>
                </Layout.Section>
                <Layout.Section>
                    <Card>
                        <div style={{ marginBottom: "12px" }}>
                            <Text variant="headingMd" as="h6">
                                Customize widget
                            </Text>
                        </div>
                        {dashboardFetchStatus === "RESOLVED" && (
                            <Tabs tabs={decideTabsToRender()} />
                        )}
                    </Card>
                </Layout.Section>
            </Layout>
        </Page>
    );
};

export default Customize;
