// store.js
import { configureStore } from '@reduxjs/toolkit';
import { getReducer } from "./utils/appConfig";

const store = () => configureStore({
    reducer: getReducer(window.location.href),
});


export default store;
