import React from 'react';
import { Text, InlineStack, Badge, BlockStack, Tooltip } from '@shopify/polaris'; // Import necessary UI components

const WrapWithLabel = ({ plusCustomer, label, children }) => {
  const renderLabel = () => {
    if (plusCustomer) {
      return <Text variant="bodyMd" as="p">{label}</Text>;
    } else {
      return (
        <Tooltip content="Upgrade to plus plan to use this feature">
        <InlineStack gap="100">
          <Text variant="bodyMd" as="p">{label}</Text>
          <Badge size='small' tone='attention'>Plus</Badge>
        </InlineStack>
        </Tooltip>
      );
    }
  };

  return (
    <BlockStack gap="050">
      {renderLabel()}
      {children}
    </BlockStack>
  );
};

export default WrapWithLabel;
