import React, { useEffect } from 'react';
import { fetchStatus } from '../../utils/constant';
import './style.css';
const WidgetRenderer = ({ mediaId,currentActivePreview,formValues,res}) => {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const view = {
          mobile: 'preview_mobile',
          desktop: 'preview_web'
        }

        // Replace widget with formValue
        const script = document.createElement("script");
        script.text = res.data.widgetCode;
        const jsCode = `
          var elements = document.getElementsByClassName('instagenie-modal');
          while(elements.length > 0){
              elements[0].parentNode.removeChild(elements[0]);
          }
          var widget = ${JSON.stringify(formValues)};
     
          var instagramData = ${JSON.stringify(res.data.shop.instagram)};
          var productsMap = ${JSON.stringify(res.data.productsMap)};
          var activeView = "${view[currentActivePreview]}"
          instaGenie.load(instagramData, widget, productsMap, activeView);
          ${mediaId ? `document.dispatchEvent(new CustomEvent("openPopup", {detail: {mediaId: '${mediaId}'}}));`: ``}
        `;

        script.text += jsCode;
        document.body.appendChild(script);

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if(res.fetchStatus===fetchStatus.resolved){
      fetchData();
    }
   
  }, [mediaId, currentActivePreview, formValues,res]);


  

  return (
  
      <div id="instagenie-feed"></div>

  )
};

export default WidgetRenderer;
