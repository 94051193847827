import { Badge, Button, Card, InlineStack, Layout, Page, Select, Text } from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import StockTable from "../StockTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchStatus } from "../../../../utils/constant";
import { get } from "lodash";
import { fetchDashboardData, updateSpecificProductSettings } from "../../slices/SoldCounterSlices/dashboardSlice";
import { useAppBridge, useToast } from "@shopify/app-bridge-react";
import { getShopifySessionToken } from "../../../../utils/authUtils";
import { Redirect } from '@shopify/app-bridge/actions';
import { ArrowUpIcon } from "@shopify/polaris-icons";
import classes from './styles.module.css';
import { FreePlanLimit } from "../Modal/FreePlanLimitModal";
const bulkActionsDetails = {
    enableWidget: { endpoint: "widget/enable", state: true },
    disableWidget: { endpoint: "widget/disable", state: false },
    enableCountdown: { endpoint: "countdown-widget/enable", state: true },
    disableCountdown: { endpoint: "countdown-widget/disable", state: false },
    enableAggregate: { endpoint: "aggregate-variant/enable", state: true },
    disableAggregate: { endpoint: "aggregate-variant/disable", state: false },
};
const Dashboard = () => {
    const app = useAppBridge();
    const redirect = Redirect.create(app);
    const dispatch = useDispatch();
    const { show } = useToast()
    const updateState = useSelector(
        (state) => state.dashboard.productSpecificUpdate.fetchStatus
    );
    const dashboardState = useSelector((state) => state.dashboard);
    const widgetEnabledCount = get(dashboardState, 'data.products.widgetEnabledCount', 0) || 0;
    const currentPlan = get(dashboardState, 'data.shop.plan', 'BASIC');
    const [pageSize, setPageSize] = useState(10);
    const [selectedBulkAction, setSelectedBulkAction] = useState("");
    const [selectedProductIds, setSelectedProductIds] = useState([]);
    const [productFetchStatus, setProductFetchStatus] = useState(
        fetchStatus.idle
    );
    const [refreshList, setRefreshList] = useState(0);
    const [totalProductLength, setTotalProductLength] = useState(0);
    const [product, setProduct] = useState([]);
    const [expandedRows, setExpandedRows] = useState({});
    const [searching, setSearching] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [widgetEnabledCountState, setWidgetEnabledCountState] = useState(widgetEnabledCount);
    const [showLoader, setShowLoader] = useState(false);
    const [showFreeLimitReached, setShowFreeLimitReached] = useState(false);


    const closeShowFreeLimitReachedModal = () => {
        setShowFreeLimitReached(false);
    }

    const handleSelectChange = (value) => {
        setPageSize(value);
    };

    const handleBulkAction = (val) => {
        setSelectedBulkAction(val);
        if (val) {
            if (currentPlan === 'BASIC' && selectedProductIds.length > 3) {
                setShowFreeLimitReached(true)
            } else {
                dispatch(
                    updateSpecificProductSettings({
                        app,
                        endpoint: bulkActionsDetails[val].endpoint,
                        payload: {
                            productIds: selectedProductIds,
                        },
                    })
                ).then(() => {
                    setSelectedBulkAction("");
                    setSelectedProductIds([]);
                    setRefreshList((prev) => prev + 1);
                    show('Products settings updated')
                }).catch((e) => {
                    show('Failed to update')
                })
            }
        }
    };

    useEffect(() => {
        setWidgetEnabledCountState(widgetEnabledCount);
    }, [widgetEnabledCount])

    useEffect(() => {
        dispatch(fetchDashboardData({ app }));
    }, [refreshList]);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                setProductFetchStatus(fetchStatus.pending);
                const bearerToken = await getShopifySessionToken(app);
                const response = await fetch(
                    `https://zegsu.com/shopify/sold-counter/product-list?authToken=${bearerToken}`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            search: { value: searchValue },
                            start: currentPage * pageSize,
                            length: pageSize,
                        }),
                    }
                );

                if (!response.ok) {
                    throw new Error("Error fetching products");
                }
                const data = await response.json();
                setTotalProductLength(data.recordsFiltered);
                setProduct(data.data);
                setProductFetchStatus(fetchStatus.resolved);
            } catch (error) {
                setProductFetchStatus(fetchStatus.rejected);
                console.error("Error fetching products:", error.message);
            }
        };

        fetchProducts();
    }, [searchValue, currentPage, refreshList, pageSize]);

    const upgradePlanHandler = async () => {
        const selectedPlanName = 'PRIME'
        setShowLoader(true);
        const token = await getShopifySessionToken(app);
        redirect.dispatch(Redirect.Action.REMOTE, {
            url:
                `https://zegsuapps.com/shopify/sold-counter/change-plan/${selectedPlanName}?authToken=${token}`
        })
    }

    return (
        <Page>
            <Layout>
                <Layout.Section>
                    <Card>
                        <InlineStack
                            direction="row"
                            align="space-between"
                            blockAlign="center"
                        >
                            <div className={classes.headingTextCss}>
                                <Text variant="headingMd" as="h6">
                                    Sold Count Dashboard
                                    <span style={{ paddingLeft: '4px' }}>
                                        {currentPlan === 'BASIC' ? <Badge tone="attention">FREE </Badge> : <Badge tone="success">Plus </Badge>}
                                    </span>
                                </Text>
                            </div>
                            <InlineStack direction="row" gap={200}>
                                {selectedProductIds.length > 0 && (
                                    <Select
                                        disabled={updateState === fetchStatus.pending}
                                        onChange={handleBulkAction}
                                        label="Bulk actions"
                                        labelInline
                                        value={selectedBulkAction}
                                        options={[
                                            {
                                                label: "Select option",
                                                value: "",
                                            },
                                            {
                                                label: "Enable widget for selected products",
                                                value: "enableWidget",
                                            },
                                            {
                                                label: "Disable widget for selected products",
                                                value: "disableWidget",
                                                disabled: currentPlan === 'BASIC'
                                            },
                                            {
                                                label: "Enable countdown widget for selected products",
                                                value: "enableCountdown",
                                                disabled: currentPlan === 'BASIC'
                                            },
                                            {
                                                label: "Disable countdown widget for selected products",
                                                value: "disableCountdown",
                                            },
                                            {
                                                label:
                                                    "Enable aggregate variant count for selected products",
                                                value: "enableAggregate",
                                            },
                                            {
                                                label:
                                                    "Disable aggregate variant count for selected products",
                                                value: "disableAggregate",
                                            },
                                        ]}
                                    />
                                )}
                                <Select
                                    label="Show entries"
                                    labelInline
                                    options={[
                                        {
                                            label: "10",
                                            value: "10",
                                        },
                                        {
                                            label: "15",
                                            value: "15",
                                        },
                                        {
                                            label: "25",
                                            value: "25",
                                        },
                                        {
                                            label: "50",
                                            value: "50",
                                        },
                                    ]}
                                    onChange={handleSelectChange}
                                    value={pageSize}
                                />
                                {currentPlan === 'BASIC' && <Button
                                    variant="primary"
                                    size="large"
                                    onClick={upgradePlanHandler}
                                    icon={ArrowUpIcon}
                                    loading={showLoader}
                                >Upgrade to plus</Button>}
                            </InlineStack>
                        </InlineStack>
                    </Card>
                </Layout.Section>
                <Layout.Section>
                    <StockTable
                        product={product}
                        selectedProductIds={selectedProductIds}
                        setSelectedProductIds={setSelectedProductIds}
                        pageSize={pageSize}
                        productFetchStatus={productFetchStatus}
                        totalProductLength={totalProductLength}
                        setSearchValue={setSearchValue}
                        setCurrentPage={setCurrentPage}
                        setSearching={setSearching}
                        currentPage={currentPage}
                        setExpandedRows={setExpandedRows}
                        searching={searching}
                        searchValue={searchValue}
                        expandedRows={expandedRows}
                        currentPlan={currentPlan}
                        widgetEnabledCountState={widgetEnabledCountState}
                    />
                </Layout.Section>
            </Layout>
            {showFreeLimitReached && <FreePlanLimit open={showFreeLimitReached} onClose={closeShowFreeLimitReachedModal} />}
        </Page>
    );
};

export default Dashboard;
