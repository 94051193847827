import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Select, Spinner, Card, Text, Banner } from "@shopify/polaris";
import classes from "./styles.module.css";
import {
  fetchUsers,
  submitUserSelection,
} from "../../../slices/InstaGenieSlices/accountModalSlice";
import { useAppBridge, useToast } from "@shopify/app-bridge-react";

const AccountLists = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const app = useAppBridge();
  const { show } = useToast();
  const [accountSelected, setAccountSelected] = useState("");
  const [linkedPages, setLinkedPages] = useState([]);
  const { pages, fetchStatus, submitFetchStatus } = useSelector(
    (state) => state.accountListModal
  );

  const fetchLinkedPages = () => {
    dispatch(fetchUsers({ app }));
  }
  useEffect(() => {
    // Fetch pages when modal is opened
    if (fetchStatus === "idle") {
      fetchLinkedPages();
    }
  }, [fetchStatus]);

  useEffect(() => {
    if (pages.length > 0) {
      const listWithInstaID = pages.map((page) => {
        return {
          label: page.igUserId ? page.name : `${page.name} - No instagram account linked`,
          value: page.igUserId ? page.igUserId : "",
          disabled: page.igUserId ? false : true,
        };
      });
      setLinkedPages(listWithInstaID);
    }
  }, [pages]);

  const handleUserSelection = (id) => {
    setAccountSelected(id);
  };

  const handleCancel = () => {
    onClose();
  };

  const handleSubmit = () => {
    if (pages && pages.length === 1) {
      const payload = {
        igUserId: pages[0].igUserId,
      };
      if (pages[0].igUserId) {
        dispatch(submitUserSelection({ app, payload })).then(() => {
          onClose(true);
        });
      } else {
        show('Nothing selected')
      }
    } else {
      const payload = {
        igUserId: accountSelected,
      };
      if (payload.igUserId) {
        dispatch(submitUserSelection({ app, payload })).then(() => {
          onClose(true);
        });
      } else {
        show('Nothing selected')
      }

    }
  };

  return (
    <Modal
      open={open}
      onClose={handleCancel}
      title="Select Account"
      primaryAction={{
        content: "Submit",
        onAction: handleSubmit,
        disabled: !accountSelected
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleCancel,
          disabled: fetchStatus === "pending",
        },
      ]}
    >
      {submitFetchStatus === "pending" && (
        <div className={classes.spinnerWrapperCss}>
          <Spinner accessibilityLabel="Loading" size="small" />
          <Text>Update is in progress</Text>
        </div>
      )}
      {submitFetchStatus === "rejected" && (
        <Banner
          title="Something went wrong while saving the information"
          tone="warning"
        />
      )}

      {submitFetchStatus === "resolved" && (
        <Banner title="Successfully updated" tone="success" />
      )}

      {fetchStatus === "pending" && submitFetchStatus !== 'pending' && (
        <div className={classes.spinnerWrapperCss}>
          <Spinner accessibilityLabel="Loading" size="small" />
          <Text>Fetching the linked pages</Text>
        </div>
      )}

      {fetchStatus === "resolved" && submitFetchStatus !== 'pending' && linkedPages.length > 0 &&
        <Card>
          <Select
            placeholder="Select"
            label="Connected pages"
            options={linkedPages}
            value={accountSelected}
            onChange={(value) => handleUserSelection(value)}
          />
        </Card>
      }

      {fetchStatus === 'resolved' && linkedPages.length === 0 && submitFetchStatus !== 'pending' && <Banner
        title="You do not manage any pages on Facebook that have Instagram account linked. You may unlink and add another account or attach the Instagram account to a Facebook page you manage."
        tone="info"
        onDismiss={() => { }}
      />}

      {fetchStatus === 'rejected' && submitFetchStatus !== 'pending' && <Banner
        title="Failed to fetch linked pages. Please try again"
        action={{ content: 'Try again', onAction: fetchLinkedPages }}
        tone="warning"
      ></Banner>}
    </Modal>
  );
};

export default AccountLists;
