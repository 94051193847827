import { Layout, Page } from "@shopify/polaris";
import React, { useEffect } from "react";
import GeneralSettings from "./GeneralSetting";
import WidgetDetails from "./WidgetDetails";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { fetchDashboardData } from "../../slices/SoldCounterSlices/dashboardSlice";
import { useAppBridge } from "@shopify/app-bridge-react";
import { fetchStatus } from "../../../../utils/constant";
const Settings = () => {
    const dispatch = useDispatch();
    const app = useAppBridge();
    const dashboardState = useSelector((state) => state.dashboard);
    const dashboardData = useSelector((state) => state.dashboard.data);
    const currentPlan = get(dashboardState, 'data.shop.plan', 'BASIC');
    const dashboardFetchStatus = get(dashboardState, "fetchStatus", fetchStatus.idle);
    const generalSettingUploadStatus = useSelector((state) => state.dashboard.updateSettingDetails.fetchStatus, fetchStatus.idle);

    const refreshDashboardData = () => {
        dispatch(fetchDashboardData({ app }))
    }
    useEffect(() => {
        refreshDashboardData()
    }, []);
    return (
        <Page>
            <Layout>
                <WidgetDetails
                    dashboardState={dashboardState}
                    dashboardFetchStatus={dashboardFetchStatus}
                    dashboardData={dashboardData}
                    refreshDashboardData={refreshDashboardData}
                />
                <Layout.Section>
                    <GeneralSettings
                        dashboardState={dashboardState}
                        dashboardFetchStatus={dashboardFetchStatus}
                        dashboardData={dashboardData}
                        refreshDashboardData={refreshDashboardData}
                        generalSettingUploadStatus={generalSettingUploadStatus}
                        currentPlan={currentPlan}
                    />
                </Layout.Section>
            </Layout>
        </Page>
    );
};

export default Settings;
