import React from "react";
import styles from "./styles.module.css";

const StockStatus = ({
    stock,
    sold,
    lowStockThreshold = 3000,
    showFireIcon = true,
    textVariant = "default",
}) => {
    console.log(textVariant, "vv");

    const renderStockMessage = () => {
        switch (textVariant) {
            case "orderSoon":
                return (
                    <p className={styles.remainingTextCss}>
                        Only <span className={styles.stockCount}>{stock}</span> Left in
                        stock - order soon
                    </p>
                );
            case "highDemand":
                return (
                    <p className={styles.remainingTextCss}>
                        {showFireIcon && <span className={styles.fireIcon}>🔥</span>} In
                        high demand! <span className={styles.stockCount}>{stock}</span> sold
                        in the last 24 hours.
                    </p>
                );
            case "remaining":
                return (
                    <p className={styles.remainingTextCss}>
                        Only <span className={styles.stockCount}>{stock}</span> remaining!
                    </p>
                );
            case "final":
                return (
                    <p className={styles.remainingTextCss}>
                        Final call ! Only {stock} left at this price
                    </p>
                );
            case "limited":
                return (
                    <p className={styles.remainingTextCss}>
                        Limited time offer: only{" "}
                        <span className={styles.stockCount}>{stock}</span> items left!
                    </p>
                );
            case "default":
            default:
                return (
                    <p className={styles.remainingTextCss}>
                        Only <span className={styles.stockCount}>{stock}</span> left in
                        stock
                    </p>
                );
        }
    };

    const renderCombinedMessage = () => {
        return (
            <p className={styles.remainingTextCss}>
                <span className={styles.stockCount}>{stock}</span> In stock/
                {showFireIcon && <span className={styles.fireIcon}>🔥</span>}{" "}
                <span className={styles.remainingTextCss}>{sold}</span> Sold
            </p>
        );
    };

    return (
        <div className={styles.stockContainer}>
            {textVariant !== "combined"
                ? renderStockMessage()
                : renderCombinedMessage()}
        </div>
    );
};

export default StockStatus;
