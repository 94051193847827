import React, { useEffect } from 'react';
import {
  Card,
  Form,
  FormLayout,
  TextField,
  Select,
  RangeSlider,
  Button,
  BlockStack,
  Text,
  InlineStack,
  Badge,
} from '@shopify/polaris';
import classes from './styles.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { resetFormDetails, updateWidgetDetails } from '../../../slices/InstaGenieSlices/updateFormSlice';
import { fetchStatus } from '../../../utils/constant';
import { fetchDashboardData } from '../../../slices/InstaGenieSlices/dashboardSlice';
import { useAppBridge, useToast } from '@shopify/app-bridge-react';
import WrapWithLabel from '../../Common/WrapWithLabel';

const InstaFeedDetailForm = (props) => {
  const {
    formValues,
    setFormValues,
    isEditMode,
    setIsEditMode,
    originalValues,
    showEditButton,
    setShowEditButton,
    currentPlan
  } = props;
  const app = useAppBridge();
  const { show } = useToast();
  const plusCustomer = currentPlan === 'PRIME';
  const dispatch = useDispatch();
  const formDetailsFetchStatus = useSelector((state) => state.formDetails.fetchStatus);
  const handleChangeMax = (field, value, max) => {
    if (!isNaN(value) && value > max) {
      handleChange(field, max);
    } else {
      handleChange(field, value);
    }
  };
  const handleChange = (field, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleSubmit = () => {
    const updateFormDetails = { ...formValues };
    delete updateFormDetails.checksum;
    dispatch(resetFormDetails());
    dispatch(updateWidgetDetails({ payload: { widget: updateFormDetails }, app })).then(() => {
      show('Configuration saved !')
      dispatch(fetchDashboardData({ app }));
    }).catch(() => {
      show('Something went wrong')
    });

  };

  useEffect(() => {
    if (formDetailsFetchStatus === fetchStatus.resolved) {
      if (showEditButton) {
        setShowEditButton(false);
      }
    }
  }, [formDetailsFetchStatus])

  const renderLayoutOptions = () => {
    switch (formValues.widgetType) {
      case 'GRID':
        return (
          <>
            <div className={classes.bottomGapCss}>
              <FormLayout.Group  >
                <TextField
                  readOnly={showEditButton}
                  label="Images per row (Mobile)"
                  type="number"
                  value={formValues.numImagesInRowMobile}
                  onChange={(value) =>
                      handleChangeMax('numImagesInRowMobile', parseInt(value || 0, 10), 6)
                  }
                  min={0}
                  max={6}
                />
                <TextField
                  readOnly={showEditButton}
                  label="Images per row (Desktop)"
                  type="number"
                  value={formValues.numImagesInRow}
                  onChange={(value) =>
                      handleChangeMax('numImagesInRow', parseInt(value || 0, 10), 6)
                  }
                  min={0}
                  max={6}
                />
              </FormLayout.Group>
            </div>
            <div className={classes.bottomGapCss}>
              <FormLayout.Group >
                <TextField
                  readOnly={showEditButton}
                  label="Number of rows (Mobile)"
                  type="number"
                  value={formValues.numRowsMobile}
                  onChange={(value) =>
                    handleChange('numRowsMobile', parseInt(value || 0, 10))
                  }
                  min={0}
                />
                <TextField
                  readOnly={showEditButton}
                  label="Number of rows (Desktop)"
                  type="number"
                  value={formValues.numRows}
                  onChange={(value) =>
                      handleChangeMax('numRows', parseInt(value || 0, 10), 10)
                  }
                  min={0}
                  max={10}
                />
              </FormLayout.Group>
            </div>
            <RangeSlider
              disabled={showEditButton}
              label="Space between images"
              value={formValues.spacing}
              onChange={(value) => handleChangeMax('spacing', value, 50)}
              min={1}
              max={50}
              output
            />
          </>
        );
      case 'SLIDER':
        return (
          <>
            <div className={classes.bottomGapCss}>
              <FormLayout.Group >
                <TextField
                  readOnly={showEditButton}
                  label="Number of images"
                  type="number"
                  value={formValues.numberImages}
                  onChange={(value) =>
                      handleChangeMax('numberImages', parseInt(value, 10), 50)
                  }
                  min={0}
                  max={50}
                />
              </FormLayout.Group>
            </div>
            <div className={classes.bottomGapCss}>
              <FormLayout.Group >
                <TextField
                  readOnly={showEditButton}
                  label="Number of rows (Mobile)"
                  type="number"
                  value={formValues.numRowsMobile}
                  onChange={(value) =>
                    handleChange('numRowsMobile', parseInt(value, 10))
                  }
                  min={0}
                />
                <TextField
                  readOnly={showEditButton}
                  label="Number of rows (Desktop)"
                  type="number"
                  value={formValues.numRows}
                  onChange={(value) =>
                    handleChange('numRows', parseInt(value, 10))
                  }
                  min={0}
                />
              </FormLayout.Group>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const handleEditConfiguration = () => {
    setShowEditButton(false);
    setIsEditMode(true)
  }

  const handleCancelEditMode = () => {
    setIsEditMode(false);
    setFormValues(originalValues);
    setShowEditButton(true)
  }

  return (
    <Card>
      <Form onSubmit={handleSubmit}>
        <FormLayout>
          {/* First Row */}
          <TextField
            label="Feed Title"
            value={formValues.feedTitle}
            onChange={(value) => handleChange('feedTitle', value)}
            readOnly={showEditButton}
          />
          {/* Second Row */}
          <FormLayout.Group spacing="loose">
            <Select
              disabled={showEditButton}
              label="Layout"
              options={[
                { label: 'Grid', value: 'GRID' },
                { label: 'Slider', value: 'SLIDER' },
              ]}
              value={formValues.widgetType}
              onChange={(value) => handleChange('widgetType', value)}
            />
            {/* Autoplay Dropdown */}
            <WrapWithLabel plusCustomer={plusCustomer} label="Autoplay">
              <Select
                options={[
                  { label: 'On', value: 'true' },
                  { label: 'Off', value: 'false' },
                ]}
                value={formValues.autoPlay ? 'true' : 'false'}
                onChange={(value) => handleChange('autoPlay', value === 'true')}
                disabled={plusCustomer ? showEditButton || formValues.widgetType === 'GRID' : true}
              />
            </WrapWithLabel>

          </FormLayout.Group>
          {/* Third and Fourth Rows */}
          {renderLayoutOptions()}
          <FormLayout.Group spacing="loose">
            <WrapWithLabel plusCustomer={plusCustomer} label="Show stories">
              <Select
                disabled={plusCustomer ? showEditButton : true}
                options={[
                  { label: 'Enabled', value: true },
                  { label: 'Disabled', value: false },
                ]}
                value={formValues.storiesEnabled}
                onChange={(value) => handleChange('storiesEnabled', value)}
              />
            </WrapWithLabel>
          </FormLayout.Group>
          {/* Submit Button */}
          <div className={classes.buttonWrapperCss}>
            {
              showEditButton ? <Button
                variant="primary"
                onClick={handleEditConfiguration}
              >
                Edit configuration
              </Button> :
                <>
                  <Button variant="primary" onClick={handleSubmit} loading={formDetailsFetchStatus === fetchStatus.pending}>
                    {isEditMode ? 'Update configuration' : 'Save configuration'}
                  </Button>
                  {isEditMode && (
                    <Button
                      variant="secondary"
                      onClick={handleCancelEditMode}  >
                      Cancel
                    </Button>
                  )}
                </>
            }

          </div>
        </FormLayout>
      </Form>
    </Card>
  );
};

export default InstaFeedDetailForm;
