import "./App.css";
import jQuery from "jquery";
import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import InstagenieRouter from "./apps/instagenie/components/InstagenieRouter/router";
import Version from "./components/Version/Version";
import SoldCounterRouter from "./apps/soldcounter/components/SoldCounterRouter/router";

function App() {
  useEffect(() => {
    window.zegsuJq = jQuery;
  }, []);

  return (
    <>
      <BrowserRouter basename="/app/shopify">
        <InstagenieRouter />
        <SoldCounterRouter />
      </BrowserRouter>
      <Version />
    </>
  );
}

export default App;
