export const fetchStatus = {
    idle: 'IDLE',
    pending: 'PENDING',
    resolved: 'RESOLVED',
    rejected: 'REJECTED'
}

export const apps = {
    INSTAGENIE: "INSTAGENIE",
    SOLDCOUNTER: "SOLDCOUNTER"
}


export const planMapping = {
    "sold-counter": {
        FREE: 'BASIC',
        PRIME: 'PRIME'
    },
    "instagenie": {
        FREE: 'FREE',
        PRIME: 'PRIME'
    }
}
