import { Frame, Modal, TextContainer } from '@shopify/polaris';
import { extractInfoForModal } from '../constant';
;

const ConfirmationDialog = (props) => {
    const { open, onClose, itemName, handleSubmit, active } = props;
    const { title, desc } = extractInfoForModal(itemName, active);
    return (
        <div style={{ height: '500px' }}>
            <Frame>
                <Modal
                    open={open}
                    onClose={onClose}
                    title={title}
                    primaryAction={{
                        content: 'Confirm',
                        onAction: () => handleSubmit(itemName),
                    }}
                    secondaryActions={[
                        {
                            content: 'Cancel',
                            onAction: onClose,
                        },
                    ]}
                >
                    <Modal.Section>
                        <TextContainer>
                            <p>
                                {desc ? desc : 'Are you sure ?'}
                            </p>
                        </TextContainer>
                    </Modal.Section>
                </Modal>
            </Frame>
        </div>
    );
}

export default ConfirmationDialog;