import {useEffect} from "react";
import {Outlet} from "react-router-dom";

const Base = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://cdn.jsdelivr.net/npm/flipdown@0.3.2/src/flipdown.min.js';
        script.async = true;

        document.body.appendChild(script);

        return () => {
            // Clean up the script when the component unmounts
            document.body.removeChild(script);
        };
    }, [])

    return <Outlet/>
}

export default Base