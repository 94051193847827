import React from "react";
import classes from "./styles.module.css";
import GetInTouchForm from "../GetInTouch";
import { Layout, Page, Text } from "@shopify/polaris";
import PageLoadingIndicator from "../../Loading";
const ContactUs = (props) => {
  const contactUsUploadStatus = { props };
  return (
    <Page>
      {contactUsUploadStatus === 'PENDING' && <PageLoadingIndicator />}
      <Layout>
        <Layout.Section>
          <Text variant="heading2xl" as="h3">
            Contact us
          </Text>

          <div className={classes.subHeadingWrapperCss}>
            <Text as="p" tone="subdued">
              Need help? Contact us by filling out the form below and we will get back ASAP!
            </Text>
          </div>
        </Layout.Section>
        <Layout.Section>
          <GetInTouchForm {...props} />
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default ContactUs;
