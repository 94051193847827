import React, { useState } from 'react';
import { Card, Badge, Text, Icon, Page, Layout } from '@shopify/polaris';
import { ChevronUpIcon, ChevronDownIcon } from '@shopify/polaris-icons';
import classes from './styles.module.css';
import GetInTouchBar from './GetInTouchBar';
import { useNavigate } from '@shopify/app-bridge-react'
import { getFAQ } from './constant';
import ManualInstallationCollapsible from '../../apps/soldcounter/components/Settings/ManualInstallation/index';
const FAQCard = ({ id, questionNumber, questionText, questionType, openCard, handleToggle, src }) => {
  return (
    <div className={classes.faqCard}>
      <div className={classes.faqHeader} onClick={() => handleToggle(id)}>
        <div className={classes.faqText}>
          <Text as="p" fontWeight='bold'>{`${questionNumber}: ${questionText}`}</Text>
          <div className={classes.faqBadge}>
            <Badge tone="info">{questionType}</Badge>
          </div>
        </div>
        <div className={classes.faqExpandIcon}>
          <Icon source={openCard === id ? ChevronUpIcon : ChevronDownIcon} />
        </div>
      </div>
      {openCard === id && (
        <div className={classes.faqContent}>
          <div class={classes.videoWrapperOuter}>
            <div class={classes.videoWrapperInner}>
              <iframe height="315" src={src} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const FAQSection = (props) => {
  const { appName } = props;
  const navigate = useNavigate();


  const handleContactUsRedirect = () => {
    navigate("/contact");
  }

  const [openCard, setOpenCard] = useState(null);

  const handleToggle = (id) => {
    setOpenCard(openCard === id ? null : id);
  };

  return (
    <Page>
      <Layout>
        <Layout.Section>
          <Text variant="heading2xl" as="h3">
            Help center
          </Text>
          <div className={classes.subHeadingWrapperCss}>
            <Text as="p" tone="subdued">
              Learn how to setup Sold Count App, fix a problem, and get answers to your questions.
            </Text>
          </div>
        </Layout.Section>
        <Layout.Section>
          <Card title="FAQ">
            {getFAQ(appName).map((faq) => (
              <FAQCard
                key={faq.id}
                {...faq}
                openCard={openCard}
                handleToggle={handleToggle}
              />
            ))}
          </Card>
        </Layout.Section>
        {appName === 'sold-counter' && <Layout.Section>
          <ManualInstallationCollapsible />
        </Layout.Section>
        }
        <Layout.Section>
          <GetInTouchBar
            btnHandler={handleContactUsRedirect}
            displayText="Didn't find what you were looking for? Contact our support team." btnText="Contact us" />
        </Layout.Section>
      </Layout>
    </Page>

  );
};

export default FAQSection;
