import React, { useState } from 'react';
import { Card, TextField, Form, Button, FormLayout } from '@shopify/polaris';
import classes from './styles.module.css';
import { useAppBridge, useToast } from '@shopify/app-bridge-react';
const GetInTouchForm = (props) => {
  const { contactUsUploadStatus, dispatch, contactUsDetails, resetContactFormDetails } = props;
  const app = useAppBridge();
  const { show } = useToast();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');

  const resetFormValues = () => {
    setName('');
    setEmail('');
    setSubject('');
    setDescription('');
  }
  const handleSubmit = () => {
    // Add your logic to handle the form submission here
    dispatch(resetContactFormDetails());
    dispatch(contactUsDetails({ payload: { subject, query: description, name, email }, app })).then(() => {
      show('Support ticket submitted')
    });
    resetFormValues();
  };

  const isButtonDisabled = !name || !email || !subject || !description;
  return (
    <>
      <Card sectioned>
        <Form onSubmit={handleSubmit}>
          <FormLayout>
            <FormLayout.Group>
              <TextField
                label="Name"
                value={name}
                onChange={(value) => setName(value)}
              />
              <TextField
                label="Email"
                type="email"
                value={email}
                onChange={(value) => setEmail(value)}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField
                label="Subject"
                value={subject}
                onChange={(value) => setSubject(value)}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField
                label="Description"
                multiline
                maxLength={500}
                value={description}
                onChange={(value) => setDescription(value)}
                rows={5}
              />
            </FormLayout.Group>
            <div className={classes.buttonWrapperCss}>
              <Button
                loading={contactUsUploadStatus === 'PENDING'}
                onClick={handleSubmit}
                variant='primary'
                disabled={isButtonDisabled}>
                Send Message
              </Button>
            </div>
          </FormLayout>
        </Form>
      </Card>
    </>
  );
};

export default GetInTouchForm;
