// authUtils.js
import { getSessionToken } from '@shopify/app-bridge-utils';

export const getShopifySessionToken = async (app) => {
    try {
      // Assuming you have access to app instance through useAppBridge
      const bearerToken = await getSessionToken(app);

      if (!bearerToken) {
        console.error('No token available');
        throw new Error('No token available');
      }

      return bearerToken;
    } catch (error) {
      console.error('Error fetching Shopify session token:', error);
      throw error;
    }
};
