import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { fetchStatus } from '../../utils/constant';
import { getShopifySessionToken } from '../../../../utils/authUtils';
import { INSTAGENIE_BASE_URL } from "../../../../settings";

const initialState = {
  data: null,
  fetchStatus: fetchStatus.idle,
  refreshFetchStatus: fetchStatus.idle,
  refreshData: null,
  error: null,
}

export const fetchDashboardData = createAsyncThunk(
  'content/fetchDashboard',
  async ({ sessionID, app }) => {
    try {
      const bearerToken = await getShopifySessionToken(app)
      const res = await axios.get(`${INSTAGENIE_BASE_URL}/v2/dashboard?authToken=${bearerToken}`, {
        params: {
          sessionID: sessionID
          // Add other query parameters if needed
        },
        ...(bearerToken && { headers: { Authorization: `Bearer ${bearerToken}` } }),
      });
      const data = await res.data
      return data
    } catch (error) {
      throw error;
    }
  }
)

export const refreshState = createAsyncThunk(
  'content/refreshState',
  async ({ app }) => {
    try {
      const bearerToken = await getShopifySessionToken(app)
      const res = await axios.get(`${INSTAGENIE_BASE_URL}/trigger-refresh?authToken=${bearerToken}`);
      const data = await res.data
      return data
    } catch (error) {
      throw error;
    }
  }
)

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDashboardData.pending, (state) => {
      state.fetchStatus = fetchStatus.pending
    })
    builder.addCase(fetchDashboardData.fulfilled, (state, action) => {
      state.fetchStatus = fetchStatus.resolved
      state.data = action.payload
    })
    builder.addCase(fetchDashboardData.rejected, (state, action) => {
      state.fetchStatus = fetchStatus.rejected
      state.error = action.error.message
    })
    builder.addCase(refreshState.pending, (state) => {
      state.refreshFetchStatus = fetchStatus.pending
    })
    builder.addCase(refreshState.fulfilled, (state, action) => {
      state.refreshFetchStatus = fetchStatus.resolved
      state.refreshData = action.payload
    })
    builder.addCase(refreshState.rejected, (state, action) => {
      state.refreshFetchStatus = fetchStatus.rejected
      state.error = action.error.message
    })
  },
})

export default dashboardSlice.reducer