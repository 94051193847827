export const generalSettings = [
    {
        name: "Enable for all products",
        tooltipContent: "Enable for all products",
        key: "enableApp",
        component: 'button',
        btnText: 'Enable',
        endpoint: '/widget/enable'
    },
    {
        name: "Disable for all products",
        tooltipContent: "Disable for all products",
        key: "disableApp",
        component: 'button',
        btnText: 'Disable',
        endpoint: '/widget/disable'
    },
    {
        name: "Enable variant aggregation for all products",
        tooltipContent: "Enable variant aggregation for all products. ",
        key: "enableVariantAggr",
        component: 'button',
        btnText: 'Enable',
        endpoint: '/aggregate-variant/enable'
    },
    {
        name: "Disable variant aggregation for all products",
        tooltipContent: "Disable variant aggregation for all products. ",
        key: "disableVariantAggr",
        component: 'button',
        btnText: 'Disable',
        endpoint: '/aggregate-variant/disable'
    },
    // {
    //     name: "Use available stock from shopify for all product",
    //     tooltipContent: "Use available stock from shopify for all product",
    //     key: "useAvailableStock",
    // },
    // {
    //     name: "Enable sold count for all products",
    //     tooltipContent: "Enable sold count for all products ",
    //     key: "enableSoldCount",
    // },
];

export const WIDGET_POSITION = {
    ABOVE_BUY_BUTTON: "ABOVE_BUY_BUTTON",
    ABOVE_FIRST_INPUT: "ABOVE_FIRST_INPUT",
    BELOW_BUY_SECTION: "BELOW_BUY_SECTION",
    BELOW_TITLE: "BELOW_TITLE",
};


export const extractInfoForModal = (key, active) => {
    const item = generalSettings.filter(({ key: itemName }) => itemName === key);
    if (item.length > 0) {
        const title = item[0].name;
        return {
            title: title,
            desc: item[0].desc ? item[0].desc : "",
        };
    } else {
        return {
            title: "",
            desc: "",
        };
    }
};

export const widgetPositionOptions = [
    {
        label: `Above 'Buy' Section`,
        value: WIDGET_POSITION.ABOVE_FIRST_INPUT
    },
    {
        label: `Above 'Buy'/'Add to cart' button`,
        value: WIDGET_POSITION.ABOVE_BUY_BUTTON,
    },
    {
        label: "Below product title",
        value: WIDGET_POSITION.BELOW_TITLE,
    },
    {
        label: `Below 'Buy' Section`,
        value: WIDGET_POSITION.BELOW_BUY_SECTION,
    },
];

export const toggleOptions = [
    { label: "ON", value: true },
    { label: "OFF", value: false },
];

export const showSoldCountFromOptions = [
    { label: "Last 7 days", value: "7" },
    { label: "Last 15 days", value: "15" },
    { label: "Last 30 days", value: "30" },
    { label: "Last 45 days", value: "45" },
    { label: "Last 2 months", value: "60" },
];

export const getFieldValue = (
    editMode,
    keyName,
    originalValues,
    editValues
) => {
    if (editMode) {
        return editValues[keyName] ? editValues[keyName] : "";
    } else {
        return originalValues[keyName] ? originalValues[keyName] : "";
    }
};


export const checkValue = (value) => {
    if (typeof value === 'boolean') {
        return value;
    } else if (value === 'true') {
        return true;
    } else if (value === 'false') {
        return false;
    } else {
        return value
    }
}
