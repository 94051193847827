import {Frame, Loading} from '@shopify/polaris';
import React from 'react';

const PageLoadingIndicator=()=> {
  return (
    <div>
      <Frame>
        <Loading />
      </Frame>
    </div>
  );
}

export default PageLoadingIndicator;