import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { fetchStatus } from '../../../../utils/constant';
import { SOLD_COUNTER_APP_BASE_URL } from '../../../../settings';
import { getShopifySessionToken } from '../../../../utils/authUtils';

const initialState = {
    data: null,
    fetchStatus: fetchStatus.idle,
    error: null,
    updateSettingDetails: {
        fetchStatus: fetchStatus.idle,
        data: null,
        error: null
    },
    productSpecificUpdate: {
        fetchStatus: fetchStatus.idle,
        data: null,
        error: null
    }
}

export const fetchDashboardData = createAsyncThunk(
    'content/fetchDashboard',
    async ({ sessionID, app }) => {
        try {
            const bearerToken = await getShopifySessionToken(app);
            const response = await axios.get(`${SOLD_COUNTER_APP_BASE_URL}/v2/dashboard`, {
                params: {
                    sessionID: sessionID,
                    authToken: bearerToken,
                    // Add other query parameters if needed
                },
                headers: bearerToken ? { Authorization: `Bearer ${bearerToken}` } : {},
            });
            return response.data;
        } catch (error) {
            throw new Error(error.response ? error.response.data : error.message);
        }
    }
);

export const updateCustomizeWidget = createAsyncThunk(
    'updateCustomizeWidget',
    async ({ payload, sessionID, app }) => {
        try {
            const bearerToken = await getShopifySessionToken(app)
            const response = await axios.put(
                `${SOLD_COUNTER_APP_BASE_URL}/setting/widget`,
                payload,
                {
                    params: {
                        sessionID: sessionID,
                        authToken: bearerToken,
                    },
                    headers: bearerToken ? { Authorization: `Bearer ${bearerToken}` } : {},
                },
            );
            return response.data;
        } catch (error) {
            throw error.response ? error.response.data : error.message;
        }
    }
)


export const updateSettingDetails = createAsyncThunk(
    'updateSettingDetails',
    async ({ payload, sessionID, app }) => {
        try {
            const bearerToken = await getShopifySessionToken(app);
            const response = await axios.put(
                `${SOLD_COUNTER_APP_BASE_URL}/setting/widget/setting`,
                payload,
                {
                    params: {
                        sessionID: sessionID,
                        authToken: bearerToken,
                    },
                    headers: bearerToken ? { Authorization: `Bearer ${bearerToken}` } : {},
                }
            );
            return response.data;
        } catch (error) {
            console.log(error);
            throw new Error(error.response ? error.response.data : error.message);
        }
    }
);

export const updateSpecificSettingDetails = createAsyncThunk(
    'updateSpecificSettingDetail',
    async ({ sessionID, endpoint, app, payload }) => {
        try {
            const bearerToken = await getShopifySessionToken(app);
            const response = await axios.post(
                `${SOLD_COUNTER_APP_BASE_URL}/products/multi${endpoint}`,
                payload,
                {
                    params: {
                        sessionID: sessionID,
                        authToken: bearerToken,
                    },
                    headers: bearerToken ? { Authorization: `Bearer ${bearerToken}` } : {},
                }
            );
            return response.data;
        } catch (error) {
            console.log(error, "error--->");
            throw new Error(error.response ? error.response.data : error.message);
        }
    }
);


export const updateSpecificProductSettings = createAsyncThunk(
    'updateSpecificProductSettings',
    async ({ sessionID, endpoint, app, payload }) => {
        try {
            const bearerToken = await getShopifySessionToken(app);
            const response = await axios.post(
                `${SOLD_COUNTER_APP_BASE_URL}/products/multi/${endpoint}`,
                payload,
                {
                    params: {
                        sessionID: sessionID,
                        authToken: bearerToken,
                    },
                    headers: bearerToken ? { Authorization: `Bearer ${bearerToken}` } : {},
                }
            );
            return response.data;
        } catch (error) {
            throw new Error(error.response ? error.response.data : error.message);
        }
    }
);


export const soldDashboardSlice = createSlice({
    name: 'soldStockDashboard',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchDashboardData.pending, (state) => {
            state.fetchStatus = fetchStatus.pending
        })
        builder.addCase(fetchDashboardData.fulfilled, (state, action) => {
            state.fetchStatus = fetchStatus.resolved
            state.data = action.payload
        })
        builder.addCase(fetchDashboardData.rejected, (state, action) => {
            state.fetchStatus = fetchStatus.rejected
            state.error = action.error.message
        })

        builder.addCase(updateCustomizeWidget.pending, (state) => {
            state.fetchStatus = fetchStatus.pending
        })
        builder.addCase(updateCustomizeWidget.fulfilled, (state, action) => {
            state.fetchStatus = fetchStatus.resolved
            state.data = action.payload
        })
        builder.addCase(updateCustomizeWidget.rejected, (state, action) => {
            state.fetchStatus = fetchStatus.rejected
            state.error = action.error.message
        })

        builder.addCase(updateSettingDetails.pending, (state) => {
            state.updateSettingDetails.fetchStatus = fetchStatus.pending
        })
        builder.addCase(updateSettingDetails.fulfilled, (state, action) => {
            state.updateSettingDetails.fetchStatus = fetchStatus.resolved
            state.updateSettingDetails.data = action.payload
        })
        builder.addCase(updateSettingDetails.rejected, (state, action) => {
            state.updateSettingDetails.fetchStatus = fetchStatus.rejected
            state.updateSettingDetails.error = action.error.message
        })

        builder.addCase(updateSpecificSettingDetails.pending, (state) => {
            state.updateSettingDetails.fetchStatus = fetchStatus.pending
        })
        builder.addCase(updateSpecificSettingDetails.fulfilled, (state, action) => {
            state.updateSettingDetails.fetchStatus = fetchStatus.resolved
            state.updateSettingDetails.data = action.payload
        })
        builder.addCase(updateSpecificSettingDetails.rejected, (state, action) => {
            state.updateSettingDetails.fetchStatus = fetchStatus.rejected
            state.updateSettingDetails.error = action.error.message
        })

        builder.addCase(updateSpecificProductSettings.pending, (state) => {
            state.productSpecificUpdate.fetchStatus = fetchStatus.pending
        })
        builder.addCase(updateSpecificProductSettings.fulfilled, (state, action) => {
            state.productSpecificUpdate.fetchStatus = fetchStatus.resolved
            state.productSpecificUpdate.data = action.payload
        })
        builder.addCase(updateSpecificProductSettings.rejected, (state, action) => {
            state.productSpecificUpdate.fetchStatus = fetchStatus.rejected
            state.productSpecificUpdate.error = action.error.message
        })
    },
})

export default soldDashboardSlice.reducer