import {useEffect} from "react";

const ChatSupport = () => {
    useEffect(() => {
        const script = document.createElement("script");

        script.src = "https://121chatagent.com/180027392";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, [])

    return (<></>)
}

export default ChatSupport