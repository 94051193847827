import { apps } from "./constant";
import { INSTAGENIE_APP_SHOPIFY_API_KEY, SOLD_COUNTER_APP_SHOPIFY_API_KEY } from "../settings";
import { InstageniePageNavigation, SoldCounterPageNavigation } from "../apps/instagenie/components/Navigation";
import instagenieReducer from "../apps/instagenie/reducer/instagenieReducer";
import soldCounterReducer from "../apps/soldcounter/reducer/soldCounterReducer";



export const appFromURL = (url) => {
    if (url === undefined) {
        return "unknown";
    }
    if (url.includes("/instagenie")) {
        return apps.INSTAGENIE;
    }
    if (url.includes("/sold-counter")) {
        return apps.SOLDCOUNTER
    }
    return "unknown";
}


export const getReducer = (url) => {
    switch (appFromURL(url)) {
        case apps.INSTAGENIE:
            return instagenieReducer
        case apps.SOLDCOUNTER:
            return soldCounterReducer
        default:
            throw new Error(`store not configured for ${url}`)
    }
}

export const getShopifyAPIKey = (url) => {
    switch (appFromURL(url)) {
        case apps.INSTAGENIE:
            return INSTAGENIE_APP_SHOPIFY_API_KEY
        case apps.SOLDCOUNTER:
            return SOLD_COUNTER_APP_SHOPIFY_API_KEY
        default:
            throw new Error(`shopify api key not configured for ${url}`)
    }
}

export const Navigation = ({ url }) => {
    switch (appFromURL(url)) {
        case apps.INSTAGENIE:
            return <InstageniePageNavigation />
        case apps.SOLDCOUNTER:
            return <SoldCounterPageNavigation />
        default:
            return <></>
    }
}