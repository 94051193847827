// Assuming you have Redux Toolkit set up
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { fetchStatus } from '../../utils/constant';
import { getShopifySessionToken } from '../../../../utils/authUtils';
import { INSTAGENIE_BASE_URL } from "../../../../settings";
// Define the initial state
const initialState = {
  fetchStatus: fetchStatus.idle,
  error: null,
};

// Create an async thunk to handle the PUT request
export const updateWidgetDetails = createAsyncThunk(
  'widget/updateDetails',
  async ({ payload, app }) => {
    try {
      const bearerToken = await getShopifySessionToken(app);
      const response = await axios.put(
        `${INSTAGENIE_BASE_URL}/update-widget`,
        payload,
        {
          ...(bearerToken && { headers: { Authorization: `Bearer ${bearerToken}` } }),
        }
      );
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  }
);

// Create a slice
const formDetailsSlice = createSlice({
  name: 'widget',
  initialState,
  reducers: {
    resetFormDetails: (state) => {
      state.fetchStatus = fetchStatus.idle;
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateWidgetDetails.pending, (state) => {
        state.fetchStatus = fetchStatus.pending;
      })
      .addCase(updateWidgetDetails.fulfilled, (state) => {
        state.fetchStatus = fetchStatus.resolved;

      })
      .addCase(updateWidgetDetails.rejected, (state, action) => {
        state.fetchStatus = fetchStatus.rejected
        state.error = action.error.message;
      });
  },
});

export default formDetailsSlice.reducer;
export const { resetFormDetails } = formDetailsSlice.actions;