import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import styles from './styles.module.css';

const ColorPickerInput = (props) => {
    const { label, value, onChange, name } = props;
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [tempColor, setTempColor] = useState(value);

    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker);
    };

    const handleClose = () => {
        setDisplayColorPicker(false);
        onChange(name)(tempColor); // Call onChange with the final selected color when closing
    };

    const handleChange = (color) => {
        setTempColor(color.hex); // Update local state with the selected color
    };

    return (
        <div className={styles.parentWrapperCss}>
            {label}
            <div className={styles.swatch} onClick={handleClick}>
                <div
                    className={styles.color}
                    style={{
                        background: tempColor || 'black',
                    }}
                />
            </div>
            {displayColorPicker && (
                <div className={styles.popover}>
                    <div className={styles.cover} onClick={handleClose} />
                    <SketchPicker color={tempColor} onChange={handleChange} />
                </div>
            )}
        </div>
    );
};

export default ColorPickerInput;
