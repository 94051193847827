const orderSoon = {
    "inStockTextStyle": {
        "fontSize": 18,
        "bold": true,
        "color": "#7ed321"
    },
    "inStockValueStyle": {
        "fontSize": 18,
        "bold": true,
        "color": "#b74745"
    },
    "soldTextStyle": {
        "fontSize": 18,
        "bold": false,
        "color": "#000000"
    },
    "soldValueStyle": {
        "fontSize": 18,
        "bold": false,
        "color": "#000000"
    },
    "separatorStyle": {
        "fontSize": "18",
        "bold": false,
        "color": "#3d3b3b"
    },
    "countdown": {
        "timerSecs": 600,
        "timerType": "DATE",
        "textColor": "#b0deb0",
        "barColor": "#567c56",
        "barBackground": "#c1dac1",
        "size": 5,
        "ringWidth": 15,
        "timerToDate": "2024-09-11T10:42:00.000Z"
    },
    "flipCountdown": {
        "timerSecs": 3000,
        "timerType": "DATE",
        "timerToDate": new Date().toISOString(),
        "size": 7,
        "saleEndsInText": "",
        "saleEndsTextColor": "#1ddf1d",
        "primaryColor": "#ffc200",
        "secondaryColor": "#092609",
        "rotorHingeColor": "#e5f1e5"
    },
    "inStockEnabled": true,
    "soldEnabled": false,
    "separatorEnabled": false,
    "soldSmileyEnabled": false,
    "separatorText": "/",
    "inStockText": "Left in stock - order soon",
    "inStockPrefixText": "Only",
    "soldText": "",
    "soldPrefixText": "",
    "position": "ABOVE_FIRST_INPUT",
    "countDownEnabled": true,
    "countDownType": "RING",
    "timezone": "Europe/Madrid",
    "soldSmiley": "zegsu-smiley-fire",
    "soldSmileyStyle": {}
}

const highDemand = {
    "inStockTextStyle": {
        "fontSize": 18,
        "bold": true,
        "color": "#000000"
    },
    "inStockValueStyle": {
        "fontSize": 18,
        "bold": true,
        "color": "#b74745"
    },
    "soldTextStyle": {
        "fontSize": 18,
        "bold": true,
        "color": "#7ed321"
    },
    "soldValueStyle": {
        "fontSize": 18,
        "bold": true,
        "color": "#b74745"
    },
    "separatorStyle": {
        "fontSize": 18,
        "bold": false,
        "color": "#0a0202"
    },
    "countdown": {
        "timerSecs": 600,
        "timerType": "DATE",
        "textColor": "#b0deb0",
        "barColor": "#567c56",
        "barBackground": "#c1dac1",
        "size": 5,
        "ringWidth": 15,
        "timerToDate": "2024-09-11T10:42:00.000Z"
    },
    "flipCountdown": {
        "timerSecs": 3000,
        "timerType": "DATE",
        "timerToDate": new Date().toISOString(),
        "size": 7,
        "saleEndsInText": "",
        "saleEndsTextColor": "#1ddf1d",
        "primaryColor": "#ffc200",
        "secondaryColor": "#092609",
        "rotorHingeColor": "#e5f1e5"
    },
    "inStockEnabled": false,
    "soldEnabled": true,
    "separatorEnabled": false,
    "soldSmileyEnabled": true,
    "separatorText": "//",
    "inStockText": "",
    "inStockPrefixText": "",
    "soldText": "Sold in last 24 hours",
    "soldPrefixText": " In high demand!",
    "position": "ABOVE_FIRST_INPUT",
    "countDownEnabled": true,
    "countDownType": "RING",
    "timezone": "Europe/Madrid",
    "soldSmiley": "zegsu-smiley-fire",
    "soldSmileyStyle": {}
}
const remaining = {
    "inStockTextStyle": {
        "fontSize": 18,
        "bold": true,
        "color": "#7ed321"
    },
    "inStockValueStyle": {
        "fontSize": 18,
        "bold": true,
        "color": "#e41919"
    },
    "soldTextStyle": {
        "fontSize": 18,
        "bold": false,
        "color": "#000000"
    },
    "soldValueStyle": {
        "fontSize": 18,
        "bold": false,
        "color": "#000000"
    },
    "separatorStyle": {
        "fontSize": "18",
        "bold": false,
        "color": "#291309"
    },
    "countdown": {
        "timerSecs": 600,
        "timerType": "DATE",
        "textColor": "#b0deb0",
        "barColor": "#567c56",
        "barBackground": "#c1dac1",
        "size": 5,
        "ringWidth": 15,
        "timerToDate": "2024-09-11T10:42:00.000Z"
    },
    "flipCountdown": {
        "timerSecs": 3000,
        "timerType": "DATE",
        "timerToDate": new Date().toISOString(),
        "size": 7,
        "saleEndsInText": "",
        "saleEndsTextColor": "#1ddf1d",
        "primaryColor": "#ffc200",
        "secondaryColor": "#092609",
        "rotorHingeColor": "#e5f1e5"
    },
    "inStockEnabled": true,
    "soldEnabled": false,
    "separatorEnabled": false,
    "soldSmileyEnabled": false,
    "separatorText": "/",
    "inStockText": "remaining!",
    "inStockPrefixText": "Only",
    "soldText": "",
    "soldPrefixText": "",
    "position": "ABOVE_FIRST_INPUT",
    "countDownEnabled": true,
    "countDownType": "RING",
    "timezone": "Europe/Madrid",
    "soldSmiley": "zegsu-smiley-fire",
    "soldSmileyStyle": {}
}
const left = {
    "inStockTextStyle": {
        "fontSize": 18,
        "bold": true,
        "color": "#7ed321"
    },
    "inStockValueStyle": {
        "fontSize": 18,
        "bold": true,
        "color": "#e41919"
    },
    "soldTextStyle": {
        "fontSize": 18,
        "bold": false,
        "color": "#000000"
    },
    "soldValueStyle": {
        "fontSize": 18,
        "bold": false,
        "color": "#000000"
    },
    "separatorStyle": {
        "fontSize": "18",
        "bold": false,
        "color": "#291309"
    },
    "countdown": {
        "timerSecs": 600,
        "timerType": "DATE",
        "textColor": "#b0deb0",
        "barColor": "#567c56",
        "barBackground": "#c1dac1",
        "size": 5,
        "ringWidth": 15,
        "timerToDate": new Date().toISOString()
    },
    "flipCountdown": {
        "timerSecs": 3000,
        "timerType": "DATE",
        "timerToDate": new Date().toISOString(),
        "size": 7,
        "saleEndsInText": "",
        "saleEndsTextColor": "#1ddf1d",
        "primaryColor": "#ffc200",
        "secondaryColor": "#092609",
        "rotorHingeColor": "#e5f1e5"
    },
    "inStockEnabled": true,
    "soldEnabled": false,
    "separatorEnabled": false,
    "soldSmileyEnabled": false,
    "separatorText": "/",
    "inStockText": "left in stock",
    "inStockPrefixText": "Only",
    "soldText": "",
    "soldPrefixText": "",
    "position": "ABOVE_FIRST_INPUT",
    "countDownEnabled": true,
    "countDownType": "RING",
    "timezone": "Europe/Madrid",
    "soldSmiley": "zegsu-smiley-fire",
    "soldSmileyStyle": {}
}
const limited = {
    "inStockTextStyle": {
        "fontSize": 18,
        "bold": true,
        "color": "#7ed321"
    },
    "inStockValueStyle": {
        "fontSize": 18,
        "bold": true,
        "color": "#b74745"
    },
    "soldTextStyle": {
        "fontSize": 18,
        "bold": true,
        "color": "#7ed321"
    },
    "soldValueStyle": {
        "fontSize": 18,
        "bold": true,
        "color": "#7ED321"
    },
    "separatorStyle": {
        "fontSize": 20,
        "bold": true,
        "color": "#ff0b00"
    },
    "countdown": {
        "timerSecs": 600,
        "timerType": "DATE",
        "textColor": "#b0deb0",
        "barColor": "#567c56",
        "barBackground": "#c1dac1",
        "size": 5,
        "ringWidth": 15,
        "timerToDate": "2024-09-11T10:42:00.000Z"
    },
    "flipCountdown": {
        "timerSecs": 3000,
        "timerType": "DATE",
        "timerToDate": new Date().toISOString(),
        "size": 7,
        "saleEndsInText": "",
        "saleEndsTextColor": "#1ddf1d",
        "primaryColor": "#ffc200",
        "secondaryColor": "#092609",
        "rotorHingeColor": "#e5f1e5"
    },
    "inStockEnabled": true,
    "soldEnabled": false,
    "separatorEnabled": false,
    "soldSmileyEnabled": false,
    "separatorText": "/",
    "inStockText": "items left!",
    "inStockPrefixText": "Limited time offer : Only",
    "soldText": "Sold in last 24 hours",
    "soldPrefixText": " In high demand!",
    "position": "ABOVE_FIRST_INPUT",
    "countDownEnabled": true,
    "countDownType": "RING",
    "timezone": "Europe/Madrid",
    "soldSmiley": "zegsu-smiley-fire",
    "soldSmileyStyle": {}
}

const combined = {
    "inStockTextStyle": {
        "fontSize": 18,
        "bold": true,
        "color": "#7ed321"
    },
    "inStockValueStyle": {
        "fontSize": 18,
        "bold": true,
        "color": "#b74745"
    },
    "soldTextStyle": {
        "fontSize": 18,
        "bold": true,
        "color": "#7ed321"
    },
    "soldValueStyle": {
        "fontSize": 18,
        "bold": true,
        "color": "#7ed321"
    },
    "separatorStyle": {
        "fontSize": 18,
        "bold": true,
        "color": "#97f528"
    },
    "countdown": {
        "timerSecs": 600,
        "timerType": "DATE",
        "textColor": "#b0deb0",
        "barColor": "#567c56",
        "barBackground": "#c1dac1",
        "size": 5,
        "ringWidth": 15,
        "timerToDate": "2024-09-11T10:42:00.000Z"
    },
    "flipCountdown": {
        "timerSecs": 3000,
        "timerType": "DATE",
        "timerToDate": new Date().toISOString(),
        "size": 7,
        "saleEndsInText": "",
        "saleEndsTextColor": "#1ddf1d",
        "primaryColor": "#ffc200",
        "secondaryColor": "#092609",
        "rotorHingeColor": "#e5f1e5"
    },
    "inStockEnabled": true,
    "soldEnabled": true,
    "separatorEnabled": true,
    "soldSmileyEnabled": true,
    "separatorText": "/",
    "inStockText": "In stock",
    "inStockPrefixText": "",
    "soldText": "Sold",
    "soldPrefixText": "",
    "position": "ABOVE_FIRST_INPUT",
    "countDownEnabled": true,
    "countDownType": "RING",
    "timezone": "Europe/Madrid",
    "soldSmiley": "zegsu-smiley-fire",
    "soldSmileyStyle": {}
}

export const dataMapping = {
    combined,
    orderSoon,
    limited,
    left,
    highDemand,
    remaining
}