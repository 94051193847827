import React from "react";
import {
  Page,
  Card,
} from "@shopify/polaris";
import classes from "./styles.module.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const DashboardSkeleton = () => {
  return (
    <Page >
      <Card>
        <div className={classes.dashboardWrapperCss}>
          <div className={classes.loadingBar1}>
            <Skeleton style={{ height: "100%" }} />
          </div>
          <div className={classes.loadingBar2}>
            <Skeleton style={{ height: "100%" }} />
          </div>
        </div>
      </Card>
    </Page>
  );
};

export default DashboardSkeleton;
