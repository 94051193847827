import {Route, Routes} from "react-router-dom";
import ConnectYourAccount from "../Cards/ConnectYourAccount";
import Dashboard from "../Dashboard";
import Plans from "../../pages/planPage";
import MediaControl from "../MediaControl";
import InstagenieContactPage from "../../pages/contactPage";
import Base from "../../Base";

const InstgenieRouter = () => {
    const appBasePathName = '/instagenie';
    return (
        <Routes>
            <Route path={appBasePathName} element={<Base/>}>
                <Route path='dashboard' element={<Dashboard />} />
                <Route path='plans' element={<Plans />} />
                <Route path='contact' element={<InstagenieContactPage />} />
                <Route path='control' element={<MediaControl />} />
                <Route path='login' element={<ConnectYourAccount />} />
            </Route>
        </Routes>
    );
}

export default InstgenieRouter;