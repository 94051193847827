import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { fetchStatus } from '../../utils/constant';
import { getShopifySessionToken } from '../../../../utils/authUtils';
import { INSTAGENIE_BASE_URL } from "../../../../settings";
const initialState = {
  data: null,
  fetchStatus: fetchStatus.idle,
  error: null,
  updatingMediaId: null
};

export const updateMediaStatus = createAsyncThunk(
  'content/updateMediaStatus',
  async ({ mediaId, state, app }) => {
    try {
      const bearerToken = await getShopifySessionToken(app);
      // Perform the PUT operation to update media status
      const res = await axios.put(`${INSTAGENIE_BASE_URL}/media/${mediaId}/${state}`, null, {
        ...(bearerToken && { headers: { Authorization: `Bearer ${bearerToken}` } }),
      });

      // Assuming the API returns some data upon success
      const data = await res.data;
      return data;
    } catch (error) {
      // Handle errors if needed
      throw error;
    }
  }
);

export const mediaControlSlice = createSlice({
  name: 'mediaControl',
  initialState,
  reducers: {
    resetMediaControlState: (state) => {
      state.fetchStatus = fetchStatus.idle;
      state.error = null;
      state.updatingMediaId = null;
    },
    setUpdatingMediaId: (state, action) => {
      state.updatingMediaId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateMediaStatus.pending, (state) => {
      state.fetchStatus = fetchStatus.pending;
    });
    builder.addCase(updateMediaStatus.fulfilled, (state, action) => {
      state.fetchStatus = fetchStatus.resolved;
    });
    builder.addCase(updateMediaStatus.rejected, (state, action) => {
      state.fetchStatus = fetchStatus.rejected;
      state.error = action.error.message;
    });
  },
});

export default mediaControlSlice.reducer;

export const { resetMediaControlState, setUpdatingMediaId } = mediaControlSlice.actions;



