import { FormLayout, Select, } from "@shopify/polaris";
import React from "react";
import { emojiList, trueFalseOptions } from "../constant";
const SoldEmojiTextForm = ({ value, error, onChange, onError }) => {
    const handleInputChange = (field) => (newValue) => {
        onChange(field, newValue);
        // if (newValue.trim() === "") {
        //     onError(field, "This field cannot be empty");
        // } else {
        //     onError(field, null);
        // }
    };
    return (
        <FormLayout>
            <Select
                label="Enable widget"
                options={trueFalseOptions}
                value={value.enableWidget === "true" || value.enableWidget === true ? "true" : "false"}
                onChange={handleInputChange("enableWidget")}
                error={error.enableWidget}

            />
            <FormLayout.Group>
                <Select
                    label="Sold Emoji"
                    value={value.soldSmiley}
                    onChange={handleInputChange("soldSmiley")}
                    options={emojiList} />
            </FormLayout.Group>
        </FormLayout>
    );
};

export default SoldEmojiTextForm;
