const soldCounterFAQ = [
    { id: 1, questionNumber: 1, questionText: 'How to add Sold Count widget to product age?', questionType: 'Video', src: 'https://www.youtube.com/embed/z6UDWBWf0hM?si=s-eS8en8ugx20edA' },
    { id: 2, questionNumber: 2, questionText: 'How to add Sold Count widget to the collections page?', questionType: 'Video', src: 'https://www.youtube.com/embed/yU1cUb3S9Yw?si=l7RWbNn0hJ_QViBF' },
    { id: 3, questionNumber: 3, questionText: 'How to add the Sold Count widget to the Featured Collection on Home Page?', questionType: 'Video', src: 'https://www.youtube.com/embed/2iVw0fSQPTw?si=_DyN_lYx4bqc6H-m' },
];


const FAQMapping = {
    'sold-counter': soldCounterFAQ
}

export const getFAQ = (appName) => {
    return FAQMapping[appName] || []
}



