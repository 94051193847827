import { useAppBridge } from "@shopify/app-bridge-react";
import { Modal,TextContainer } from '@shopify/polaris';
import { getShopifySessionToken } from "../../../../../utils/authUtils";
import {Redirect} from '@shopify/app-bridge/actions';
import { useState } from "react";

export function NoAccess(props) {
  const [navigationInProgress,setNavigationInProgress]=useState(false);
  const app = useAppBridge();
  const redirect=Redirect.create(app);
  const { open, onClose } = props;

  const upgradePlanHandler = async () => {
    setNavigationInProgress(true);
    const token = await getShopifySessionToken(app);
    redirect.dispatch(Redirect.Action.REMOTE, {url: `https://zegsuapps.com/shopify/instagenie/change-plan/PRIME?authToken=${token}`})

  };

  return (
    <Modal
    open={open}
    onClose={onClose}
    title="Update required!"
    primaryAction={{
      content: 'Upgrade now',
      onAction:upgradePlanHandler,
      loading:navigationInProgress
    }}
  >
    <Modal.Section>
      <TextContainer>
        <p>
          This feature is only available in the PLUS plan. Please consider upgrading.
        </p>
      </TextContainer>
    </Modal.Section>
  </Modal>
  );
}
