import {
    Button,
    Collapsible,
    Text,
    Card,
    InlineStack,
    Icon,
    TextField,
} from "@shopify/polaris";
import {
    AlertDiamondIcon,
    ChevronUpIcon,
    ChevronDownIcon,
} from "@shopify/polaris-icons";
import { useState, useCallback } from "react";
import classes from "./styles.module.css";

const ManualInstallationCollapsible = () => {
    const [open, setOpen] = useState(true);

    const handleToggle = useCallback(() => setOpen((open) => !open), []);

    return (
        <Card>
            <div className={classes.titleCardWrapperCss}>
                <InlineStack
                    as="div"
                    align="space-between"
                    direction="row"
                    blockAlign="center"
                >
                    <InlineStack align="center" gap={200}>
                        <Icon source={AlertDiamondIcon} />
                        <Text variant="headingMd" as="h6">
                            Manual installation guide
                        </Text>
                    </InlineStack>
                    <div className={classes.toggleButtonWrapperCss}>
                        <Button
                            onClick={handleToggle}
                            size="slim"
                            icon={open ? ChevronUpIcon : ChevronDownIcon}
                        />
                    </div>
                </InlineStack>
            </div>

            <div>
                <Collapsible
                    open={open}
                    id="basic-collapsible"
                    transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
                    expandOnPrint
                >
                    <div className={classes.textContainerWrapperCss}>
                        <Text>
                            Open your store's product page or featured product page and check
                            if you see our sold counter widget.
                        </Text>
                        <div className={classes.noteCss}>
                            <Text fontWeight="semibold">
                                Note: Only follow these steps if you dont see the widget after
                                automatic installation or if you want to install the widget on
                                pages other than product/featured product page
                            </Text>
                        </div>
                        <div className={classes.stepsWrapperCss}>
                            Also note that the widget currently works on section which has
                            'product' variable set by shopify
                            <ul>
                                <li>Login to your Shopify Store</li>
                                <li>Click on 'Online Store' in the left navigation</li>
                                <li>Open the 'Themes' tab</li>
                                <li>Click on 'Actions' and then on 'Edit Code'</li>
                                <li>
                                    Copy and paste the code below into the liquid template where
                                    you want the widget to appear.
                                </li>
                            </ul>
                            If you need any help with installation, feel free to contact use
                            at contact@zegsu.com
                        </div>
                        <div className={classes.inputWrapperCss}>
                            <div className={classes.inputLabelCss}>
                                <Text fontWeight="semibold">Product Page Widget</Text>
                            </div>
                            <TextField
                                className="space-400"
                                value={`<div class="zegsu-sold-counter-manual-marker" data-product-id="{{product.id}}"></div>`}
                                monospaced
                                autoComplete="off"
                            />
                        </div>
                    </div>
                </Collapsible>
            </div>
        </Card>
    );
};

export default ManualInstallationCollapsible;
