import { NavigationMenu } from '@shopify/app-bridge-react';
import React from 'react';
export const InstageniePageNavigation = () => {
  return (
    <NavigationMenu navigationLinks={[
      {
        label: "Customize media",
        destination: "/shopify/instagenie/control"
      },
      {
        label: "Plan",
        destination: "/shopify/instagenie/plans"
      },
      {
        label: "Support",
        destination: "/shopify/instagenie/contact"
      },
    ]
    } />
  )
}


export const SoldCounterPageNavigation = () => {
  return (
    <NavigationMenu navigationLinks={[
      {
        label: "Customize",
        destination: "/shopify/sold-counter/customize"
      },
      {
        label: "Settings",
        destination: "/shopify/sold-counter/settings"
      },
      {
        label: "Plan",
        destination: "/shopify/sold-counter/plans"
      },
      {
        label: "Help Center",
        destination: "/shopify/sold-counter/faq"
      },
      {
        label: "Support",
        destination: "/shopify/sold-counter/contact"
      },
    ]
    } />
  )
}