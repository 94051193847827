
export const extractHostFromUrl = (url) => {
    const urlObject = new URL(url);
    const queryParams = new URLSearchParams(urlObject.search);
    const host = queryParams.get('host');

    return host;
}


export const isDateGreaterThanNow = (dateString) => {
    //Expected format : 2015-03-25T12:00:00Z
    const givenDate = new Date(dateString);
    const currentDate = new Date();
    return givenDate > currentDate;
};