import { formatDateTimeToUTC } from "../../utils/helper";

export const COUNT_WIDGET_KEYS = {
    CUSTOMIZE_SOLD_COUNTER_DISPLAY_TEXT:
        "countWidgetCustomizeSoldCounterDisplayText",
    CUSTOMIZE_SOLD_COUNTER_VALUE_TEXT: "countWidgetCustomizeSoldCounterValueText",
    CUSTOMIZE_AVAILABILITY_DISPLAY_TEXT:
        "countWidgetCustomizeAvailabilityDisplayText",
    CUSTOMIZE_AVAILABILITY_VALUE_TEXT:
        "countWidgetCustomizeAvailabilityValueText",
    CUSTOMIZE_SEPARATOR_TEXT: "countWidgetCustomizeSeparatorText",
    CUSTOMIZE_EMOJI: "countWidgetCustomizeEmoji",
};

export const COUNTDOWN_WIDGET_KEYS = {
    CUSTOMIZE_COUNTDOWN_WIDGET_SETTINGS: "countDownWidgetSettings",
};
// Use the constants in the countWidgetCards array
export const countWidgetCards = [
    {
        id: 1,
        title: "Sold Count Display Text",
        content: "Content for card 1",
        cardKey: COUNT_WIDGET_KEYS.CUSTOMIZE_SOLD_COUNTER_DISPLAY_TEXT,
        parent: null
    },
    {
        id: 2,
        title: "Sold Count Value Text",
        content: "Content for card 2",
        cardKey: COUNT_WIDGET_KEYS.CUSTOMIZE_SOLD_COUNTER_VALUE_TEXT,
        parent: COUNT_WIDGET_KEYS.CUSTOMIZE_SOLD_COUNTER_DISPLAY_TEXT
    },
    {
        id: 3,
        title: "In-stock Display Text",
        content: "Content for card 2",
        cardKey: COUNT_WIDGET_KEYS.CUSTOMIZE_AVAILABILITY_DISPLAY_TEXT,
        parent: null,
    },
    {
        id: 4,
        title: "In-stock Value Text",
        content: "Content for card 2",
        cardKey: COUNT_WIDGET_KEYS.CUSTOMIZE_AVAILABILITY_VALUE_TEXT,
        parent: COUNT_WIDGET_KEYS.CUSTOMIZE_AVAILABILITY_DISPLAY_TEXT
    },
    {
        id: 5,
        title: "Separator",
        content: "Content for card 3",
        cardKey: COUNT_WIDGET_KEYS.CUSTOMIZE_SEPARATOR_TEXT,
        parent: null
    },
    {
        id: 6,
        title: "Emoji",
        content: "Content for card 4",
        cardKey: COUNT_WIDGET_KEYS.CUSTOMIZE_EMOJI,
        parent: null
    },
];

export const countDownWidgetCards = [
    {
        id: 1,
        title: "Customize countdown",
        content: "Content for card 1",
        cardKey: COUNTDOWN_WIDGET_KEYS.CUSTOMIZE_COUNTDOWN_WIDGET_SETTINGS,
    },
]
export const fieldNameBasedOnKey = {
    CUSTOMIZE_AVAILABILITY_DISPLAY_TEXT: {
        enableWidget: "soldEnabled",
        suffixText: "soldText",
        prefixText: "soldPrefixText",
        fontSize: "fontSize",
    },
};

export const mapFormValuesToWidget = (formValues) => ({
    inStockEnabled:
        formValues.countWidgetCustomizeAvailabilityDisplayText.enableWidget,
    separatorEnabled: formValues.countWidgetCustomizeSeparatorText.enableWidget,
    soldEnabled:
        formValues.countWidgetCustomizeSoldCounterDisplayText.enableWidget,
    soldSmileyEnabled: formValues.countWidgetCustomizeEmoji.enableWidget,
    inStockPrefixText:
        formValues.countWidgetCustomizeAvailabilityDisplayText.prefixText,
    inStockText:
        formValues.countWidgetCustomizeAvailabilityDisplayText.suffixText,
    separatorText: formValues.countWidgetCustomizeSeparatorText.suffixText,
    soldPrefixText:
        formValues.countWidgetCustomizeSoldCounterDisplayText.prefixText,
    soldSmiley: formValues.countWidgetCustomizeEmoji.soldSmiley,
    soldText: formValues.countWidgetCustomizeSoldCounterDisplayText.suffixText,
    inStockTextStyle: {
        fontSize: formValues.countWidgetCustomizeAvailabilityDisplayText.fontSize,
        bold: formValues.countWidgetCustomizeAvailabilityDisplayText.bold,
        color: formValues.countWidgetCustomizeAvailabilityDisplayText.color,
    },
    inStockValueStyle: {
        fontSize: formValues.countWidgetCustomizeAvailabilityValueText.fontSize,
        bold: formValues.countWidgetCustomizeAvailabilityValueText.bold,
        color: formValues.countWidgetCustomizeAvailabilityValueText.color,
    },
    separatorStyle: {
        fontSize: formValues.countWidgetCustomizeSeparatorText.fontSize,
        bold: formValues.countWidgetCustomizeSeparatorText.bold,
        color: formValues.countWidgetCustomizeSeparatorText.color,
    },
    soldSmileyStyle: {
        fontSize: formValues.countWidgetCustomizeEmoji.fontSize,
        bold: formValues.countWidgetCustomizeEmoji.bold,
        color: formValues.countWidgetCustomizeEmoji.color,
    },
    soldTextStyle: {
        fontSize: formValues.countWidgetCustomizeSoldCounterDisplayText.fontSize,
        bold: formValues.countWidgetCustomizeSoldCounterDisplayText.bold,
        color: formValues.countWidgetCustomizeSoldCounterDisplayText.color,
    },
    soldValueStyle: {
        fontSize: formValues.countWidgetCustomizeSoldCounterValueText.fontSize,
        bold: formValues.countWidgetCustomizeSoldCounterValueText.bold,
        color: formValues.countWidgetCustomizeSoldCounterValueText.color,
    },
});



export const mapFormValuesToCountDownWidget = (formValues) => ({

    "countdown": {
        "timerSecs": formValues[COUNTDOWN_WIDGET_KEYS.CUSTOMIZE_COUNTDOWN_WIDGET_SETTINGS].countDownTimerSecs,
        "timerType": formValues[COUNTDOWN_WIDGET_KEYS.CUSTOMIZE_COUNTDOWN_WIDGET_SETTINGS].countDownTimerType,
        "textColor": formValues[COUNTDOWN_WIDGET_KEYS.CUSTOMIZE_COUNTDOWN_WIDGET_SETTINGS].countDownTextColor,
        "barColor": formValues[COUNTDOWN_WIDGET_KEYS.CUSTOMIZE_COUNTDOWN_WIDGET_SETTINGS].countDownBarColor,
        "barBackground": formValues[COUNTDOWN_WIDGET_KEYS.CUSTOMIZE_COUNTDOWN_WIDGET_SETTINGS].countDownBarBackground,
        "size": formValues[COUNTDOWN_WIDGET_KEYS.CUSTOMIZE_COUNTDOWN_WIDGET_SETTINGS].countDownSize,
        "ringWidth": formValues[COUNTDOWN_WIDGET_KEYS.CUSTOMIZE_COUNTDOWN_WIDGET_SETTINGS].countDownRingWidth,
        "timerToDate": formatDateTimeToUTC(formValues[COUNTDOWN_WIDGET_KEYS.CUSTOMIZE_COUNTDOWN_WIDGET_SETTINGS].countDownTimerToDate)
    },
    "flipCountdown": {
        "timerSecs": formValues[COUNTDOWN_WIDGET_KEYS.CUSTOMIZE_COUNTDOWN_WIDGET_SETTINGS].flipTimerSecs,
        "timerType": formValues[COUNTDOWN_WIDGET_KEYS.CUSTOMIZE_COUNTDOWN_WIDGET_SETTINGS].flipTimerType,
        "timerToDate": formatDateTimeToUTC(formValues[COUNTDOWN_WIDGET_KEYS.CUSTOMIZE_COUNTDOWN_WIDGET_SETTINGS].flipTimerToDate),
        "size": formValues[COUNTDOWN_WIDGET_KEYS.CUSTOMIZE_COUNTDOWN_WIDGET_SETTINGS].flipSize,
        "saleEndsInText": formValues[COUNTDOWN_WIDGET_KEYS.CUSTOMIZE_COUNTDOWN_WIDGET_SETTINGS].flipSaleEndsInText,
        "saleEndsTextColor": formValues[COUNTDOWN_WIDGET_KEYS.CUSTOMIZE_COUNTDOWN_WIDGET_SETTINGS].flipSaleEndsTextColor,
        "primaryColor": formValues[COUNTDOWN_WIDGET_KEYS.CUSTOMIZE_COUNTDOWN_WIDGET_SETTINGS].flipPrimaryColor,
        "secondaryColor": formValues[COUNTDOWN_WIDGET_KEYS.CUSTOMIZE_COUNTDOWN_WIDGET_SETTINGS].flipSecondaryColor,
        "rotorHingeColor": formValues[COUNTDOWN_WIDGET_KEYS.CUSTOMIZE_COUNTDOWN_WIDGET_SETTINGS].flipRotorHingeColor
    },
    countDownType: formValues[COUNTDOWN_WIDGET_KEYS.CUSTOMIZE_COUNTDOWN_WIDGET_SETTINGS].countDownType,
    countDownEnabled: formValues[COUNTDOWN_WIDGET_KEYS.CUSTOMIZE_COUNTDOWN_WIDGET_SETTINGS].enableWidget
});


export const trueFalseOptions = [
    { label: "True", value: "true" },
    { label: "False", value: "false" },
];


export const emojiList = [
    { "label": "🔥", "value": "zegsu-smiley-fire" },
    { "label": "💯", "value": "zegsu-smiley-100" },
    { "label": "💩", "value": "zegsu-smiley-poo" },
    { "label": "👻", "value": "zegsu-smiley-ghost" },
    { "label": "🤖", "value": "zegsu-smiley-robot" },
    { "label": "🙈", "value": "zegsu-smiley-monkey-no-see" },
    { "label": "🙉", "value": "zegsu-smiley-monkey-no-hear" },
    { "label": "🙊", "value": "zegsu-smiley-monkey-no-say" },
    { "label": "💥", "value": "zegsu-smiley-collision" },
    { "label": "💫", "value": "zegsu-smiley-dizzy" },
    { "label": "💦", "value": "zegsu-smiley-drops" },
    { "label": "💣", "value": "zegsu-smiley-bomb" },
    { "label": "💬", "value": "zegsu-smiley-speech" },
    { "label": "👌", "value": "zegsu-smiley-ok-hand" },
    { "label": "👍", "value": "zegsu-smiley-thumbs-up" },
    { "label": "🙏", "value": "zegsu-smiley-folded-hands" },
    { "label": "👀", "value": "zegsu-smiley-eyes" },
    { "label": "🍻", "value": "zegsu-smiley-beer-mugs" },
    { "label": "✅", "value": "zegsu-smiley-green-check" }
]