import {
    Card,
    Grid,
    InlineStack,
    LegacyCard,
    Text,
    Icon,
    Badge,
    Button,
    ButtonGroup,
} from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import {
    COUNTDOWN_WIDGET_KEYS,
    countDownWidgetCards,
    mapFormValuesToCountDownWidget,
} from "../constant";
import { ChevronDownIcon, ChevronUpIcon } from "@shopify/polaris-icons";
import classes from "./styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchDashboardData,
    updateCustomizeWidget,
} from "../../../slices/SoldCounterSlices/dashboardSlice";
import { get } from "lodash";
import CountDownWidgetRenderer from "../../WidgetRenderer/CountDownWidget";
import CountDownSettingForm from "../Forms/countDownSettingForm";
import WidgetWrapper from "../../WidgetRenderer/WidgetWrapper/index";
import { fetchStatus } from "../../../../../utils/constant";
import { useAppBridge, useToast } from "@shopify/app-bridge-react";
import {
    useWindowWidth,
} from '@react-hook/window-size'
import FormSkeleton from "../Forms/Common/formSkelton";

const formComponents = {
    [COUNTDOWN_WIDGET_KEYS.CUSTOMIZE_COUNTDOWN_WIDGET_SETTINGS]: CountDownSettingForm

};

const layoutPattern = [
    ['full'],
    ['half', 'half'],
    ['full', 'full'],
    ['full'],
    ['half', 'half'],
    ['full', 'full'],
];

const SoldCountWidget = (props) => {
    const { dashboardData, dashboardFetchStatus } = props;
    const app = useAppBridge();
    const { show } = useToast()
    const width = useWindowWidth();
    const dispatch = useDispatch();
    const [minimize, setMinimize] = useState(false);
    const [openId, setOpenId] = useState(1);
    const [formValues, setFormValues] = useState({});
    const [initialFormValues, setInitialFormValues] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [widgetValues, setWidgetValues] = useState({});

    useEffect(() => {
        if (dashboardFetchStatus === fetchStatus.resolved) {
            const enableWidget = get(
                dashboardData,
                "data.shop.widget.countDownEnabled",
                false
            );
            const countDownType = get(
                dashboardData,
                "data.shop.widget.countDownType",
                'FLIP'
            );
            const flipTimerSecs = get(
                dashboardData,
                "data.shop.widget.flipCountdown.timerSecs",
                0
            );

            const flipTimerToDate = get(
                dashboardData,
                "data.shop.widget.flipCountdown.timerToDate",
                ''
            )

            const countDownTimerSecs = get(
                dashboardData,
                "data.shop.widget.countdown.timerSecs",
                0
            );

            const flipTimerType = get(
                dashboardData,
                "data.shop.widget.flipCountdown.timerType",
                'SEC'
            );

            const countDownTimerType = get(
                dashboardData,
                "data.shop.widget.countdown.timerType",
                'SEC'
            );


            const flipSaleEndsInText = get(
                dashboardData,
                "data.shop.widget.flipCountdown.saleEndsInText",
                ''
            );

            const flipSaleEndsTextColor = get(
                dashboardData,
                "data.shop.widget.flipCountdown.saleEndsTextColor",
                ''
            );

            const flipPrimaryColor = get(
                dashboardData,
                "data.shop.widget.flipCountdown.primaryColor",
                ''
            );

            const flipSecondaryColor = get(
                dashboardData,
                "data.shop.widget.flipCountdown.secondaryColor",
                ''
            );

            const flipRotorHingeColor = get(
                dashboardData,
                "data.shop.widget.flipCountdown.rotorHingeColor",
                ''
            );

            const flipSize = get(
                dashboardData,
                "data.shop.widget.flipCountdown.size",
                ''
            );

            const countDownTextColor = get(
                dashboardData,
                "data.shop.widget.countdown.textColor",
                ''
            );

            const countDownBarColor = get(
                dashboardData,
                "data.shop.widget.countdown.barColor",
                ''
            );


            const countDownBarBackground = get(
                dashboardData,
                "data.shop.widget.countdown.barBackground",
                ''
            );

            const countDownSize = get(
                dashboardData,
                "data.shop.widget.countdown.size",
                0
            );

            const countDownRingWidth = get(
                dashboardData,
                "data.shop.widget.countdown.ringWidth",
                0
            );

            const countDownTimerToDate = get(
                dashboardData,
                "data.shop.widget.countdown.timerToDate",
                ""
            );

            const widgetData = get(dashboardData, "data.shop.widget", {});

            const countDownWidgetSettings = {
                flipTimerSecs,
                flipTimerType,
                flipSaleEndsInText,
                flipSaleEndsTextColor,
                flipPrimaryColor,
                flipSecondaryColor,
                flipSize,
                countDownTimerType,
                countDownSize,
                countDownBarBackground,
                countDownBarColor,
                countDownRingWidth,
                countDownTextColor,
                flipRotorHingeColor,
                countDownTimerSecs,
                enableWidget,
                countDownType,
                flipTimerToDate,
                countDownTimerToDate
            }

            setFormValues({
                ...formValues,
                countDownWidgetSettings
            });

            setInitialFormValues({
                ...initialFormValues,
                countDownWidgetSettings
            });

            setWidgetValues(widgetData);
        }
    }, [dashboardFetchStatus]);

    useEffect(() => {
        if (formValues && Object.keys(formValues).length > 0) {
            const updatedWidgetValues = mapFormValuesToCountDownWidget(formValues);
            setWidgetValues(updatedWidgetValues);
        }
    }, [formValues]);

    const handleToggle = (id) => {
        setOpenId(openId === id ? null : id);
    };

    const handleChange = (cardKey, field, value) => {
        let updatedValue = value;
        if (value === "true" || value === "false") {
            updatedValue = value === "true" ? true : false;
        }
        setFormValues((prevValues) => ({
            ...prevValues,
            [cardKey]: {
                ...prevValues[cardKey],
                [field]: updatedValue,
            },
        }));
    };

    const handleError = (cardKey, field, error) => {
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [cardKey]: {
                ...prevErrors[cardKey],
                [field]: error,
            },
        }));
    };

    const handleUpdate = (cardKey) => {
        const widgetData = get(dashboardData, "data.shop.widget", {});
        const payload = Object.assign({}, widgetData, widgetValues);
        const data = {
            widget: {
                ...payload,
            },
        };
        dispatch(updateCustomizeWidget({ app, payload: data }))
            .then((res) => {
                show('Widget updated')
                dispatch(fetchDashboardData({ app }));

            })
            .catch((e) => {
                show('Widget update failed')
            });
    };

    const handleCancel = (cardKey) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            [cardKey]: initialFormValues[cardKey],
        }));
    };

    const hasUnsavedChanges = (cardKey) => {
        return (
            JSON.stringify(formValues[cardKey]) !==
            JSON.stringify(initialFormValues[cardKey])
        );
    };

    const handleMinimize = () => {
        setMinimize(prev => !prev)
    };

    return (
        <div>
            {
                width >= 1040 && !minimize ? <Grid>
                    <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 7, xl: 7 }}>
                        <Card padding={0}>
                            {countDownWidgetCards.map(({ id, title, content, cardKey }) => (
                                <CollapsibleCard
                                    key={cardKey}
                                    id={id}
                                    title={title}
                                    content={content}
                                    isOpen={openId === id}
                                    onToggle={handleToggle}
                                    cardKey={cardKey}
                                    formValues={formValues}
                                    formErrors={formErrors}
                                    handleChange={handleChange}
                                    handleError={handleError}
                                    handleUpdate={handleUpdate}
                                    handleCancel={handleCancel}
                                    hasUnsavedChanges={hasUnsavedChanges(cardKey)}
                                    dashboardFetchStatus={dashboardFetchStatus}
                                />
                            ))}
                        </Card>
                    </Grid.Cell>
                    <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 5, xl: 5 }}>
                        <LegacyCard sectioned>
                            <WidgetWrapper
                                minimize={minimize}
                                handleMinimize={handleMinimize}
                                isWidgetVisible={
                                    dashboardFetchStatus === fetchStatus.resolved && formValues &&
                                        Object.keys(formValues).length > 0 &&
                                        formValues[COUNTDOWN_WIDGET_KEYS.CUSTOMIZE_COUNTDOWN_WIDGET_SETTINGS].enableWidget ? true : false
                                }
                                dashboardFetchStatus={dashboardFetchStatus}>
                                <CountDownWidgetRenderer
                                    res={dashboardData}
                                    widgetValues={widgetValues}
                                    dashboardFetchStatus={dashboardFetchStatus}
                                />
                            </WidgetWrapper>

                        </LegacyCard>
                    </Grid.Cell>

                </Grid> :
                    <Grid>
                        <Grid.Cell columnSpan={{ xs: minimize ? 12 : 6, sm: minimize ? 12 : 6, md: minimize ? 12 : 6, lg: minimize ? 12 : 7, xl: minimize ? 12 : 7 }}>
                            <LegacyCard sectioned>
                                <WidgetWrapper
                                    minimize={minimize}
                                    handleMinimize={handleMinimize}
                                    isWidgetVisible={
                                        dashboardFetchStatus === fetchStatus.resolved && formValues &&
                                            Object.keys(formValues).length > 0 &&
                                            formValues[COUNTDOWN_WIDGET_KEYS.CUSTOMIZE_COUNTDOWN_WIDGET_SETTINGS].enableWidget ? true : false
                                    }
                                    dashboardFetchStatus={dashboardFetchStatus}>
                                    <CountDownWidgetRenderer
                                        res={dashboardData}
                                        widgetValues={widgetValues}
                                        dashboardFetchStatus={dashboardFetchStatus}
                                    />
                                </WidgetWrapper>

                            </LegacyCard>
                        </Grid.Cell>
                        <Grid.Cell columnSpan={{ xs: minimize ? 12 : 6, sm: minimize ? 12 : 6, md: minimize ? 12 : 6, lg: minimize ? 12 : 5, xl: minimize ? 12 : 5 }}>
                            <Card padding={0}>
                                {countDownWidgetCards.map(({ id, title, content, cardKey }) => (
                                    <CollapsibleCard
                                        key={cardKey}
                                        id={id}
                                        title={title}
                                        content={content}
                                        isOpen={openId === id}
                                        onToggle={handleToggle}
                                        cardKey={cardKey}
                                        formValues={formValues}
                                        formErrors={formErrors}
                                        handleChange={handleChange}
                                        handleError={handleError}
                                        handleUpdate={handleUpdate}
                                        handleCancel={handleCancel}
                                        hasUnsavedChanges={hasUnsavedChanges(cardKey)}
                                        dashboardFetchStatus={dashboardFetchStatus}
                                    />
                                ))}
                            </Card>
                        </Grid.Cell>

                    </Grid>
            }

        </div>
    );
};

export default SoldCountWidget;

const CollapsibleCard = (props) => {
    const {
        id,
        title,
        isOpen,
        onToggle,
        cardKey,
        formValues,
        formErrors,
        handleChange,
        handleError,
        handleUpdate,
        handleCancel,
        hasUnsavedChanges,
        dashboardFetchStatus
    } = props;
    return (
        <div className={classes.wrapContainerCss}>
            <div className={classes.wrapTitleCss} onClick={() => onToggle(id)}>
                <div>
                    <InlineStack gap={100}>
                        <Text as="p" fontWeight="semibold">
                            {title}
                        </Text>

                        {dashboardFetchStatus === fetchStatus.resolved && formValues &&
                            Object.keys(formValues).length > 0 &&
                            formValues[cardKey].enableWidget ? (
                            <Badge tone="success" size="small">
                                On
                            </Badge>
                        ) : (
                            <Badge tone="default" size="small">
                                Off
                            </Badge>
                        )}
                    </InlineStack>
                </div>
                <div>
                    {isOpen ? (
                        <Icon source={ChevronUpIcon} />
                    ) : (
                        <Icon source={ChevronDownIcon} />
                    )}
                </div>
            </div>
            {isOpen && (
                <div className={classes.formWrapperCss}>
                    <RenderForm
                        cardKey={cardKey}
                        dashboardFetchStatus={dashboardFetchStatus}
                        formValues={formValues}
                        formErrors={formErrors}
                        handleChange={handleChange}
                        handleError={handleError}
                    />
                    {hasUnsavedChanges && (
                        <div className={classes.actionButtonWrapperCss}>
                            <ButtonGroup>
                                <Button onClick={() => handleCancel(cardKey)}>Cancel</Button>
                                <Button variant="primary" onClick={() => handleUpdate(cardKey)}>
                                    Save
                                </Button>
                            </ButtonGroup>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

const RenderForm = ({
    cardKey,
    dashboardFetchStatus,
    formValues,
    formErrors,
    handleChange,
    handleError,
}) => {
    const FormComponent = formComponents[cardKey];

    const formProps = {
        value: formValues[cardKey] || {},
        error: formErrors[cardKey] || {},
        onChange: (field, value) => handleChange(cardKey, field, value),
        onError: (field, error) => handleError(cardKey, field, error),
    };

    return (
        <div>
            {dashboardFetchStatus === fetchStatus.pending && <FormSkeleton rows={layoutPattern} />}
            {dashboardFetchStatus === fetchStatus.resolved && FormComponent && <FormComponent {...formProps} />}
            {
                dashboardFetchStatus === fetchStatus.rejected && (
                    <div>Error while fetching form</div>
                )
            }

        </div>
    );
};
