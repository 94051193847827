import { Button, Card ,Text} from "@shopify/polaris";
import classes from './styles.module.css';
import React from "react";
const GetInTouchBar=({displayText,btnText,btnHandler})=>{
    return(
        <Card>
            <div className={classes.textWrapperCss}>
            <Text as="p" fontWeight="bold">
            {displayText}
            </Text>
            <div className={classes.buttonWrapperCss}>
            <Button variant="primary" onClick={btnHandler}>{btnText}</Button>
            </div>
            </div>
           
        </Card>
    )
}

export default GetInTouchBar;