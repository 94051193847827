import {Route, Routes} from "react-router-dom";
import Dashboard from "../Dashboard";
import Customize from "../Customize";
import Settings from "../Settings";
import SoldCounterContactPage from "../../pages/contactPage";
import SoldCounterPlanPage from "../../pages/planPage";
import FAQSection from "../../../../components/FAQ";
import Base from "../Base";

const SoldCounterRouter = () => {
    const appBasePathName = '/sold-counter';
    return (
        <Routes>
            <Route path={appBasePathName} element={<Base />}>
                <Route index element={<Dashboard />} />
                <Route path='dashboard' element={<Dashboard />} />
                <Route path='customize' element={<Customize />} />
                <Route path='plans' element={<SoldCounterPlanPage />} />
                <Route path='faq' element={<FAQSection appName="sold-counter" />} />
                <Route path='contact' element={<SoldCounterContactPage />} />
                <Route path='settings' element={<Settings />} />
                {/* <Route path='/login' element={<ConnectYourAccount />} /> */}
            </Route>
        </Routes>
    );
};

export default SoldCounterRouter;