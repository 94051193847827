export const fetchStatus = {
    idle: 'IDLE',
    pending: 'PENDING',
    resolved: 'RESOLVED',
    rejected: 'REJECTED'
}

export const plansOptions = [
    {
        id: "free",
        name: "Free",
        priceMonthly: 0,
        priceYearly: 0,
        features: [
            {
                name: "Forever free",
                subheading: "Forever free - Enjoy Instafeed for free forever!",
                available: true,
            },
            {
                name: "Instagram authentication",
                subheading:
                    "Connect your Instagram account using Instagram authentication",
                available: true,
            },
            {
                name: "Grid or slider layout",
                subheading: "Show your feed in a grid or carousel layout",
                available: true,
            },
            {
                name: 'Reels support',
                subheading: 'Show reels on your feed',
                available: true
            },
            {
                name: "`Powered by` branding",
                subheading:
                    "Our app branding appears below the feed. Contact our chat team to remove it for free :)",
                available: true,
            },
            {
                name: "Feed auto-sync every 24 hours",
                subheading: "New posts are synched every 24 hours",
                available: true,
            },
            { name: "24x7 live chat support", available: true },
        ],
        popular: false,
    },
    {
        id: "pro",
        name: "Plus",
        priceMonthly: 5.99,
        priceYearly: 55.99,
        features: [
            { name: "Everything in free", available: true },
            { name: "No branding", available: true },
            {
                name: "Facebook business authentication",
                subheading:
                    "Connect your Instagram account using Facebook Business authentication",
                available: true,
            },
            {
                name: "Product tagging",
                subheading:
                    "Tag products on your Instagram posts and reels to make them shoppable",
                available: true,
            },
            {
                name: "Feed auto-sync on demand",
                subheading: "Sync your Instagram feed real-time",
                available: true,
            },
            {
                name: "Show stories & profile picture",
                subheading: "Display stories & actual Instagram profile photo.",
                available: true,
            },
            {
                name: "Autoplay feature",
                subheading:
                    "Smooth animation to cycle through posts in the slider layout",
                available: true,
            },
            {
                name: "Hide posts",
                subheading: "Ability to hide chosen posts from your feed",
                available: true,
            },
        ],
        popular: true,
    },
];
