import {
    Badge,
    Button,
    ButtonGroup,
    Card,
    Grid,
    Icon,
    InlineStack,
    LegacyCard,
    Text,
} from "@shopify/polaris";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchDashboardData,
    updateCustomizeWidget,
} from "../../../slices/SoldCounterSlices/dashboardSlice";
import { ChevronDownIcon, ChevronUpIcon } from "@shopify/polaris-icons";
import {
    COUNT_WIDGET_KEYS,
    countWidgetCards,
    mapFormValuesToWidget,
} from "../constant";
import classes from "./styles.module.css";

import CountWidgetRender from "../../WidgetRenderer/CountWidget/index";
import DisplayTextForm from "../Forms/Common/displayTextForm";
import ValueTextForm from "../Forms/Common/valueTextForm";
import SeparatorTextForm from "../Forms/seperatorTextForm";
import SoldEmojiTextForm from "../Forms/soldEmojiTextForm";
import WidgetWrapper from "../../WidgetRenderer/WidgetWrapper";
import { fetchStatus } from "../../../../../utils/constant";
import { useAppBridge, useToast } from "@shopify/app-bridge-react";
import { useWindowWidth } from "@react-hook/window-size";
import FormSkeleton from "../Forms/Common/formSkelton";

const layoutPattern = [
    ['full'],
    ['half', 'half'],
    ['full', 'full'],
];


const formComponents = {
    [COUNT_WIDGET_KEYS.CUSTOMIZE_SOLD_COUNTER_DISPLAY_TEXT]: DisplayTextForm,
    [COUNT_WIDGET_KEYS.CUSTOMIZE_SOLD_COUNTER_VALUE_TEXT]: ValueTextForm,
    [COUNT_WIDGET_KEYS.CUSTOMIZE_AVAILABILITY_DISPLAY_TEXT]: DisplayTextForm,
    [COUNT_WIDGET_KEYS.CUSTOMIZE_AVAILABILITY_VALUE_TEXT]: ValueTextForm,
    [COUNT_WIDGET_KEYS.CUSTOMIZE_SEPARATOR_TEXT]: SeparatorTextForm,
    [COUNT_WIDGET_KEYS.CUSTOMIZE_EMOJI]: SoldEmojiTextForm,
};
const CountDownWidget = (props) => {
    const { dashboardFetchStatus, dashboardData } = props
    const app = useAppBridge();
    const { show } = useToast()
    const width = useWindowWidth();
    const dispatch = useDispatch();
    const [openId, setOpenId] = useState(null);
    const [formValues, setFormValues] = useState({});
    const [initialFormValues, setInitialFormValues] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [widgetValues, setWidgetValues] = useState({});

    useEffect(() => {
        if (dashboardFetchStatus === fetchStatus.resolved) {
            const inStockEnabled = get(
                dashboardData,
                "data.shop.widget.inStockEnabled",
                false
            );
            const separatorEnabled = get(
                dashboardData,
                "data.shop.widget.separatorEnabled",
                false
            );
            const soldEnabled = get(
                dashboardData,
                "data.shop.widget.soldEnabled",
                false
            );
            const soldSmileyEnabled = get(
                dashboardData,
                "data.shop.widget.soldSmileyEnabled",
                false
            );

            const inStockPrefixText = get(
                dashboardData,
                "data.shop.widget.inStockPrefixText",
                ""
            );
            const inStockText = get(
                dashboardData,
                "data.shop.widget.inStockText",
                ""
            );

            const separatorText = get(
                dashboardData,
                "data.shop.widget.separatorText",
                ""
            );
            const soldPrefixText = get(
                dashboardData,
                "data.shop.widget.soldPrefixText",
                ""
            );

            const soldSmiley = get(dashboardData, "data.shop.widget.soldSmiley", "");
            const soldText = get(dashboardData, "data.shop.widget.soldText", "");

            //Group
            let inStockTextStyle = get(
                dashboardData,
                "data.shop.widget.inStockTextStyle",
                {}
            );
            let inStockValueStyle = get(
                dashboardData,
                "data.shop.widget.inStockValueStyle",
                {}
            );
            let separatorStyle = get(
                dashboardData,
                "data.shop.widget.separatorStyle",
                {}
            );

            let soldTextStyle = get(
                dashboardData,
                "data.shop.widget.soldTextStyle",
                {}
            );
            let soldValueStyle = get(
                dashboardData,
                "data.shop.widget.soldValueStyle",
                {}
            );

            const widgetData = get(dashboardData, "data.shop.widget", {});

            //Group fields for soldTextStyle
            const countWidgetCustomizeSoldCounterDisplayText = {
                enableWidget: soldEnabled,
                suffixText: soldText,
                prefixText: soldPrefixText,
                fontSize: soldTextStyle.fontSize,
                bold: soldTextStyle.bold,
                color: soldTextStyle.color,
            };

            //Group fields for inStockTextStyle
            const countWidgetCustomizeAvailabilityDisplayText = {
                enableWidget: inStockEnabled,
                suffixText: inStockText,
                prefixText: inStockPrefixText,
                fontSize: inStockTextStyle.fontSize,
                bold: inStockTextStyle.bold,
                color: inStockTextStyle.color,
            };

            //Group fields for separatorStyle
            const countWidgetCustomizeSeparatorText = {
                enableWidget: separatorEnabled,
                suffixText: separatorText,
                fontSize: separatorStyle.fontSize,
                bold: separatorStyle.bold,
                color: separatorStyle.color,
            };

            const countWidgetCustomizeEmoji = {
                enableWidget: soldSmileyEnabled,
                soldSmiley: soldSmiley,
            };

            const countWidgetCustomizeSoldCounterValueText = {
                fontSize: soldValueStyle.fontSize,
                bold: soldValueStyle.bold,
                color: soldValueStyle.color,
            };

            const countWidgetCustomizeAvailabilityValueText = {
                fontSize: inStockValueStyle.fontSize,
                bold: inStockValueStyle.bold,
                color: inStockValueStyle.color,
            };

            setFormValues({
                ...formValues,
                countWidgetCustomizeSoldCounterDisplayText,
                countWidgetCustomizeAvailabilityDisplayText,
                countWidgetCustomizeSeparatorText,
                countWidgetCustomizeEmoji,
                countWidgetCustomizeSoldCounterValueText,
                countWidgetCustomizeAvailabilityValueText,
            });

            setInitialFormValues({
                ...initialFormValues,
                countWidgetCustomizeSoldCounterDisplayText,
                countWidgetCustomizeAvailabilityDisplayText,
                countWidgetCustomizeSeparatorText,
                countWidgetCustomizeEmoji,
                countWidgetCustomizeSoldCounterValueText,
                countWidgetCustomizeAvailabilityValueText,
            });

            setWidgetValues(widgetData);
        }
    }, [dashboardFetchStatus]);

    useEffect(() => {
        if (formValues && Object.keys(formValues).length > 0) {
            const updatedWidgetValues = mapFormValuesToWidget(formValues);
            setWidgetValues(updatedWidgetValues);
        }
    }, [formValues]);

    const handleToggle = (id) => {
        setOpenId(openId === id ? null : id);
    };

    const handleChange = (cardKey, field, value) => {
        let updatedValue = value;
        if (value === "true" || value === "false") {
            updatedValue = value === "true" ? true : false;
        }
        setFormValues((prevValues) => ({
            ...prevValues,
            [cardKey]: {
                ...prevValues[cardKey],
                [field]: updatedValue,
            },
        }));
    };

    const handleError = (cardKey, field, error) => {
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [cardKey]: {
                ...prevErrors[cardKey],
                [field]: error,
            },
        }));
    };

    const handleUpdate = (cardKey) => {
        const widgetData = get(dashboardData, "data.shop.widget", {});
        const payload = Object.assign({}, widgetData, widgetValues);
        const data = {
            widget: {
                ...payload,
            },
        };
        dispatch(updateCustomizeWidget({ app, payload: data }))
            .then((res) => {
                show('Widget updated')
                dispatch(fetchDashboardData({ app }));
            })
            .catch((e) => {
                show('Widget update failed')
            });
    };

    const handleCancel = (cardKey) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            [cardKey]: initialFormValues[cardKey],
        }));
    };

    const hasUnsavedChanges = (cardKey) => {
        return (
            JSON.stringify(formValues[cardKey]) !==
            JSON.stringify(initialFormValues[cardKey])
        );
    };

    return (
        <div>
            {width >= 1040 ? (
                <Grid>
                    <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 7, xl: 7 }}>
                        <Card padding={0}>
                            {countWidgetCards.map(
                                ({ id, title, content, cardKey, parent }) => (
                                    <CollapsibleCard
                                        key={cardKey}
                                        id={id}
                                        title={title}
                                        content={content}
                                        isOpen={openId === id}
                                        onToggle={handleToggle}
                                        cardKey={cardKey}
                                        formValues={formValues}
                                        formErrors={formErrors}
                                        handleChange={handleChange}
                                        handleError={handleError}
                                        handleUpdate={handleUpdate}
                                        handleCancel={handleCancel}
                                        hasUnsavedChanges={hasUnsavedChanges(cardKey)}
                                        dashboardFetchStatus={dashboardFetchStatus}
                                        parent={parent}
                                    />
                                )
                            )}
                        </Card>
                    </Grid.Cell>
                    <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 5, xl: 5 }}>
                        <LegacyCard sectioned>
                            <WidgetWrapper
                                dashboardFetchStatus={dashboardFetchStatus}
                                showBadge={false}
                            >
                                <Card padding={0}>
                                    <div className={classes.widgetPreviewCss}>Widget preview</div>
                                    <div style={{ padding: "12px 16px" }}>
                                        <CountWidgetRender
                                            res={dashboardData}
                                            widgetValues={widgetValues}
                                            dashboardFetchStatus={dashboardFetchStatus}
                                        />
                                    </div>
                                </Card>
                            </WidgetWrapper>
                        </LegacyCard>
                    </Grid.Cell>
                </Grid>
            ) : (
                <Grid>
                    <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 7, xl: 7 }}>
                        <LegacyCard sectioned>
                            <WidgetWrapper
                                dashboardFetchStatus={dashboardFetchStatus}
                                showBadge={false}
                            >
                                <Card padding={0}>
                                    <div className={classes.widgetPreviewCss}>Widget preview</div>
                                    <div style={{ padding: "12px 16px" }}>
                                        <CountWidgetRender
                                            res={dashboardData}
                                            widgetValues={widgetValues}
                                            dashboardFetchStatus={dashboardFetchStatus}
                                        />
                                    </div>
                                </Card>
                            </WidgetWrapper>
                        </LegacyCard>
                    </Grid.Cell>
                    <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 5, xl: 5 }}>
                        <Card padding={0}>
                            {countWidgetCards.map(
                                ({ id, title, content, cardKey, parent }) => (
                                    <CollapsibleCard
                                        key={cardKey}
                                        id={id}
                                        title={title}
                                        content={content}
                                        isOpen={openId === id}
                                        onToggle={handleToggle}
                                        cardKey={cardKey}
                                        formValues={formValues}
                                        formErrors={formErrors}
                                        handleChange={handleChange}
                                        handleError={handleError}
                                        handleUpdate={handleUpdate}
                                        handleCancel={handleCancel}
                                        hasUnsavedChanges={hasUnsavedChanges(cardKey)}
                                        dashboardFetchStatus={dashboardFetchStatus}
                                        parent={parent}
                                    />
                                )
                            )}
                        </Card>
                    </Grid.Cell>
                </Grid>
            )}
        </div>
    );
};
export default CountDownWidget;

const CollapsibleCard = (props) => {
    const {
        id,
        title,
        isOpen,
        onToggle,
        cardKey,
        formValues,
        formErrors,
        handleChange,
        handleError,
        handleUpdate,
        handleCancel,
        hasUnsavedChanges,
        dashboardFetchStatus,
        parent,
    } = props;

    const renderBadge = () => {
        return (
            <React.Fragment>
                {dashboardFetchStatus === fetchStatus.resolved &&
                    formValues &&
                    Object.keys(formValues).length > 0 &&
                    formValues[parent ? parent : cardKey].enableWidget ? (
                    <Badge tone="success" size="small">
                        On
                    </Badge>
                ) : (
                    <Badge tone="default" size="small">
                        Off
                    </Badge>
                )}
            </React.Fragment>
        );
    };

    return (
        <div className={classes.wrapContainerCss}>
            <div className={classes.wrapTitleCss} onClick={() => onToggle(id)}>
                <div>
                    <InlineStack gap={100}>
                        <Text as="p" fontWeight="semibold">
                            {title}
                        </Text>

                        {renderBadge()}
                    </InlineStack>
                </div>
                <div>
                    {isOpen ? (
                        <Icon source={ChevronUpIcon} />
                    ) : (
                        <Icon source={ChevronDownIcon} />
                    )}
                </div>
            </div>
            {isOpen && (
                <div className={classes.formWrapperCss}>
                    <RenderForm
                        cardKey={cardKey}
                        dashboardFetchStatus={dashboardFetchStatus}
                        formValues={formValues}
                        formErrors={formErrors}
                        handleChange={handleChange}
                        handleError={handleError}
                    />
                    {hasUnsavedChanges && (
                        <div className={classes.actionButtonWrapperCss}>
                            <ButtonGroup>
                                <Button onClick={() => handleCancel(cardKey)}>Cancel</Button>
                                <Button variant="primary" onClick={() => handleUpdate(cardKey)}>
                                    Save
                                </Button>
                            </ButtonGroup>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

const RenderForm = ({
    cardKey,
    dashboardFetchStatus,
    formValues,
    formErrors,
    handleChange,
    handleError,
}) => {
    const FormComponent = formComponents[cardKey];

    const formProps = {
        value: formValues[cardKey] || {},
        error: formErrors[cardKey] || {},
        onChange: (field, value) => handleChange(cardKey, field, value),
        onError: (field, error) => handleError(cardKey, field, error),
    };

    return (
        <div>
            {dashboardFetchStatus === fetchStatus.pending && <FormSkeleton rows={layoutPattern} />}
            {dashboardFetchStatus === fetchStatus.resolved && FormComponent && <FormComponent {...formProps} />}
            {dashboardFetchStatus === fetchStatus.resolved && dashboardFetchStatus !== fetchStatus.pending && !FormComponent && <Text>No form found</Text>}
            {dashboardFetchStatus === fetchStatus.rejected && (
                <div>Some error loading form</div>
            )}
        </div>
    );
};
