import React, { useEffect } from "react";
import { fetchStatus } from "../../../../../utils/constant";

const CountDownWidgetRenderer = ({ res, widgetValues, dashboardFetchStatus }) => {
    useEffect(() => {
        const fetchData = async () => {
            try {
                const script = document.createElement("script");
                script.text = res.data.widgetHtml;
                const jsCode = `
                var widget=${JSON.stringify(widgetValues)};
                zegsuSoldCounter.loadCountdown(widget)
                `;

                script.text += jsCode;

                document.body.appendChild(script);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        if (dashboardFetchStatus === fetchStatus.resolved) {
            fetchData();
        }
    }, [res, dashboardFetchStatus, widgetValues]);

    return <><style>{`#zegsuCountdownCanvas{zoom:0.75;}`}</style><div id="zegsu-sold-counter"></div></>;
};

export default CountDownWidgetRenderer;
