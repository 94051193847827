import React, { useCallback, useEffect, useState } from "react";
import AccountInfoHeader from "../Cards/AccountInfoHeader";
import InstaFeedDetailForm from "../Forms/InstaFeedDetail";
import classes from "./styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardData, refreshState } from "../../slices/InstaGenieSlices/dashboardSlice";
import { get, isEqual } from "lodash";
import WidgetRenderer from "../Widget";
import { ArrowUpIcon, RefreshIcon, SettingsIcon } from "@shopify/polaris-icons";
import { MobileIcon, DesktopIcon } from "@shopify/polaris-icons";
import { Redirect } from '@shopify/app-bridge/actions';
import {
  Card,
  Text,
  Divider,
  Button,
  ButtonGroup,
  Page,
  Layout,
  Grid,
  Tooltip,
  Badge,
  InlineStack,
  Banner,
} from "@shopify/polaris";
import SelectProductModal from "../Modal";
import DesktopWidget from "../Skeletons/Widget";
import { unlinkMedia } from "../../slices/InstaGenieSlices/mediaSlice";
import { fetchStatus } from "../../utils/constant";
import PageLoadingIndicator from "../../../../components/Loading";
import { useAppBridge, useNavigate, useToast } from "@shopify/app-bridge-react";
import DashboardSkeleton from "../Skeletons/Dashboard";
import AccountLists from "../Modal/AccountLists";
import GetInTouchBar from "../GetInTouchBar";
import { AppEnableConfig } from "../Modal/AppEnableConfig";
import { getShopifySessionToken } from "../../../../utils/authUtils";
import { extractIdFromEventDetail, extractIdsFromEventDetail } from "../../utils/helper";
const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { show } = useToast();
  const app = useAppBridge();
  const redirect = Redirect.create(app);

  const dashboardFetchStatus = useSelector(
    (state) => state.dashboard.fetchStatus
  );
  const refreshFetchStatus = useSelector(
    (state) => state.dashboard.refreshFetchStatus
  );

  const [currentActivePreview, setcurrentActivePreview] = useState("mobile");

  const [showTagProductModal, setShowTagProductModal] = useState(false);
  const [decideWhetherToShowProductModal, setDecideWhetherToShowProductModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showEditButton, setShowEditButton] = useState(false);
  const [originalValues, setOriginalValues] = useState({});
  const [selectedMediaId, setSelectedMediaId] = useState(null);
  const [showAccountListModal, setShowAccountListModal] = useState(false);
  const [refreshId, setRefreshId] = useState(1);
  const [showAppConfigureModal, setShowAppConfigureModal] = useState(false);
  const [plusCustomer, SetPlusCustomer] = useState(false);
  const [navigationInProgress, setNavigationInProgress] = useState(false);

  const dashboardData = useSelector((state) => state.dashboard);
  const instagramData = get(dashboardData, "data.shop.instagram", {}) || {};
  const instaNotLinked = get(
    dashboardData,
    "data.shop.instagram.needReAuth",
    false
  );
  const instagramUserName = get(instagramData, "username", "") || "";
  const widgetDetails = get(dashboardData, "data.shop.widget", {}) || {};
  const currentPlan = get(dashboardData, "data.shop.plan", "") || "";
  const integrationType = get(instagramData, "integrationType", "") || "";
  const dataFetchStatus = get(dashboardData, "fetchStatus", "IDLE");
  const widgetEnabled = get(dashboardData, "data.shop.widgetEnabled", false);


  const [formValues, setFormValues] = useState({
    feedTitle: "",
    widgetType: "GRID",
    autoPlay: false,
    numImagesInRowMobile: 0,
    numImagesInRow: 0,
    numRowsMobile: 0,
    numRows: 0,
    spacing: 0,
    numberImages: 0,
    checksum: "",
    storiesEnabled: false
  });

  const handleMobilePreview = useCallback(() => {
    setcurrentActivePreview("mobile");
  }, [currentActivePreview]);

  const handleDesktopPreview = useCallback(() => {
    setcurrentActivePreview("desktop");
  }, [currentActivePreview]);

  useEffect(() => {
    if (instaNotLinked) {
      navigate("/shopify/instagenie/login");
    }
  }, [instaNotLinked]);

  useEffect(() => {
    dispatch(fetchDashboardData({ app }));
  }, [refreshId]);

  useEffect(() => {
    SetPlusCustomer(currentPlan === 'PRIME')
  }, [currentPlan])



  useEffect(() => {
    if (instagramData) {
      const instagramId = get(instagramData, "id", "");
      const userToken = get(instagramData, "userToken", "");
      const needReAuth = get(instagramData, "needReAuth", "");
      if (
        integrationType === "GRAPH" &&
        (instagramId === "" || instagramUserName === "") &&
        userToken &&
        needReAuth === false
      ) {
        setShowAccountListModal(true);
      }
    }
  }, [instagramData]);

  useEffect(() => {
    document.addEventListener("redirectToProduct", (event) => {
      const productId = extractIdFromEventDetail(event);
      if (productId) {
        navigate({
          name: 'Product',
          resource: {
            id: productId,
          }
        });
      }
    });

    document.addEventListener("addProduct", (event) => {
      const mediaId = extractIdFromEventDetail(event);
      if (mediaId) {
        setSelectedMediaId(mediaId);
      }
      setShowTagProductModal(true);

    });

    document.addEventListener("removeProduct", (e) => {
      const { mediaId, productId } = extractIdsFromEventDetail(e);
      if (mediaId) {
        const payload = {
          mediaId,
          productId
        };
        dispatch(unlinkMedia({ payload, app })).then(() => {
          show("Product unlinked");
          setRefreshId((prev) => prev + 1);
        });
      }
    });
  }, [plusCustomer]);

  useEffect(() => {
    if (dashboardFetchStatus === 'RESOLVED' && !decideWhetherToShowProductModal) {
      if (plusCustomer) {
        setDecideWhetherToShowProductModal(true);
      } else {
        show('Upgrade to PLUS!')
      }
    }
  }, [showTagProductModal])

  useEffect(() => {
    if (widgetDetails && !isEqual(widgetDetails, originalValues)) {
      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };

        Object.entries(widgetDetails).forEach(([key, value]) => {
          // Only update if the value is not null or undefined
          if (value !== null && value !== undefined) {
            updatedValues[key] = value;
          }
        });

        return updatedValues;
      });

      setOriginalValues(widgetDetails);
      if (
        widgetDetails &&
        widgetDetails.checksum &&
        widgetDetails.checksum !== "init"
      ) {
        setShowEditButton(true);
      }
    }
  }, [widgetDetails]);

  const closeTagProductModalHandler = () => {
    setSelectedMediaId(null);
    setShowTagProductModal(false);
    setDecideWhetherToShowProductModal(false)
  };

  const pullFreshInstagramData = () => {
    dispatch(refreshState({ app })).then(() => {
      setRefreshId((prev) => prev + 1);
    });
  };

  const handleCloseAccountListModal = (reFetch) => {
    setShowAccountListModal(false);
    if (reFetch) {
      setRefreshId((prev) => prev + 1);
    }
  };

  const widgetEnabledHandler = () => {
    setShowAppConfigureModal(true);
  };

  const closeAppConfigureModal = () => {
    setShowAppConfigureModal(false);
  };

  const handlePlanUpgrade = async () => {
    setNavigationInProgress(true);
    const token = await getShopifySessionToken(app);
    redirect.dispatch(Redirect.Action.REMOTE,
      { url: `https://zegsuapps.com/shopify/instagenie/change-plan/PRIME?authToken=${token}` })

  }

  const tooltipContent = widgetEnabled ? "Disable the app" : "Enable the app";
  const facebookLinked = instagramUserName && integrationType === "GRAPH";

  return (
    <Page >
      {dashboardFetchStatus === fetchStatus.pending && (
        <React.Fragment>
          <PageLoadingIndicator />
          <DashboardSkeleton />
        </React.Fragment>
      )}
      {dashboardFetchStatus === fetchStatus.resolved && !instaNotLinked && (
        <div className={classes.dashboardWrapperCss}>
          <Layout>
            {!facebookLinked && plusCustomer && <Layout.Section>
              {/* <Card>
              <InlineStack gap="200" align="center">
              <Icon source={InfoIcon} tone="info" />
              <Text as="h2" variant="bodyMd">
              You need to log in with Facebook to use the Plus features
      </Text>
              </InlineStack>
            </Card> */}
              <Banner>
                <p>
                  You need to log in with Facebook to use the Plus features
                </p>
              </Banner>

            </Layout.Section>
            }
            <Layout.Section>
              <Card>
                <div className={classes.enableDisableWrapperCss}>
                  <InlineStack gap="100" blockAlign="center">
                    <Text variant="headingMd" as="h6">
                      Instafeed Dashboard
                    </Text>
                    {plusCustomer ? <Badge tone="success">Plus</Badge> : <Badge tone="attention">Free</Badge>}
                  </InlineStack>
                  <div className={classes.buttonsOnLargerScreenCss}>
                    <InlineStack gap="400" blockAlign="center">
                      <Tooltip content={tooltipContent} dismissOnMouseOut>
                        <Button
                          variant={widgetEnabled ? "default" : "primary"}
                          onClick={widgetEnabledHandler}
                        >
                          {widgetEnabled ? "Disable" : "Enable"}
                        </Button>
                      </Tooltip>
                      {!plusCustomer && <Button
                        icon={ArrowUpIcon}
                        loading={navigationInProgress}
                        variant="primary" onClick={handlePlanUpgrade}>Upgrade to plus</Button>}
                    </InlineStack>
                  </div>
                  <div className={classes.buttonsOnSmallerScreenCss}>
                    <InlineStack gap="400" blockAlign="center">
                      <Tooltip content={tooltipContent} dismissOnMouseOut>
                        <Button
                          variant="tertiary"
                          onClick={widgetEnabledHandler}
                        >
                          {widgetEnabled ? "Disable" : "Enable"}
                        </Button>
                      </Tooltip>
                      {!plusCustomer && <Button
                        icon={ArrowUpIcon}
                        variant="tertiary" onClick={handlePlanUpgrade}>Upgrade to plus</Button>}
                    </InlineStack>
                  </div>
                </div>
              </Card>
            </Layout.Section>
            <Layout.Section>
              <AccountInfoHeader
                currentPlan={currentPlan}
                instagramUserName={instagramUserName}
                integrationType={integrationType}
              />
            </Layout.Section>
            <Layout.Section>
              {currentActivePreview === "mobile" ? (
                <div className={classes.gridContainer}>
                  <div className={classes.div1}>
                    <InstaFeedDetailForm
                      widgetDetails={widgetDetails}
                      formValues={formValues}
                      setFormValues={setFormValues}
                      originalValues={originalValues}
                      setOriginalValues={setOriginalValues}
                      setIsEditMode={setIsEditMode}
                      isEditMode={isEditMode}
                      showEditButton={showEditButton}
                      setShowEditButton={setShowEditButton}
                      currentPlan={currentPlan}
                    />

                  </div>
                  <div className={classes.div2}>
                    <div className={classes.widgetWrapperCss}>
                      <Card>
                        <div className={classes.widgetBoxWrapperCss}>
                          {dashboardData &&
                            Object.keys(dashboardData).length > 0 && (
                              <WidgetRenderer
                                res={dashboardData}
                                currentActivePreview={currentActivePreview}
                                formValues={formValues}
                              />
                            )}
                        </div>
                        <div className={classes.dividerGapCss}>
                          <Divider borderColor="border" />
                        </div>
                        <div className={classes.headingWrapperCss}>
                          <Text variant="headingSm" as="h6">
                            Preview
                          </Text>
                        </div>
                        {instagramUserName && (
                          <div className={classes.subHeadingCss}>
                            <Text as="p" tone="subdued">
                              Showing feed from @{instagramUserName} account
                            </Text>
                          </div>
                        )}
                        <div className={classes.buttonGroupWrapperCss}>
                          <ButtonGroup>
                            <Button
                              icon={MobileIcon}
                              pressed={currentActivePreview === "mobile"}
                              onClick={handleMobilePreview}
                            >
                              Mobile
                            </Button>
                            <Button
                              icon={DesktopIcon}
                              pressed={currentActivePreview === "desktop"}
                              onClick={handleDesktopPreview}
                            >
                              Desktop
                            </Button>
                            {
                              plusCustomer ? <Button
                                icon={RefreshIcon}
                                onClick={pullFreshInstagramData}
                                loading={refreshFetchStatus === fetchStatus.pending}

                              >
                                Refresh
                              </Button> :
                                <InlineStack gap="200">
                                  <Tooltip content="Upgrade to plus">
                                    <Button
                                      icon={RefreshIcon}
                                      onClick={pullFreshInstagramData}
                                      loading={refreshFetchStatus === fetchStatus.pending}
                                      disabled={true}
                                    >
                                      Refresh
                                    </Button>
                                  </Tooltip>
                                  <Badge tone="attention" size="small">
                                    Plus
                                  </Badge>
                                </InlineStack>
                            }

                          </ButtonGroup>
                        </div>
                      </Card>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {dataFetchStatus === fetchStatus.pending && <DesktopWidget />}
                  {dataFetchStatus === fetchStatus.resolved && (
                    <>
                      <Grid columns={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }}>
                        <Grid.Cell
                          columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}
                        >
                          <Card>
                            <div className={classes.desktopWidgetBoxWrapperCss}>
                              <div className={classes.headingWrapperCss}>
                                <Text variant="headingSm" as="h6">
                                  Desktop Preview
                                </Text>
                                <div className={classes.buttonGroupCss}>
                                  <ButtonGroup>
                                    <Button
                                      icon={SettingsIcon}
                                      pressed={
                                        currentActivePreview === "mobile"
                                      }
                                      onClick={handleMobilePreview}
                                    >
                                      Settings
                                    </Button>
                                    <Button
                                      icon={MobileIcon}
                                      pressed={
                                        currentActivePreview === "mobile"
                                      }
                                      onClick={handleMobilePreview}
                                    >
                                      Mobile
                                    </Button>
                                    <Button
                                      icon={DesktopIcon}
                                      pressed={
                                        currentActivePreview === "desktop"
                                      }
                                      onClick={handleDesktopPreview}
                                    >
                                      Desktop
                                    </Button>
                                    {
                                      plusCustomer ? <Button
                                        icon={RefreshIcon}
                                        onClick={pullFreshInstagramData}
                                        loading={
                                          refreshFetchStatus ===
                                          fetchStatus.pending
                                        }
                                        disabled={!plusCustomer}
                                      >
                                        Refresh Feed
                                      </Button> :
                                        <InlineStack gap="200">
                                          <Button
                                            icon={RefreshIcon}
                                            onClick={pullFreshInstagramData}
                                            loading={
                                              refreshFetchStatus ===
                                              fetchStatus.pending
                                            }
                                            disabled={true}
                                          >
                                            Refresh Feed
                                          </Button>
                                          <Badge tone="attention" size="small">Plus</Badge>
                                        </InlineStack>
                                    }

                                  </ButtonGroup>
                                </div>
                              </div>
                              <div className={classes.suHeadingWrapperCss}>
                                {instagramUserName && (
                                  <div className={classes.subHeadingCss}>
                                    <Text as="p" tone="subdued">
                                      Showing feed from @{instagramUserName}{" "}
                                      account
                                    </Text>
                                  </div>
                                )}
                              </div>
                              <div className={classes.dividerGapCss}>
                                <Divider borderColor="border" />
                              </div>

                              {dashboardData &&
                                Object.keys(dashboardData).length > 0 && (
                                  <WidgetRenderer
                                    res={dashboardData}
                                    currentActivePreview={currentActivePreview}
                                    formValues={formValues}
                                  />
                                )}
                            </div>
                          </Card>
                        </Grid.Cell>

                      </Grid>

                    </>
                  )}
                </>
              )}
            </Layout.Section>
            {!plusCustomer && <Layout.Section>
              <div className={classes.getInTouchBarWrapperCss}>
                <GetInTouchBar />
              </div>
            </Layout.Section>
            }
          </Layout>

          {decideWhetherToShowProductModal && (
            <SelectProductModal
              open={decideWhetherToShowProductModal}
              onClose={closeTagProductModalHandler}
              mediaId={selectedMediaId}
            />
          )}
          {showAccountListModal && (
            <AccountLists
              open={showAccountListModal}
              onClose={handleCloseAccountListModal}
            />
          )}

          {showAppConfigureModal && (
            <AppEnableConfig
              open={showAppConfigureModal}
              onClose={closeAppConfigureModal}
              widgetEnabled={widgetEnabled}
            />
          )}
        </div>
      )}
    </Page>
  );
};

export default Dashboard;
