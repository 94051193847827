import React, { useState, useEffect } from 'react';
import {
  Modal,
  TextField,
  ResourceList,
  Text,
  Button,
  ResourceItem,
} from '@shopify/polaris';
import NoImagePreview from '../../../../assets/noImagePreview.jpg';
import classes from './styles.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { resetLinkMedia, linkMedia } from '../../slices/InstaGenieSlices/mediaSlice';
import { fetchStatus } from '../../utils/constant';
import { useAppBridge, useToast } from '@shopify/app-bridge-react';
import { getShopifySessionToken } from '../../../../utils/authUtils';
import { fetchDashboardData } from '../../slices/InstaGenieSlices/dashboardSlice';
import { INSTAGENIE_BASE_URL } from "../../../../settings";

const SelectProductModal = ({ open, onClose, mediaId }) => {
  const dispatch = useDispatch();
  const app = useAppBridge();
  const { show } = useToast();
  const mediaLinkUpdateStatus = useSelector((state) => state.media.linkMedia.fetchStatus);
  const [searchValue, setSearchValue] = useState('');
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [confirmationVisible, setConfirmationVisible] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const bearerToken = await getShopifySessionToken(app);
        const response = await fetch(`${INSTAGENIE_BASE_URL}/product-list`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${bearerToken}`

          },
          body: JSON.stringify({ search: { value: searchValue }, start: 0, length: 4 }),
        });

        if (!response.ok) {
          throw new Error('Error fetching products');
        }

        const data = await response.json();
        setProducts(data.data);
      } catch (error) {
        console.error('Error fetching products:', error.message);
      }
    };

    if (open || searchValue) {
      fetchProducts();
    }
  }, [open, searchValue]);

  const handleSearchChange = (value) => {
    setSearchValue(value);
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setConfirmationVisible(true);
  };

  const handleConfirmation = (confirmed) => {
    if (confirmed && selectedProduct) {
      const data = {
        mediaId,
        productId: selectedProduct.id
      }
      dispatch(linkMedia({ payload: data, app })).then(() => {
        show('Tag updated!')
        dispatch(fetchDashboardData({ app }));
      }).catch(() => {
        show('Something went wrong')
      });
    } else {
      setConfirmationVisible(false);
      setSelectedProduct(null);
    }
  };

  useEffect(() => {
    if (mediaLinkUpdateStatus === fetchStatus.resolved) {
      onClose(true);
      dispatch(resetLinkMedia());
    }
  }, [mediaLinkUpdateStatus]);


  return (
    <Modal
      open={open}
      onClose={() => {
        setConfirmationVisible(false);
        onClose();
      }}
      title="Select Product"
    >
      <Modal.Section>
        <TextField
          label="Search Products"
          value={searchValue}
          onChange={handleSearchChange}
        />
      </Modal.Section>

      <Modal.Section>
        <ResourceList
          items={products}
          renderItem={(product) => {
            return (
              <div style={{
                backgroundColor: selectedProduct && selectedProduct.id === product.id ? '#CDD1D1' : 'transparent',
              }}>
                <ResourceItem
                  id={product.id}
                  media={<img src={product.images[0] || NoImagePreview} alt={product.title} width="50px" height="50px" />}
                  onClick={() => handleProductClick(product)}

                >
                  <h3>
                    <Text variation="strong">{product.title}</Text>
                  </h3>
                  <div>{product.price}</div>
                </ResourceItem>
              </div>
            )
          }}
        />
      </Modal.Section>

      {confirmationVisible && (
        <Modal.Section>
          <div style={{ padding: '12px' }}>
            <Text fontWeight='semibold'>
              Are you sure you want to tag the product "{selectedProduct ? selectedProduct.title : ''}"?
            </Text>
            <div className={classes.buttonWrapperCss}>
              <Button
                loading={mediaLinkUpdateStatus === fetchStatus.pending}
                onClick={() => handleConfirmation(true)} variant='primary' >Tag Product</Button>
              <Button
                disabled={mediaLinkUpdateStatus === fetchStatus.pending}
                onClick={() => handleConfirmation(false)}>Cancel</Button>
            </div>
          </div>
        </Modal.Section>
      )}
    </Modal>

  );
};

export default SelectProductModal;
