import { FormLayout, Select, TextField } from "@shopify/polaris";
import React from "react";
import ColorPickerInput from "../../../ColorPickerInput/index";
import { trueFalseOptions } from "../../constant";

const DisplayTextForm = ({ value, error, onChange, onError }) => {
    const handleInputChange = (field) => (newValue) => {
        onChange(field, newValue);
        // if (newValue.trim() === "") {
        //     onError(field, "This field cannot be empty");
        // } else {
        //     onError(field, null);
        // }
    };

    return (
        <FormLayout>
            <Select
                label="Enable widget"
                options={trueFalseOptions}
                onChange={handleInputChange("enableWidget")}
                value={value.enableWidget === "true" || value.enableWidget === true ? "true" : "false"}
                error={error.enableWidget}
            />
            <TextField
                label="Suffix text"
                onChange={handleInputChange("suffixText")}
                value={value.suffixText}
                error={error.suffixText}
                autoComplete="off"
                fullWidth={true}
            />
            <TextField
                label="Prefix text"
                autoComplete="off"
                fullWidth={true}
                onChange={handleInputChange("prefixText")}
                value={value.prefixText}
                error={error.prefixText}

            />
            <FormLayout.Group condensed>
                <TextField
                    label="Font size"
                    autoComplete="off"
                    fullWidth={true}
                    onChange={handleInputChange("fontSize")}
                    value={value.fontSize}
                    error={error.fontSize}
                />

                <Select
                    label="Bold"
                    options={trueFalseOptions}
                    onChange={handleInputChange("bold")}
                    value={value.bold === "true" || value.bold === true ? "true" : "false"}
                    error={error.bold}
                />
                <ColorPickerInput
                    label="Font color"
                    key={value.color}
                    value={value.color}
                    name="color"
                    onChange={handleInputChange}
                />
            </FormLayout.Group>

        </FormLayout>
    );
};

export default DisplayTextForm;
