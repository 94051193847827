import {useEffect} from "react";
import {Outlet} from "react-router-dom";
import ChatSupport from "../../../components/ChatSupport";

const Base = () => {
    useEffect(() => {

    }, [])

    return (
        <>
            <ChatSupport/>
            <Outlet/>
        </>
    )
}

export default Base