import { useAppBridge } from "@shopify/app-bridge-react";
import { Modal, TextContainer } from '@shopify/polaris';
import { getShopifySessionToken } from "../../../../utils/authUtils";
import { Redirect } from '@shopify/app-bridge/actions';
import { useState } from "react";

export function FreePlanLimit(props) {
    const [navigationInProgress, setNavigationInProgress] = useState(false);
    const app = useAppBridge();
    const redirect = Redirect.create(app);
    const { open, onClose } = props;

    const upgradePlanHandler = async () => {
        const selectedPlanName = 'PRIME'
        setNavigationInProgress(true)
        const token = await getShopifySessionToken(app);
        redirect.dispatch(Redirect.Action.REMOTE, {
            url:
                `https://zegsuapps.com/shopify/sold-counter/change-plan/${selectedPlanName}?authToken=${token}`
        })
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            title="Free plan limit reached!"
            primaryAction={{
                content: 'Upgrade now',
                onAction: upgradePlanHandler,
                loading: navigationInProgress
            }}
        >
            <Modal.Section>
                <TextContainer>
                    <p>
                        Free plan is limited to 3 products. Please consider upgrading.
                    </p>
                </TextContainer>
            </Modal.Section>
        </Modal>
    );
}
