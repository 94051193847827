import {
    Box,
    Button,
    Card,
    Icon,
    InlineStack,
    Text,
    Tooltip,
} from "@shopify/polaris";
import React, { useState } from "react";
import {
    InfoIcon,
    ShieldCheckMarkIcon,
    ToggleOffIcon,
    ToggleOnIcon,
} from "@shopify/polaris-icons";
import classes from "./styles.module.css";
import { generalSettings } from "../constant";
import ConfirmationDialog from "../ConfirmationDialog";
import { useDispatch } from "react-redux";
import { updateSpecificSettingDetails } from "../../../slices/SoldCounterSlices/dashboardSlice";
import { get } from "lodash";

import { useAppBridge, useToast } from "@shopify/app-bridge-react";
import { fetchStatus } from "../../../../../utils/constant";

const GeneralSettings = (props) => {
    const { show } = useToast();
    const { dashboardData, refreshDashboardData, generalSettingUploadStatus } =
        props;
    const dispatch = useDispatch();
    const app = useAppBridge();
    const aggregateVariantsForAllProducts = get(
        dashboardData,
        "shop.setting.aggregateVariantsForAllProducts",
        false
    );
    const productCount = get(
        dashboardData,
        "products.count",
        0
    );

    const widgetEnableCount = get(
        dashboardData,
        "products.widgetEnabledCount",
        0
    );

    const [activateModalFor, setActivateModalFor] = useState("");
    const [endPointToHit, setEndPointToHit] = useState("");

    const handleToggle = (key, endpoint) => {
        setActivateModalFor(key);
        setEndPointToHit(endpoint);
    };

    const updateSetting = () => {
        const payload = {
            all: String(endPointToHit).includes("enable") ? true : false
        }
        dispatch(
            updateSpecificSettingDetails({ app, endpoint: endPointToHit, payload })
        ).then(() => {
            setActivateModalFor("");
            setEndPointToHit("");
            refreshDashboardData();
            show('Settings updated')
        }).catch((e) => {
            show('Failed to update')
        })
    };

    const handleSubmit = () => {
        updateSetting();
    };

    const handleClose = () => {
        setActivateModalFor("");
    };

    const shouldBeDisabledForThisItem = (key) => {
        if (key === "enableVariantAggr" || key === "disableVariantAggr" || key === 'enableApp') {
            if (key === 'enableApp') {
                if (productCount > 0 && widgetEnableCount > 0) {
                    return Number(productCount) === Number(widgetEnableCount)
                } else {
                    return false
                }
            }
            if (key === "enableVariantAggr" && aggregateVariantsForAllProducts) {
                return true;
            } else if (
                key === "disableVariantAggr" &&
                !aggregateVariantsForAllProducts
            ) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    return (
        <Card>
            <div className={classes.titleCardWrapperCss}>
                <InlineStack
                    as="div"
                    align="space-between"
                    direction="row"
                    blockAlign="center"
                >
                    <InlineStack align="center" gap={200}>
                        <Icon source={ShieldCheckMarkIcon} />
                        <Text variant="headingMd" as="h6">
                            General Settings
                        </Text>
                    </InlineStack>
                </InlineStack>
            </div>
            <Box>
                {generalSettings.map(
                    ({ name, tooltipContent, key, component, btnText, endpoint }) => {
                        return (
                            <SettingItem
                                key={key}
                                itemName={key}
                                name={name}
                                tooltipContent={tooltipContent}
                                handleToggle={handleToggle}
                                component={component}
                                btnText={btnText}
                                endpoint={endpoint}
                                disabled={shouldBeDisabledForThisItem(key)}
                                generalSettingUploadStatus={generalSettingUploadStatus}
                                currentPlan={props.currentPlan}
                            />
                        );
                    }
                )}
            </Box>
            {activateModalFor && (
                <ConfirmationDialog
                    itemName={activateModalFor}
                    open={activateModalFor ? true : false}
                    handleSubmit={handleSubmit}
                    activateModalFor={activateModalFor}
                    onClose={handleClose}
                    isLoading={generalSettingUploadStatus === fetchStatus.pending}
                />
            )}
        </Card>
    );
};

const SettingItem = (props) => {
    const {
        name,
        tooltipContent,
        active,
        handleToggle,
        itemName,
        component,
        btnText,
        endpoint,
        disabled,
        currentPlan
    } = props;

    return (
        <Box className={classes.itemWrapperCss}>
            <InlineStack
                as="div"
                blockAlign="center"
                direction="row"
                align="space-between"
            >
                <InlineStack gap={200} align="center">
                    <Text as="p" fontWeight="medium">
                        {name}
                    </Text>
                    <Tooltip content={tooltipContent} dismissOnMouseOut>
                        <Icon source={InfoIcon} />
                    </Tooltip>
                </InlineStack>
                {component === "switch" && (
                    <div
                        onClick={() => handleToggle(itemName, endpoint)}
                        className={classes.toggleButtonWrapperCss}
                    >
                        {active ? (
                            <Icon source={ToggleOnIcon} tone="info" />
                        ) : (
                            <Icon source={ToggleOffIcon} />
                        )}
                    </div>
                )}
                {component === "button" && (
                    <Button
                        disabled={currentPlan === 'BASIC' ? itemName === 'enableApp' : disabled}
                        onClick={() => handleToggle(itemName, endpoint)}
                    >
                        {btnText}
                    </Button>
                )}
            </InlineStack>
        </Box>
    );
};

export default GeneralSettings;
