// Assuming you have Redux Toolkit set up

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { fetchStatus } from '../../utils/constant';
import { getShopifySessionToken } from '../../../../utils/authUtils';
import { INSTAGENIE_BASE_URL } from "../../../../settings";

// Define the initial state
const initialState = {
  fetchStatus: fetchStatus.idle,
  error: null,
};

// Create an async thunk to handle the PUT request
export const contactUsDetails = createAsyncThunk(
  'widget/updateDetails',
  async ({ payload, app }) => {
    try {
      const bearerToken = await getShopifySessionToken(app);
      const response = await axios.post(
        `${INSTAGENIE_BASE_URL}/contact`,
        payload,
        {
          ...(bearerToken && { headers: { Authorization: `Bearer ${bearerToken}` } }),
        }
      );
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  }
);

// Create a slice
const contactFormDetails = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    resetContactFormDetails: (state) => {
      state.fetchStatus = fetchStatus.idle;
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(contactUsDetails.pending, (state) => {
        state.fetchStatus = fetchStatus.pending;
      })
      .addCase(contactUsDetails.fulfilled, (state) => {
        state.fetchStatus = fetchStatus.resolved;

      })
      .addCase(contactUsDetails.rejected, (state, action) => {
        state.fetchStatus = fetchStatus.rejected
        state.error = action.error.message;
      });
  },
});

export default contactFormDetails.reducer;
export const { resetContactFormDetails } = contactFormDetails.actions;