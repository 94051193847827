import { useAppBridge } from '@shopify/app-bridge-react';
import { Modal, TextContainer } from '@shopify/polaris';
import { getShopifySessionToken } from '../../../utils/authUtils';
import { Redirect } from '@shopify/app-bridge/actions';
import { useState } from 'react';
import { planMapping } from '../../../utils/constant';
export function DowngradeConfirmation(props) {
  const [navigationInProgress, setNavigationInProgress] = useState(false);
  const app = useAppBridge();
  const redirect = Redirect.create(app);

  const { open, onClose, appName } = props;


  const downgradePlanHandler = async () => {
    setNavigationInProgress(true)
    const token = await getShopifySessionToken(app);
    const selectedPlanName = planMapping[appName]['FREE']
    const url = `https://zegsuapps.com/shopify/${appName}/change-plan/${selectedPlanName}?authToken=${token}`
    redirect.dispatch(Redirect.Action.REMOTE, { url })
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Downgrade to Free plan"
      primaryAction={{
        content: 'Confirm',
        onAction: downgradePlanHandler,
        loading: navigationInProgress,
      }}
      secondaryActions={[
        {
          content: 'Discard',
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>
            Are you sure you want to downgrade? You will loose access to all the Plus features.
          </p>
        </TextContainer>
      </Modal.Section>
    </Modal>

  );
}
