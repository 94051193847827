export const DEPLOYMENT_ENV = process.env.REACT_APP_DEPLOYMENT_ENV;
export const BUILD_HASH = process.env.REACT_APP_BUILD_HASH;
export const BUILD_BRANCH = process.env.REACT_APP_BUILD_BRANCH;

export const INSTAGENIE_APP_SHOPIFY_API_KEY = process.env.REACT_APP_INSTAGENIE_APP_SHOPIFY_API_KEY;
export const INSTAGENIE_BASE_URL = process.env.REACT_APP_INSTAGENIE_BASE_URL;

export const SOLD_COUNTER_APP_SHOPIFY_API_KEY = process.env.REACT_APP_SOLD_COUNTER_APP_SHOPIFY_API_KEY
export const SOLD_COUNTER_APP_BASE_URL = process.env.REACT_APP_SOLD_COUNTER_APP_BASE_URL;

