import {
    FormLayout,
    Select,
    Card,
    Layout,
    InlineStack,
    Icon,
    Text,
    ButtonGroup,
    Button,
    TextField,
} from "@shopify/polaris";
import { SettingsIcon } from "@shopify/polaris-icons";
import React, { useEffect, useState } from "react";
import {
    WIDGET_POSITION,
    checkValue,
    showSoldCountFromOptions,
    toggleOptions,
    widgetPositionOptions,
} from "../constant";
import classes from "./styles.module.css";
import { updateSettingDetails } from "../../../slices/SoldCounterSlices/dashboardSlice";
import { useDispatch } from "react-redux";
import { get } from "lodash";
import { fetchStatus } from "../../../../../utils/constant";
import { useAppBridge, useToast } from "@shopify/app-bridge-react";

const WidgetDetails = (props) => {
    const dispatch = useDispatch();
    const app = useAppBridge();
    const { show } = useToast();
    const { dashboardFetchStatus, dashboardData, refreshDashboardData } = props;
    const [initialValues, setInitialValues] = useState({});
    const [values, setValues] = useState({});

    useEffect(() => {
        if (dashboardFetchStatus === fetchStatus.resolved) {
            const aggregateVariantsForAllProducts = get(
                dashboardData,
                "shop.setting.aggregateVariantsForAllProducts",
                false
            );
            const animateSold = get(dashboardData, "shop.setting.animateSold", false);
            const autoFillAvailable = get(
                dashboardData,
                "shop.setting.autoFillAvailable",
                false
            );
            const autoFillAvailableValue = get(
                dashboardData,
                "shop.setting.autoFillAvailableValue",
                0
            );
            const collectionWidgetEnabled = get(
                dashboardData,
                "shop.setting.collectionWidgetEnabled",
                false
            );
            const conditionalAvailable = get(
                dashboardData,
                "shop.setting.conditionalAvailable",
                false
            );
            const conditionalAvailableRange = get(
                dashboardData,
                "shop.setting.conditionalAvailableRange",
                [0, 100000]
            );
            const lastNDaySold = get(dashboardData, "shop.setting.lastNDaySold", -1);
            const randomSoldEnabled = get(
                dashboardData,
                "shop.setting.randomSoldEnabled",
                false
            );
            const position = get(
                dashboardData,
                "shop.widget.position",
                WIDGET_POSITION.ABOVE_BUY_BUTTON
            );
            const conditionalAvailableHigh = conditionalAvailableRange[1];
            const conditionalAvailableLow = conditionalAvailableRange[0];

            setInitialValues({
                aggregateVariantsForAllProducts,
                animateSold,
                autoFillAvailable,
                autoFillAvailableValue,
                collectionWidgetEnabled,
                conditionalAvailable,
                conditionalAvailableRange,
                lastNDaySold,
                randomSoldEnabled,
                position,
                conditionalAvailableLow,
                conditionalAvailableHigh,
            });

            setValues({
                aggregateVariantsForAllProducts,
                animateSold,
                autoFillAvailable,
                autoFillAvailableValue,
                collectionWidgetEnabled,
                conditionalAvailable,
                conditionalAvailableRange,
                lastNDaySold,
                randomSoldEnabled,
                position,
                conditionalAvailableLow,
                conditionalAvailableHigh,
            });
        }
    }, [dashboardData]);

    const handleSelectChange = (field, value) => {
        setValues((prevValues) => ({
            ...prevValues,
            [field]: checkValue(value),
        }));
    };

    const handleUpdate = () => {
        dispatch(
            updateSettingDetails({ payload: values, app })
        ).then(() => {
            show('Settings updated');
            refreshDashboardData();
        }).catch((e) => {
            show('Update failed')
        })
    };

    const handleCancel = () => {
        setValues({
            ...initialValues,
        });
    };

    const handleInputChange = (field, value) => {
        setValues((prevValues) => ({
            ...prevValues,
            [field]: value,
        }));
    };


    const hasUnsavedChanges = () => {
        return JSON.stringify(values) !== JSON.stringify(initialValues);
    };

    console.log(values, "--->widget details value")
    return (
        <>
            <Layout.Section>
                <Card>
                    <InlineStack
                        as="div"
                        align="space-between"
                        direction="row"
                        blockAlign="center"
                    >
                        <InlineStack align="center" gap={200}>
                            <Icon source={SettingsIcon} />
                            <Text variant="headingMd" as="h6">
                                General Settings
                            </Text>
                        </InlineStack>
                    </InlineStack>
                </Card>
            </Layout.Section>
            <Layout.Section>
                <Card>
                    <FormLayout>
                        <FormLayout.Group>
                            <Select
                                label="Widget position"
                                options={widgetPositionOptions}
                                onChange={(val) => handleSelectChange("position", val)}
                                value={values["position"]}
                                name="position"
                            />
                        </FormLayout.Group>
                        <FormLayout.Group>
                            <Select
                                label="Intelligently randomize sold count"
                                options={toggleOptions}
                                value={checkValue(values.randomSoldEnabled)}
                                name="randomSoldEnabled"
                                onChange={(val) => handleSelectChange("randomSoldEnabled", val)}
                            />
                        </FormLayout.Group>
                        <FormLayout.Group>
                            <Select
                                label="Autofill stock quantity when it reaches 0"
                                options={toggleOptions}
                                name="autoFillAvailable"
                                onChange={(val) => handleSelectChange("autoFillAvailable", val)}
                                value={checkValue(values["autoFillAvailable"])}
                            />
                        </FormLayout.Group>
                        {checkValue(values["autoFillAvailable"]) && (
                            <TextField
                                label="Autofill with quantity"
                                onChange={(val) => handleInputChange('autoFillAvailableValue', parseInt(val, 10))
                                }
                                value={String(values.autoFillAvailableValue)}
                                autoComplete="off"
                                fullWidth={true}
                                type="number"
                                helpText="Only number is allowed"
                                error={isNaN(Number(values.autoFillAvailableValue))}
                            />
                        )}
                        <FormLayout.Group>
                            <Select
                                label="Animate sold count to  0"
                                options={toggleOptions}
                                name="animateSold"
                                onChange={(val) => handleSelectChange("animateSold", val)}
                                value={checkValue(values["animateSold"])}
                            />
                        </FormLayout.Group>
                        <FormLayout.Group>
                            <Select
                                label="Show on collection page"
                                options={toggleOptions}
                                name="collectionWidgetEnabled"
                                onChange={(val) =>
                                    handleSelectChange("collectionWidgetEnabled", val)
                                }
                                value={checkValue(values["collectionWidgetEnabled"])}
                            />
                            <Select
                                label="Conditionally show available stock"
                                options={toggleOptions}
                                name="conditionalAvailable"
                                onChange={(val) =>
                                    handleSelectChange("conditionalAvailable", val)
                                }
                                value={checkValue(values["conditionalAvailable"])}
                            />
                        </FormLayout.Group>
                        {checkValue(values["conditionalAvailable"]) && (
                            <FormLayout.Group>
                                <TextField
                                    label="Show When Stock In Between from"
                                    onChange={(val) => handleInputChange('conditionalAvailableLow', parseInt(val, 10))}
                                    value={String(values.conditionalAvailableLow)}
                                    helpText="Only number is allowed"
                                    error={isNaN(Number(values.conditionalAvailableLow))}
                                    type="number"
                                    autoComplete="off"
                                    fullWidth={true}
                                />

                                <TextField
                                    label="To"
                                    onChange={(val) => handleInputChange("conditionalAvailableHigh", parseInt(val, 10))}
                                    value={String(values.conditionalAvailableHigh)}
                                    autoComplete="off"
                                    fullWidth={true}
                                    helpText="Only number is allowed"
                                    error={isNaN(Number(values.conditionalAvailableHigh))}
                                    type="number"
                                />
                            </FormLayout.Group>
                        )}
                        <FormLayout.Group>
                            <Select
                                label="Show sold count From"
                                options={showSoldCountFromOptions}
                                onChange={(val) => handleSelectChange("lastNDaySold", parseInt(val, 10))}
                                value={String(values["lastNDaySold"])}
                                name="lastNDaySold"
                            />
                        </FormLayout.Group>
                    </FormLayout>
                    {hasUnsavedChanges() && (
                        <div className={classes.buttonWrapperCss}>
                            <ButtonGroup className={classes.buttonWrapperCss}>
                                <Button onClick={handleCancel}>Cancel</Button>
                                <Button variant="primary" onClick={handleUpdate}>
                                    Save
                                </Button>
                            </ButtonGroup>
                        </div>
                    )}
                </Card>
            </Layout.Section>
        </>
    );
};

export default WidgetDetails;
