import { FormLayout, Select, TextField } from "@shopify/polaris";
import React from "react";
import ColorPickerInput from "../../../ColorPickerInput";
import { trueFalseOptions } from "../../constant";
const ValueTextForm = ({ value, error, onChange, onError }) => {

    const handleInputChange = (field) => (newValue) => {
        onChange(field, newValue);
        // if (newValue.trim() === "") {
        //     onError(field, "This field cannot be empty");
        // } else {
        //     onError(field, null);
        // }
    };

    return (
        <FormLayout>
            <FormLayout.Group condensed>
                <Select
                    label="Bold"
                    options={trueFalseOptions}
                    onChange={handleInputChange("bold")}
                    value={value.bold === "true" || value.bold === true ? "true" : "false"}
                    error={error.bold}
                />
                <ColorPickerInput
                    label="Color"
                    value={value.color}
                    key={value.color}
                    onChange={handleInputChange}
                    name="color"
                />
                <TextField
                    label="Font size"
                    autoComplete="off"
                    fullWidth={true}
                    onChange={handleInputChange("fontSize")}
                    value={value.fontSize}
                    error={error.fontSize}
                />
            </FormLayout.Group>
        </FormLayout>
    );
};

export default ValueTextForm;
