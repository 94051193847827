import { FormLayout, Select, TextField } from "@shopify/polaris";
import React from "react";
import { trueFalseOptions } from "../constant";
import ColorPickerInput from "../../ColorPickerInput";
const SeparatorTextForm = ({ value, error, onChange, onError }) => {
    console.log(value, "--->seperator value")
    const handleInputChange = (field) => (newValue) => {
        onChange(field, newValue);
        // if (newValue.trim() === "") {
        //     onError(field, "This field cannot be empty");
        // }
        // else {
        //     onError(field, null);

        // }
    };

    return (
        <FormLayout>
            <Select
                label="Enable widget"
                options={trueFalseOptions}
                onChange={handleInputChange("enableWidget")}
                value={value.enableWidget === "true" || value.enableWidget === true ? "true" : "false"}
                error={error.enableWidget}
            />
            <TextField
                label="Text"
                autoComplete="off"
                fullWidth={true}
                onChange={handleInputChange("suffixText")}
                value={value.suffixText}
                error={error.suffixText}
            />
            <FormLayout.Group condensed>
                <TextField
                    label="Font size"
                    autoComplete="off"
                    fullWidth={true}
                    onChange={handleInputChange("fontSize")}
                    value={value.fontSize}
                    error={error.fontSize}
                />
                <Select
                    label="Bold"
                    options={trueFalseOptions}
                    onChange={handleInputChange("bold")}
                    value={value.bold === "true" || value.bold === true ? "true" : "false"}
                    error={error.bold}
                />
                <ColorPickerInput
                    label="Font color"
                    value={value.color}
                    key={value.color}
                    name="color"
                    onChange={handleInputChange}
                />
            </FormLayout.Group>
        </FormLayout>
    );
};

export default SeparatorTextForm;
