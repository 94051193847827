// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { Provider } from 'react-redux';
// import store from './store';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <Provider store={store}>
//     <App />
//   </Provider>,
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React from "react";
import {createRoot} from "react-dom/client";
import App from "./App";
import {AppProvider} from "@shopify/polaris";
import en from "@shopify/polaris/locales/en.json";
import "@shopify/polaris/build/esm/styles.css";
import {Provider} from 'react-redux';
import {Provider as AppBridgeProvider} from '@shopify/app-bridge-react';
import store from './store';
import {getShopifyAPIKey, Navigation} from "./utils/appConfig";

const container = document.getElementById("root");
const root = createRoot(container);

const config = () => {
    const queryParams = new URLSearchParams(window.location.search);
    return {
        apiKey: getShopifyAPIKey(window.location.href),
        host: queryParams.get('host') || "99be80-3",
        forceRedirect: queryParams.get('require_embed') || false,
    }
};

root.render(
    <Provider store={store()}>
        <AppProvider i18n={en}>
            <AppBridgeProvider config={config()}>
                <Navigation url={window.location.href}/>
                <App/>
            </AppBridgeProvider>
        </AppProvider>
    </Provider>
);
