// rootReducer.js
import { combineReducers } from '@reduxjs/toolkit';
import soldDashboardSlice from '../slices/SoldCounterSlices/dashboardSlice';
import contactFormSlice from '../slices/SoldCounterSlices/contactFormSlice';



// Import your individual reducers here
// import exampleReducer from './exampleReducer';

const soldCounterReducer = combineReducers({
    dashboard: soldDashboardSlice,
    contactForm: contactFormSlice,
});

export default soldCounterReducer;
