import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getShopifySessionToken } from "../../../../utils/authUtils";
import { INSTAGENIE_BASE_URL } from "../../../../settings";



// Define the initial state
const initialState = {
  pages: [],
  fetchStatus: "idle",
  submitFetchStatus: 'idle',
  error: null,
};

// Create an async thunk to handle the GET request for fetching users
export const fetchUsers = createAsyncThunk(
  "modal/fetchUsers",
  async ({ app }) => {
    try {
      const bearerToken = await getShopifySessionToken(app);
      const response = await axios.get(`${INSTAGENIE_BASE_URL}/instagram-accounts?authToken=${bearerToken}`);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  }
);

// Create an async thunk to handle the POST request for submitting user selection
export const submitUserSelection = createAsyncThunk(
  "modal/submitUserSelection",
  async ({ payload, app }) => {
    try {
      const bearerToken = await getShopifySessionToken(app);
      const response = await axios.post(`${INSTAGENIE_BASE_URL}/instagram-id`, payload, {
        ...(bearerToken && {
          headers: { Authorization: `Bearer ${bearerToken}` },
        }),
      });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  }
);

// Create a slice
const accountListsSlice = createSlice({
  name: "accountLists",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.fetchStatus = "pending";
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.fetchStatus = "resolved";
        state.pages = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.fetchStatus = "rejected";
        state.error = action.error.message;
      })
      .addCase(submitUserSelection.pending, (state) => {
        state.submitFetchStatus = "pending";
      })
      .addCase(submitUserSelection.fulfilled, (state) => {
        state.submitFetchStatus = "resolved";
      })
      .addCase(submitUserSelection.rejected, (state, action) => {
        state.submitFetchStatus = "rejected";
        state.error = action.error.message;
      });
  },
});

export const { selectUser } = accountListsSlice.actions;
export default accountListsSlice.reducer;
