import React, { useEffect } from "react";
import { Badge, Button, ButtonGroup, Card, InlineStack, Layout, Page, Text, } from "@shopify/polaris";
import Logo from "../../../assets/logo.png";
import classes from "./styles.module.css";
import { LogoFacebookIcon, LogoInstagramIcon } from "@shopify/polaris-icons";
import { getShopifySessionToken } from "../../../../../utils/authUtils";
import { useAppBridge, useNavigate, useToast } from "@shopify/app-bridge-react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardData } from "../../../slices/InstaGenieSlices/dashboardSlice";
import { get } from "lodash";
import { Redirect } from '@shopify/app-bridge/actions';
import { INSTAGENIE_BASE_URL } from "../../../../../settings";

const ConnectYourAccount = () => {

  const app = useAppBridge();
  const redirect = Redirect.create(app);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { show } = useToast();
  const dashboardData = useSelector((state) => state.dashboard);
  const currentPlan = get(dashboardData, "data.shop.plan", "") || "";
  const instaNotLinked = get(dashboardData, 'data.shop.instagram.needReAuth', true);

  const plusCustomer = currentPlan === 'PRIME';

  const handleFacebookRequest = async () => {

    if (plusCustomer) {
      const token = await getShopifySessionToken(app);
      const url = `${INSTAGENIE_BASE_URL}/auth?authToken=${token}`;
      redirect.dispatch(Redirect.Action.REMOTE,
        { url });
    } else {
      show('Upgrade to PLUS plan!')
    }
  };

  const handleInstagramRequest = async () => {
    const token = await getShopifySessionToken(app);
    const url = `${INSTAGENIE_BASE_URL}/insta/auth?authToken=${token}`;
    redirect.dispatch(Redirect.Action.REMOTE,
      { url });
  };


  useEffect(() => {
    dispatch(fetchDashboardData({ app }))
  }, []);

  useEffect(() => {
    if (!instaNotLinked) {
      navigate('/shopify/instagenie/dashboard')
    }
  }, [instaNotLinked])

  return (
    <Page >
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <div className={classes.parentWrapperCss}>
              <img
                src={Logo}
                alt="Logo"
                style={{ height: "80px", width: "80px", marginRight: "10px" }}
              />
              <div className={classes.headingWrapperCss}>
                <Text variant="headingXl" as="h4">
                  Connect your Instagram account to start using Instafeed
                </Text>
              </div>
              <div className={classes.subHeadiingWrapperCss}>
                <Text as="p" tone="subdued">
                  Official Instagram Integration to Skyrocket Your Store's Sales.
                </Text>
              </div>
              <div className={classes.buttonWrapperCss}>
                <ButtonGroup>
                  <Button
                    icon={LogoInstagramIcon}

                    onClick={handleInstagramRequest}
                  >
                    Connect with Instagram
                  </Button>
                  <InlineStack gap="200" align="center">
                    <Button
                      variant="primary"
                      icon={LogoFacebookIcon} onClick={handleFacebookRequest}>
                      Connect with Facebook
                    </Button>
                    {!plusCustomer && <Badge tone="attention">Plus</Badge>}
                  </InlineStack>
                </ButtonGroup>
              </div>
            </div>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default ConnectYourAccount;
