
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, ButtonGroup, RadioButton } from '@shopify/polaris';
import classes from './styles.module.css'
import StockStatus from './StockStatus/index';
import { dataMapping } from './constant';
import { fetchDashboardData, updateCustomizeWidget } from '../../../slices/SoldCounterSlices/dashboardSlice';
import { useAppBridge, useToast } from '@shopify/app-bridge-react';

const WidgetTemplates = () => {
    const app = useAppBridge();
    const dispatch = useDispatch();
    const { show } = useToast();
    const [value, setValue] = useState('');

    const handleChange = (value, name) => {
        setValue(name)
    }

    const handleSave = () => {
        dispatch(updateCustomizeWidget({ app, payload: { widget: dataMapping[value] } }))
            .then((res) => {
                dispatch(fetchDashboardData({ app }));
                setValue('')
                show('Widget applied');
            })
            .catch((e) => {
                show('Widget applying failed');
            });
    }
    return (
        <React.Fragment>
            <div className={classes.templateWrapperCss}>
                <div className={classes.boxWrapperCss}>
                    <RadioButton
                        checked={value === 'orderSoon'}
                        name="template"
                        id="orderSoon"
                        onChange={handleChange}
                    />
                    <StockStatus stock={50} textVariant="orderSoon" />


                </div>
                <div className={classes.boxWrapperCss}>
                    <RadioButton
                        checked={value === 'highDemand'}
                        name="template"
                        id="highDemand"
                        onChange={handleChange}
                    />
                    <StockStatus stock={50} sold={2} textVariant="highDemand" />

                </div>
                <div className={classes.boxWrapperCss}>
                    <RadioButton
                        checked={value === 'remaining'}
                        id='remaining'
                        name="template"
                        onChange={handleChange}
                    />
                    <StockStatus stock={50}

                        textVariant="remaining" />

                </div>
                <div className={classes.boxWrapperCss}>

                    <RadioButton
                        checked={value === 'combined'}
                        name="template"
                        id='combined'
                        onChange={handleChange}
                    />
                    <StockStatus stock={50} sold={2} textVariant="combined" />

                </div>
                <div className={classes.boxWrapperCss}>
                    <RadioButton
                        checked={value === 'limited'}
                        name="template"
                        id='limited'
                        onChange={handleChange}
                    />
                    <StockStatus stock={50} sold={2} textVariant="limited" />

                </div>
                <div className={classes.boxWrapperCss}>
                    <RadioButton
                        id='left'
                        checked={value === 'left'}
                        name="template"
                        onChange={handleChange} />
                    <StockStatus stock={50} sold={2} textVariant="left" />

                </div>
            </div>
            {value && <div className={classes.buttonWrapperCss}>
                <ButtonGroup>
                    <Button onClick={() => setValue('')}>Cancel</Button>
                    <Button variant="primary" onClick={handleSave}>Save</Button>
                </ButtonGroup>
            </div>
            }
        </React.Fragment>

    )
}
export default WidgetTemplates;