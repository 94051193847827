import React, { useEffect, useRef, useState } from "react";
import {
  Text,
  Button,
  Select,
  Tooltip,
  Pagination,
  EmptyState,
  Card,
  Banner,
} from "@shopify/polaris";
import classes from "./styles.module.css";
import { get, isEqual } from "lodash";
import { HideIcon, ViewIcon } from "@shopify/polaris-icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardData } from "../../slices/InstaGenieSlices/dashboardSlice";
import {
  resetMediaControlState,
  setUpdatingMediaId,
  updateMediaStatus,
} from "../../slices/InstaGenieSlices/mediaControlSlice";
import { fetchStatus } from "../../utils/constant";
import PageLoadingIndicator from "../../../../components/Loading";
import MediaControlSkeleton from "../Skeletons/Media";
import { useAppBridge, useToast, useNavigate } from "@shopify/app-bridge-react";
import { NoAccess } from "../Modal/NoAccess";

const MediaControl = () => {
  const loadCountRef = useRef(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const app = useAppBridge();
  const { show } = useToast();
  const dashboardData = useSelector((state) => state.dashboard);
  const dashboardFetchStatus = useSelector(
    (state) => state.dashboard.fetchStatus
  );
  const currentPlan = get(dashboardData, "data.shop.plan", "") || "";

  const instaNotLinked = get(
    dashboardData,
    "data.shop.instagram.needReAuth",
    false
  );

  const imagesList = get(dashboardData, "data.shop.instagram.media", []) || [];
  const instagramData = get(dashboardData, "data.shop.instagram", {}) || {};
  const instagramUserName = get(instagramData, "username", "") || "";
  const integrationType = get(instagramData, "integrationType", "") || "";

  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedVisibilityOptions, setSelectedVisibilityOptions] =
    useState("both");
  const [mediaList, setMediaList] = useState([]);
  const [showNoAccessModal, setShowNoAccessModal] = useState(false);
  const plusCustomer = currentPlan === "PRIME";

  useEffect(() => {
    loadCountRef.current += 1;
    dispatch(fetchDashboardData({ app }));
  }, []);

  useEffect(() => {
    if (!plusCustomer) {
      setShowNoAccessModal(true)
    } else {
      setShowNoAccessModal(false)
    }
  }, [plusCustomer]);

  useEffect(() => {
    if (instagramData) {
      const instagramId = get(instagramData, "id", "");
      const userToken = get(instagramData, "userToken", "");
      const needReAuth = get(instagramData, "needReAuth", "");
      if (
        integrationType === "GRAPH" &&
        (instagramId === "" || instagramUserName === "") &&
        userToken &&
        needReAuth === false
      ) {
        navigate("/shopify/instagenie/dashboard");
      }
    }
  }, [instagramData]);

  useEffect(() => {
    if (instaNotLinked) {
      navigate("/shopify/instagenie/login");
    }
  }, [instaNotLinked]);

  useEffect(() => {
    if (!isEqual(imagesList, mediaList)) setMediaList(imagesList);
  }, [imagesList]);

  const handlePerPageChange = (value) => {
    setPerPage(value);
    setCurrentPage(1);
  };

  const visibilityChangeHandler = (value) => {
    setSelectedVisibilityOptions(value);
    let filteredMediaList = [];

    switch (value) {
      case "onlyVisible":
        filteredMediaList = imagesList.filter(({ visible }) => visible);
        break;
      case "onlyHidden":
        filteredMediaList = imagesList.filter(({ visible }) => !visible);
        break;
      case "both":
      default:
        filteredMediaList = imagesList;
        break;
    }

    setMediaList(filteredMediaList);
  };

  const handleToggle = (mediaId, visible) => {
    const state = visible ? "disable" : "enable";
    dispatch(resetMediaControlState());
    dispatch(setUpdatingMediaId(mediaId));
    dispatch(updateMediaStatus({ mediaId, state, app }))
      .then(() => {
        show("Updated successfully !");
        dispatch(fetchDashboardData({ app }));
      })
      .catch((e) => {
        show("Something went wrong!");
      });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const redirectToDashboard = () => {
    navigate("/shopify/instagenie/dashboard");
  }

  const perPageOptions = [5, 10, 15];

  const visibilityOptions = [
    { label: "Visible Only", value: "onlyVisible" },
    { label: "Hidden Only", value: "onlyHidden" },
    { label: "Both Visible and Hidden", value: "both" },
  ];

  const startIndex = (currentPage - 1) * perPage;
  const endIndex = startIndex + perPage;
  const paginatedProducts = mediaList.slice(startIndex, endIndex);
  const isFirstPage = currentPage === 1;
  const isLastPage = endIndex >= mediaList.length;

  return (
    <div className={classes.mediaControlWrapperCss}>
      {dashboardFetchStatus === fetchStatus.pending && (
        <React.Fragment>
          <PageLoadingIndicator />
          {loadCountRef.current === 1 && <MediaControlSkeleton />}
        </React.Fragment>
      )}
      {
        dashboardFetchStatus === fetchStatus.resolved && instaNotLinked &&
        <Banner
          title="Account Not Linked: Please Connect to Access Feature. Redirecting to Login..."
          tone="warning"
        >
        </Banner>
      }
      {dashboardFetchStatus === fetchStatus.resolved &&
        !instaNotLinked &&
        instagramUserName && (
          <>
            <div className={classes.flexWrapCss}>
              <Text variant="headingLg" as="h5">
                Media control
              </Text>
              <div className={classes.dropdownWrapperCss}>
                <Select
                  options={perPageOptions.map((option) => ({
                    label: `${option} per page`,
                    value: option,
                  }))}
                  value={perPage}
                  onChange={handlePerPageChange}
                />
                <Select
                  options={visibilityOptions.map(({ label, value }) => ({
                    label,
                    value,
                  }))}
                  value={selectedVisibilityOptions}
                  onChange={visibilityChangeHandler}
                />
              </div>
            </div>
            {paginatedProducts.length > 0 ? (
              <>
                <div className={classes.productListWrapperCss}>
                  {paginatedProducts.length > 0 &&
                    paginatedProducts.map(
                      ({
                        mediaType,
                        mediaThumbnailUrl,
                        mediaUrl,
                        visible,
                        id,
                      }) => {
                        return (
                          <CardComponent
                            imageSrc={
                              mediaType === "VIDEO"
                                ? mediaThumbnailUrl
                                : mediaUrl
                            }
                            visible={visible}
                            mediaId={id}
                            key={id}
                            handleToggle={handleToggle}
                            plusCustomer={plusCustomer}
                          />
                        );
                      }
                    )}
                </div>
                <div className={classes.paginationWrapperCss}>
                  <Pagination
                    hasPrevious={!isFirstPage}
                    hasNext={!isLastPage}
                    onPrevious={() => handlePageChange(currentPage - 1)}
                    onNext={() => handlePageChange(currentPage + 1)}
                  />
                </div>
              </>
            ) : (
              <div className={classes.emptyStateContainerCss}>
                <Card>
                  <EmptyState
                    heading="No media items found"
                    image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
                    action={{
                      content: "Refresh",
                      onAction: () => window.location.reload(),
                    }}
                  >
                    <Text>
                      There are no media items matching your criteria. Please
                      check your filters or try refreshing the page.
                    </Text>
                  </EmptyState>
                </Card>
              </div>
            )}
          </>
        )}
      {
        showNoAccessModal && dashboardFetchStatus === fetchStatus.resolved && <NoAccess
          open={showNoAccessModal}
          onClose={redirectToDashboard}
          message="Please upgrade to PLUS to use this feature"
          key={currentPlan}
        />
      }
    </div>
  );
};

export default MediaControl;

const CardComponent = ({
  imageSrc,
  visible,
  mediaId,
  handleToggle,
  plusCustomer,
}) => {
  const tooltipContent = visible ? "Hide this item" : "Show this item";

  return (
    <div className={`${classes.cardWrapperCss}`}>
      <div className={`${classes.imageContainer}`}>
        <img src={imageSrc} alt="Instagram" className={classes.image} />
        {!visible && <div className={classes.overlay}></div>}
        <div className={classes.toggleButtonWrapper}>
          {plusCustomer ? (
            <Tooltip content={tooltipContent}>
              {visible ? (
                <Button
                  onClick={() => handleToggle(mediaId, visible)}
                  primary={!visible}
                  icon={ViewIcon}
                >
                  Visible
                </Button>
              ) : (
                <Button
                  onClick={() => handleToggle(mediaId, visible)}
                  variant="primary"
                  icon={HideIcon}
                >
                  Hidden
                </Button>
              )}
            </Tooltip>
          ) : (
            <Button icon={ViewIcon} variant="primary" disabled>
              Toggle visibility
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
