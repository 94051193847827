export const extractVariantInfo = (variants) => {

    if (!variants || !Array.isArray(variants)) {
        return { totalVariants: 0, totalInStock: 0, totalUnitsSold: 0 };
    }


    let totalVariants = 0;
    let totalInStock = 0;
    let totalUnitsSold = 0;

    for (const variant of variants) {
        if (typeof variant.available !== 'number' || typeof variant.sold !== 'number') {
            return { totalVariants: 0, totalInStock: 0, totalUnitsSold: 0 };
        }
        totalVariants += 1;
        totalInStock += variant.available;
        totalUnitsSold += variant.sold;
    }

    return {
        totalVariants,
        totalInStock,
        totalUnitsSold
    };
}


export const formatDateTimeToUTC = (isoString) => {
    if (!isoString) return ''; // Handle empty string

    const pad = (num) => (num < 10 ? '0' : '') + num;

    const date = new Date(isoString);
    const month = pad(date.getUTCMonth() + 1);
    const day = pad(date.getUTCDate());
    const year = date.getUTCFullYear();
    let hours = date.getUTCHours();
    const minutes = pad(date.getUTCMinutes());
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    return `${month}/${day}/${year} ${hours}:${minutes} ${ampm}`;
};

export const revertDateTimeFromUTC = (formattedString) => {
    if (!formattedString) return ''; // Handle empty string

    const pad = (num) => (num < 10 ? '0' : '') + num;

    const [datePart, timePart] = formattedString.split(' ');
    const [month, day, year] = datePart.split('/');
    let [hours, minutes] = timePart.split(/[: ]/);
    const ampm = timePart.split(' ')[1];

    if (ampm === 'PM' && hours !== '12') {
        hours = parseInt(hours, 10) + 12;
    } else if (ampm === 'AM' && hours === '12') {
        hours = '00';
    }

    return `${year}-${month}-${day}T${pad(hours)}:${pad(minutes)}:00Z`;
};


export const plansOptions = [
    {
        id: "free",
        name: "Free",
        priceMonthly: 0,
        priceYearly: 0,
        features: [
            {
                name: "Display Sold Count & Stock Count on upto 3 product pages",
                subheading: "",
                available: true,
            },
            {
                name: "Basic customization",
                subheading:
                    "",
                available: true,
            },
            {
                name: "Pre-built Sold Count & Low Stock Counter templates",
                subheading:
                    "",
                available: true,
            }
        ],
        popular: false,
    },
    {
        id: "pro",
        name: "Plus",
        priceMonthly: 9.99,
        priceYearly: 109.99,
        features: [
            { name: "Everything in free", available: true },
            { name: "Display widgets on unlimited product pages", available: true },
            { name: "Display count on collection pages", available: true },
            { name: "Advanced customization", available: true },
        ],
        popular: true,
    },
];
