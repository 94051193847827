import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { fetchStatus } from '../../utils/constant';
import { getShopifySessionToken } from '../../../../utils/authUtils';
import { INSTAGENIE_BASE_URL } from "../../../../settings";

const initialState = {
  fetchStatus: fetchStatus.idle,
  error: null,
  instaUnlinkStatus: fetchStatus.idle
}

export const unlinkInstaAccount = createAsyncThunk(
  'content/unlinkInstaAccount',
  async ({ app }) => {
    try {
      const bearerToken = await getShopifySessionToken(app);
      const res = await axios.post(`${INSTAGENIE_BASE_URL}/insta/unlink`, null,
        {
          ...(bearerToken && { headers: { Authorization: `Bearer ${bearerToken}` } }),
        }
      );
      const data = await res.data
      return data
    } catch (error) {
      throw error;
    }
  }
)

export const socialMediaAccountSlice = createSlice({
  name: 'socialMediaAccounts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(unlinkInstaAccount.pending, (state) => {
      state.fetchStatus = fetchStatus.pending
      state.instaUnlinkStatus = fetchStatus.pending
    })
    builder.addCase(unlinkInstaAccount.fulfilled, (state, action) => {
      state.fetchStatus = fetchStatus.resolved
      state.instaUnlinkStatus = fetchStatus.resolved
    })
    builder.addCase(unlinkInstaAccount.rejected, (state, action) => {
      state.fetchStatus = fetchStatus.rejected
      state.instaUnlinkStatus = fetchStatus.rejected
      state.error = action.error.message
    })
  },
})

export default socialMediaAccountSlice.reducer