import React from "react";
import { Card, Text, ButtonGroup, Button, Link, InlineStack, Badge } from "@shopify/polaris";
import classes from "./styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import { unlinkInstaAccount } from "../../../slices/InstaGenieSlices/socialMediaAccountSlice";
import { useAppBridge, useNavigate, useToast } from "@shopify/app-bridge-react";
import { fetchStatus } from "../../../utils/constant";
import { getShopifySessionToken } from "../../../../../utils/authUtils";
import { Redirect } from '@shopify/app-bridge/actions';
import { INSTAGENIE_BASE_URL } from "../../../../../settings";
const AccountInfoHeader = (props) => {
  const { instagramUserName, integrationType, currentPlan } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { show } = useToast();
  const app = useAppBridge();
  const redirect = Redirect.create(app);

  const instaUnlinkStatus = useSelector(
    (state) => state.socialMediaAccounts.instaUnlinkStatus
  );
  const handleFaqRedirection = () => {
    navigate("/shopify/instagenie/contact");
  };

  const handleInstaUnlinkAccount = () => {
    dispatch(unlinkInstaAccount({ app })).then(() => {
      show("Account unlinked successfully");
      navigate("/shopify/instagenie/login");
    });
  };

  const handleFacebookRequest = async () => {
    const token = await getShopifySessionToken(app);
    const url = `${INSTAGENIE_BASE_URL}/auth?authToken=${token}`;
    redirect.dispatch(Redirect.Action.REMOTE,
      { url });

  };

  const handleInstagramRequest = async () => {
    const token = await getShopifySessionToken(app);
    const url = `${INSTAGENIE_BASE_URL}/insta/auth?authToken=${token}`;
    redirect.dispatch(Redirect.Action.REMOTE,
      { url });
  };

  const instagramLinked = instagramUserName && integrationType === "BASIC";
  const facebookLinked = instagramUserName && integrationType === "GRAPH";

  const plusCustomer = currentPlan === 'PRIME';

  return (
    <Card sectioned>
      <div className={classes.headerWrapperCss}>
        <div>
          <Text variant="headingSm" as="h6">
            Connected Instagram Account
          </Text>
          <div className={classes.mainTextCss}>
            {instagramLinked ? (
              <Text as="p" fontWeight="regular">
                @{instagramUserName}
              </Text>
            ) : (
              <Text as="p" fontWeight="regular" tone="subdued">
                No account linked
              </Text>
            )}
          </div>
          {instagramLinked ? (
            <ButtonGroup>
              <Button variant="plain" onClick={handleInstagramRequest}>
                Change Account
              </Button>
              <Button
                variant="plain"
                loading={instaUnlinkStatus === fetchStatus.pending}
                onClick={handleInstaUnlinkAccount}
              >
                Unlink Account
              </Button>
            </ButtonGroup>
          ) : (
            <ButtonGroup>
              <Button variant="plain" onClick={handleInstagramRequest}>
                Link Account
              </Button>
            </ButtonGroup>
          )}
        </div>
        <div>
          <Text variant="headingSm" as="h6">
            Connected Facebook Account
          </Text>
          <div className={classes.mainTextCss}>
            {facebookLinked ? (
              <Text as="p" fontWeight="regular">
                @{instagramUserName}
              </Text>
            ) : (
              <Text as="p" fontWeight="regular" tone="subdued">
                No accounts linked
              </Text>
            )}
          </div>
          {facebookLinked && plusCustomer ? (
            <ButtonGroup>
              <Button variant="plain" onClick={handleFacebookRequest}>
                Change Account
              </Button>
              <Button
                variant="plain"
                loading={instaUnlinkStatus === fetchStatus.pending}
                onClick={handleInstaUnlinkAccount}
              >
                Unlink Account
              </Button>
            </ButtonGroup>
          ) : (
            <ButtonGroup>
              {plusCustomer ? <Button variant="plain" onClick={handleFacebookRequest}>
                Link Account Now
              </Button> :
                <InlineStack gap="200">
                  <Button variant="plain" disabled={true}>
                    Link Account Now
                  </Button>
                  <Badge tone="attention">Plus</Badge>
                </InlineStack>

              }
            </ButtonGroup>
          )}
        </div>
        <div className={classes.secondHeaderWrapperCss}>
          <div>
            <Text variant="headingSm" as="h6">
              Integrate Instagram feed
            </Text>
            <div className={classes.mainTextCss}>
              <div className={classes.mainTextCss}>
                <Link url="https://youtu.be/KoQWUzS1efI" target="_blank">
                  View Help Video
                </Link>
              </div>
            </div>

            <Button variant="monochromePlain" onClick={handleFaqRedirection}>
              Looking for something else?
            </Button>

          </div>
        </div>

      </div>
    </Card>
  );
};

export default AccountInfoHeader;
