import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Badge,
  InlineStack,
  Text,
  Page,
  Layout,
  BlockStack,
  Tooltip,
  Icon,
} from "@shopify/polaris";
import classes from "./styles.module.css";
import { get } from "lodash";
import { useAppBridge } from "@shopify/app-bridge-react";
import { getShopifySessionToken } from "../../../utils/authUtils";
import { InfoIcon } from "@shopify/polaris-icons";
import { DowngradeConfirmation } from "../DowngradeConfirmation";
import { Redirect } from '@shopify/app-bridge/actions';
import { planMapping } from "../../../utils/constant";

const Plans = (props) => {
  const { dashboardData, appName, plansOptions, freeHeading, primeHeading } = props

  const app = useAppBridge();
  const redirect = Redirect.create(app);
  const fetchDashboardData = get(dashboardData, "fetchStatus", "IDLE");
  const currentPlan = get(dashboardData, "data.shop.plan", "") || "";
  const [showDowngradeModal, setShowDowngradeModal] = useState(false);
  const [showLoader, setShowLoader] = useState({});


  const selectPlanHandler = async (planName) => {
    const selectedPlanName = planMapping[appName][planName]
    setShowLoader({ [planName]: true });
    const token = await getShopifySessionToken(app);
    redirect.dispatch(Redirect.Action.REMOTE, { url: `https://zegsuapps.com/shopify/${appName}/pricing/${selectedPlanName}?authToken=${token}` })

  }

  const upgradePlanHandler = async (planName) => {
    const selectedPlanName = planMapping[appName][planName]
    setShowLoader({ [planName]: true });
    const token = await getShopifySessionToken(app);
    redirect.dispatch(Redirect.Action.REMOTE, {
      url:
        `https://zegsuapps.com/shopify/${appName}/change-plan/${selectedPlanName}?authToken=${token}`
    })
  }

  const fetchingData = fetchDashboardData === 'PENDING';

  const RenderButtonForPlus = useCallback(() => {
    const modifiedCurrentPlan = String(currentPlan).toLowerCase();
    if (!modifiedCurrentPlan) {
      return <Button
        loading={fetchingData || showLoader['PRIME']}
        fullWidth variant="primary"
        onClick={() => selectPlanHandler('PRIME')}>Get started</Button>
    } else if (modifiedCurrentPlan === "prime") {
      return <Button
        loading={fetchingData || showLoader['PRIME']}
        fullWidth disabled>Current Plan</Button>
    } else {
      return <Button
        loading={fetchingData || showLoader['PRIME']}
        fullWidth variant="primary" onClick={() => upgradePlanHandler('PRIME')}>Upgrade to Plus</Button>
    }
  }, [currentPlan, showLoader]);

  const RenderButtonForFree = useCallback(() => {
    const modifiedCurrentPlan = String(currentPlan).toLowerCase();
    if (!modifiedCurrentPlan) {
      return <Button
        loading={fetchingData || showLoader['FREE']}
        fullWidth onClick={() => selectPlanHandler('FREE')}>Try for free</Button>
    } else if (modifiedCurrentPlan === "free") {
      return <Button
        loading={fetchingData || showLoader['FREE']}
        fullWidth>Current Plan</Button>
    } else {
      return <Button
        loading={fetchingData || showLoader['FREE']}
        fullWidth onClick={() => setShowDowngradeModal(true)}>Downgrade to Free</Button>
    }
  }, [currentPlan, showLoader])

  const closeDowngradeModal = () => {
    setShowDowngradeModal(false);
  }

  return (
    <Page>
      <Layout>
        <Layout.Section>
          <InlineStack align="space-between">
            <Text variant="headingMd" as="h6" >
              Pricing
            </Text>
          </InlineStack>

          <div className={classes.subHeadingWrapperCss}>
            <Text as="p" tone="subdued">
              Pick a plan based on the features you need
            </Text>
          </div>
        </Layout.Section>
        <Layout.Section>
          <div className={classes.planWrapperCss}>
            {plansOptions.map((plan) => (
              <div className={classes.planItemCss}>
                <InlineStack gap="200">
                  <Text variant="headingMd" as="h6">
                    {plan.name}
                  </Text>
                  {plan.popular && <Badge tone="attention">Recommended</Badge>}
                </InlineStack>
                <div className={classes.priceTextWrapCss}>
                  <BlockStack blockAlign="center">
                    <InlineStack blockAlign="center">
                      <Text variant="heading2xl" as="h3">
                        ${plan.priceMonthly}
                      </Text>
                      <Text as="p" tone="subdued">
                        /month
                      </Text>
                    </InlineStack>
                  </BlockStack>
                </div>
                <div className={classes.priceDescWrapperCss}>
                  <Text as="p" tone="subdued">
                    {plan.name === "Free"
                      ? freeHeading
                      : primeHeading}
                  </Text>
                </div>
                {plan.name === "Free" ? <RenderButtonForFree /> : <RenderButtonForPlus />}
                <div className={classes.featureWrapCss}>
                  <ul className={classes.featureBulletPointsCss}>
                    {plan.features.map((feature, index) => (
                      <li key={index}>
                        <InlineStack gap="200" wrap={false}>
                          <span
                            style={{
                              color: "#E8546E"
                            }}
                          >
                            ✔
                          </span>
                          <InlineStack gap="100"
                          >
                            <Text as="p" >
                              {feature.name}
                            </Text>
                            {
                              feature.subheading && <Tooltip content={feature.subheading} width="wide">
                                <Icon source={InfoIcon} />
                              </Tooltip>
                            }

                          </InlineStack>
                        </InlineStack>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </Layout.Section>
      </Layout>
      {
        showDowngradeModal && <DowngradeConfirmation
          appName={appName}
          open={showDowngradeModal}
          onClose={closeDowngradeModal}
        />
      }
    </Page>
  );
};

export default Plans;

