import { useAppBridge, useToast } from '@shopify/app-bridge-react';
import { Modal, TextContainer } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import { resetUpdateConfigState, updateAppConfig } from '../../../slices/InstaGenieSlices/appConfigSlice';
import { fetchDashboardData } from '../../../slices/InstaGenieSlices/dashboardSlice';

export function AppEnableConfig(props) {

  const dispatch = useDispatch();
  const app = useAppBridge();
  const { show } = useToast();
  const { open, onClose, widgetEnabled } = props;
  const { fetchStatus } = useSelector(
    (state) => state.updateAppConfigModal
  );

  const handleConfirm = () => {
    const state = widgetEnabled ? "disable" : "enable";
    dispatch(resetUpdateConfigState());
    dispatch(updateAppConfig({ state, app }))
      .then(() => {
        show(`App ${state}d `)
        dispatch(fetchDashboardData({ app }))
        onClose();
      }).catch((e) => {
        show('Something went wrong!')
      });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Update app configuration"
      primaryAction={{
        content: 'Confirm',
        onAction: handleConfirm,
        loading: fetchStatus === 'pending'
      }}
      secondaryActions={[
        {
          content: 'Discard',
          onAction: onClose,
          disabled: fetchStatus === "pending",
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>
            Are you sure you want to {widgetEnabled ? 'disable' : 'enable'} the app?
          </p>
        </TextContainer>
      </Modal.Section>
    </Modal>

  );
}
