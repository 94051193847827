export const extractIdFromEventDetail = (event) => {
    if (event && event.detail && event.detail.id) {
        return event.detail.id;
    }
    return false
}
export const extractIdsFromEventDetail = (event) => {
    const ids = {}
    if (event && event.detail && event.detail.id) {
        ids['mediaId'] = event.detail.id;
    }

    if (event && event.detail && event.detail.productId) {
        ids['productId'] = event.detail.productId;
    }
    return ids;
}