import React from "react";

const ExpandedRow = ({ variants }) => {
    return (
        <tr style={{ cursor: 'default' }}>
            <td colSpan={7}>
                <div style={{ margin: '8px' }}>
                    <table style={{ width: "100%" }}>
                        <thead>
                            <tr >
                                <th className="Polaris-IndexTable__TableHeading Polaris-IndexTable__TableHeading" align="start">Product variant</th>
                                <th className="Polaris-IndexTable__TableHeading Polaris-IndexTable__TableHeading" align="start">Inventory</th>
                                <th className="Polaris-IndexTable__TableHeading Polaris-IndexTable__TableHeading" align="start">Units sold</th>
                                <th className="Polaris-IndexTable__TableHeading Polaris-IndexTable__TableHeading" align="start">Net quantity</th>
                            </tr>
                        </thead>
                        <tbody>
                            {variants.map((variant, index) => (
                                <tr key={index} className="Polaris-IndexTable__TableRow" style={{ cursor: 'default' }}>
                                    <td className="Polaris-IndexTable__TableCell">{variant.title}</td>
                                    <td className="Polaris-IndexTable__TableCell">{variant.available}</td>
                                    <td className="Polaris-IndexTable__TableCell">{variant.sold}</td>
                                    <td className="Polaris-IndexTable__TableCell">{(Number(variant.available) - Number(variant.sold))}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {/* </Card> */}
                </div>
            </td>
        </tr>
    );
};

export default ExpandedRow;
