// rootReducer.js
import { combineReducers } from '@reduxjs/toolkit';
import dashboardSlice from '../slices/InstaGenieSlices/dashboardSlice';
import mediaControlSlice from '../slices/InstaGenieSlices/mediaControlSlice';
import updateFormSlice from '../slices/InstaGenieSlices/updateFormSlice';
import contactFormSlice from '../slices/InstaGenieSlices/contactFormSlice';
import mediaLinkSlice from '../slices/InstaGenieSlices/mediaSlice';
import socialMediaAccountSlice from '../slices/InstaGenieSlices/socialMediaAccountSlice';
import accountModalSlice from '../slices/InstaGenieSlices/accountModalSlice';
import updateAppConfigSlice from '../slices/InstaGenieSlices/appConfigSlice';

const instagenieReducer = combineReducers({
  dashboard: dashboardSlice,
  mediaControl: mediaControlSlice,
  formDetails: updateFormSlice,
  contactForm: contactFormSlice,
  media: mediaLinkSlice,
  socialMediaAccounts: socialMediaAccountSlice,
  accountListModal: accountModalSlice,
  updateAppConfigModal: updateAppConfigSlice
});

export default instagenieReducer;
