import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
const TableSkeleton = () => {
    const rows = 15; // Number of rows to render
    return (
        <div style={{ width: '100%', overflowX: 'auto' }}>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                    <tr>
                        {Array(6).fill().map((_, index) => (
                            <th
                                key={index}
                                style={{
                                    padding: '10px',
                                    border: '1px solid #ddd',
                                    textAlign: 'left' // Left-align the heading
                                }}
                            >
                                <Skeleton height={30} width={100} />
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {Array(rows).fill().map((_, rowIndex) => (
                        <tr key={rowIndex}>
                            {Array(6).fill().map((_, colIndex) => (
                                <td
                                    key={colIndex}
                                    style={{
                                        padding: '10px',
                                        border: '1px solid #ddd',
                                        textAlign: 'left' // Left-align the cell content
                                    }}
                                >
                                    <Skeleton height={20} width={100} />
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};
export default TableSkeleton;