// ImageList.js
import React from "react";
import styles from "./styles.module.css";
import { Card } from "@shopify/polaris";

const ImageSkeletonLoader = () => {
  return (
    <div className={styles.imageCard}>
      <div className={`${styles.imageSkeleton} ${styles.autoFadeIn}`}></div>
    </div>
  );
};

const MediaControlSkeleton = () => {
  const images = [
    { id: 1, url: "https://example.com/image1.jpg" },
    { id: 2, url: "https://example.com/image2.jpg" },
    { id: 3, url: "https://example.com/image1.jpg" },
    { id: 4, url: "https://example.com/image2.jpg" },
    { id: 5, url: "https://example.com/image1.jpg" },
    { id: 6, url: "https://example.com/image2.jpg" },
    { id: 7, url: "https://example.com/image1.jpg" },
    { id: 8, url: "https://example.com/image2.jpg" },
  ];

  return (
    <Card>
      <div className={styles.imageList}>
        {images.map((image, index) => (
          <ImageSkeletonLoader key={image.id} index={index} />
        ))}
      </div>
    </Card>
  );
};

export default MediaControlSkeleton;
