import React, { useState } from "react";
import styles from "./styles.module.css";
import { Button, Card, Popover, Text } from "@shopify/polaris";
import { LockIcon } from "@shopify/polaris-icons";
import { useAppBridge } from "@shopify/app-bridge-react";
import { Redirect } from '@shopify/app-bridge/actions';
import { getShopifySessionToken } from "../../../../utils/authUtils";

const Tabs = ({ tabs }) => {
    const app = useAppBridge();
    const redirect = Redirect.create(app);
    const [selectedTab, setSelectedTab] = useState(0);
    const [active, setActive] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    const togglePopoverActive = () => setActive((prev) => !prev);

    const upgradePlanHandler = async () => {
        const selectedPlanName = 'PRIME'
        setShowLoader(true);
        const token = await getShopifySessionToken(app);
        redirect.dispatch(Redirect.Action.REMOTE, {
            url:
                `https://zegsuapps.com/shopify/sold-counter/change-plan/${selectedPlanName}?authToken=${token}`
        })
    }

    const handleTabSelection = (disabled, index) => {
        if (disabled) return;
        setSelectedTab(index)
    }

    return (
        <React.Fragment>
            <div>
                <div className={styles.tabsContainer}>
                    {tabs.map((tab, index) => (
                        <div
                            key={index}
                            className={`${styles.tab} ${selectedTab === index ? styles.active : ""
                                }`}
                            onClick={() => handleTabSelection(tab.disabled, index)} // Set selected tab on click
                        >
                            {tab.icon ? (
                                <Popover
                                    active={active}
                                    activator={
                                        <div
                                            className={styles.iconTextWrapperCss}
                                            onClick={togglePopoverActive}
                                            disclosure
                                        >
                                            <div className={styles.textCss}>{tab.label}</div>
                                            <div className={styles.iconWrapperCss}>{tab.icon}</div>
                                        </div>
                                    }
                                    autofocusTarget="first-node"
                                    onClose={togglePopoverActive}
                                >
                                    <Card>
                                        <div>
                                            <Text fontWeight="medium">
                                                To use this feature please upgrade to plus
                                            </Text>
                                            <div className={styles.plusButtonWrapper}>
                                                <Button icon={LockIcon} variant="primary"
                                                    onClick={upgradePlanHandler}
                                                    disabled={showLoader}
                                                    loading={showLoader}>
                                                    Unlock feature
                                                </Button>

                                            </div>
                                        </div>
                                    </Card>
                                </Popover>
                            ) : (
                                <div className={styles.textCss}>{tab.label}</div>
                            )}
                        </div>
                    ))}
                </div>

                <div className={styles.tabContent}>
                    {tabs[selectedTab].content} {/* Show content based on selected tab */}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Tabs;
