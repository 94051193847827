import { FormLayout, Select, TextField, RangeSlider } from "@shopify/polaris";
import React from "react";
import { trueFalseOptions } from "../constant";
import ColorPickerInput from "../../ColorPickerInput";
import { isDateGreaterThanNow } from "../../../../../utils/helper";
const CountDownSettingForm = ({ value, error, onChange, onError }) => {

    const handleInputChange = (field) => (newValue) => {
        onChange(field, newValue);
        // if (typeof newValue === "string" && newValue.trim() === "") {
        //     onError(field, "This field cannot be empty");
        // } else {
        //     onError(field, null);
        // }
    };

    const handleNumberInputChange = (field) => (newValue) => {
        onChange(field, newValue)
        if (isNaN(Number(newValue))) {
            onError(field, "Only numbers are allowed")
        }
    }

    console.log(value, "-->from count down setting form")
    return (
        <FormLayout>
            <Select
                label="Enable widget"
                options={trueFalseOptions}
                onChange={handleInputChange("enableWidget")}
                value={value.enableWidget === "true" || value.enableWidget === true ? "true" : "false"}
                error={error.enableWidget}
            />

            <Select
                label="Countdown type"
                options={[
                    {
                        label: "Ring countdown",
                        value: "RING",
                    },
                    {
                        label: "Flip countdown",
                        value: "FLIP",
                    },
                ]}
                onChange={handleInputChange("countDownType")}
                value={value.countDownType}
                error={error.enableWidget}
            />

            {value.countDownType === "FLIP" && (
                <FormLayout>
                    <TextField
                        label="Sales end text"
                        value={value.flipSaleEndsInText}
                        autoComplete="off"
                        fullWidth={true}
                        onChange={handleInputChange("flipSaleEndsInText")}
                    />
                    <ColorPickerInput
                        label="Sale end text color"
                        name="flipSaleEndsTextColor"
                        value={value.flipSaleEndsTextColor}
                        key={value.flipSaleEndsTextColor}
                        onChange={handleInputChange}
                    />
                    <ColorPickerInput
                        name="flipPrimaryColor"
                        label="Primary color"
                        value={value.flipPrimaryColor}
                        key={value.flipPrimaryColor}
                        onChange={handleInputChange}
                    />

                    <ColorPickerInput
                        name="flipSecondaryColor"
                        label="Secondary color"
                        value={value.flipSecondaryColor}
                        key={value.flipSecondaryColor}
                        onChange={handleInputChange}
                    />
                    <ColorPickerInput
                        name="flipRotorHingeColor"
                        label="Rotor hinge color"
                        value={value.flipRotorHingeColor}
                        key={value.flipRotorHingeColor}
                        onChange={handleInputChange}
                    />
                    <Select
                        label="Timer type"
                        options={[
                            {
                                label: "Seconds",
                                value: "SEC",
                            },
                            {
                                label: "Date",
                                value: "DATE",
                            },
                        ]}
                        onChange={handleInputChange("flipTimerType")}
                        value={value.flipTimerType}
                        error={error.flipTimerType}
                    />
                    {value.flipTimerType === "SEC" ? (
                        <TextField
                            label="Time in seconds"
                            value={value.flipTimerSecs}
                            error={error.flipTimerSecs}
                            autoComplete="off"
                            fullWidth={true}
                            helpText="Only numbers allowed"
                            onChange={handleNumberInputChange('flipTimerSecs')}
                        />
                    ) : (
                        <TextField
                            label="Times in date"
                            autoComplete="off"
                            fullWidth={true}
                            type="datetime-local"
                            value={value && value.flipTimerToDate !== null && value.flipTimerToDate !== "undefined"
                                ? value.flipTimerToDate.slice(0, 16)
                                : new Date().toISOString().slice(0, 16)}
                            onError={isDateGreaterThanNow(value.flipTimerToDate)}
                            helpText="Must be a future date time."
                            onChange={handleInputChange("flipTimerToDate")}
                        />
                    )}
                    <RangeSlider
                        output
                        label="Size"
                        min={4}
                        max={10}
                        value={value.flipSize}
                        onChange={handleInputChange("flipSize")}
                        prefix={<p>4</p>}
                        suffix={
                            <p
                                style={{
                                    minWidth: '24px',
                                    textAlign: 'right',
                                }}
                            >
                                {10}
                            </p>
                        }
                    />

                </FormLayout>
            )}

            {value.countDownType === "RING" && (
                <FormLayout>
                    <ColorPickerInput
                        name="countDownBarColor"
                        label="Ring Color"
                        value={value.countDownBarColor}
                        key={value.countDownBarColor}
                        onChange={handleInputChange}
                    />

                    <ColorPickerInput
                        name="countDownBarBackground"
                        label="Ring BG Color"
                        value={value.countDownBarBackground}
                        key={value.countDownBarBackground}
                        onChange={handleInputChange}
                    />

                    <ColorPickerInput
                        name="countDownTextColor"
                        label="Text color"
                        value={value.countDownTextColor}
                        key={value.countDownTextColor}
                        onChange={handleInputChange}
                    />

                    <Select
                        label="Timer type"
                        options={[
                            {
                                label: "Seconds",
                                value: "SEC",
                            },
                            {
                                label: "Date",
                                value: "DATE",
                            },
                        ]}
                        onChange={handleInputChange("countDownTimerType")}
                        value={value.countDownTimerType}
                        error={error.countDownTimerType}
                    />
                    {value.countDownTimerType === "SEC" ? (
                        <TextField
                            label="Time in seconds"
                            value={String(value.countDownTimerSecs)}
                            error={error.countDownTimerSecs}
                            autoComplete="off"
                            fullWidth={true}
                            helpText="Only numbers allowed"
                            onChange={handleNumberInputChange('countDownTimerSecs')}
                        />
                    ) : (
                        <TextField
                            label="Times in date"
                            autoComplete="off"
                            fullWidth={true}
                            value={value && value.countDownTimerToDate !== null && value.countDownTimerToDate !== "undefined"
                                ? value.countDownTimerToDate.slice(0, 16)
                                : new Date().toISOString().slice(0, 16)}
                            type="datetime-local"
                            onError={isDateGreaterThanNow(value.countDownTimerToDate)}
                            helpText="Must be a future date time."
                            onChange={handleInputChange("countDownTimerToDate")}
                        />
                    )}
                    <FormLayout.Group>
                        <RangeSlider
                            output
                            label="Ring size"
                            min={1}
                            max={10}
                            value={value.countDownSize}
                            onChange={handleInputChange("countDownSize")}
                            prefix={<p>7</p>}
                            suffix={
                                <p
                                    style={{
                                        minWidth: '24px',
                                        textAlign: 'right',
                                    }}
                                >
                                    {10}
                                </p>
                            }
                        />
                        <RangeSlider
                            output
                            label="Ring thickness"
                            min={1}
                            max={20}
                            value={value.countDownRingWidth}
                            onChange={handleInputChange("countDownRingWidth")}
                            prefix={<p>1</p>}
                            suffix={
                                <p
                                    style={{
                                        minWidth: '24px',
                                        textAlign: 'right',
                                    }}
                                >
                                    {20}
                                </p>
                            }
                        />
                    </FormLayout.Group>

                </FormLayout>
            )}
        </FormLayout>
    );
};

export default CountDownSettingForm;
