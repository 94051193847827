import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'; // Import the CSS file
const FormSkeleton = ({ rows }) => {
    const baseColor = '#ddd';
    const highlightColor = '#eee';
    return (
        <div >
            {rows.map((row, rowIndex) => (
                <div key={rowIndex} style={{ display: 'flex', marginBottom: '10px' }}>
                    {row.map((input, inputIndex) => {
                        if (input === 'full') {
                            return (
                                <div key={inputIndex} style={{ flex: 1, marginRight: '10px' }}>
                                    <Skeleton width="100%" height={40} baseColor={baseColor}
                                        highlightColor={highlightColor} />
                                </div>
                            );
                        } else if (input === 'half') {
                            return (
                                <div key={inputIndex} style={{ flex: 1, marginRight: '10px' }}>
                                    <Skeleton width="100%" height={40} baseColor={baseColor}
                                        highlightColor={highlightColor} />
                                </div>
                            );
                        }
                        return null;
                    })}
                </div>
            ))}
        </div>
    );
};
export default FormSkeleton