import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Plans from "../../../components/Plan/Plans";
import { fetchDashboardData } from "../slices/SoldCounterSlices/dashboardSlice";
import { plansOptions } from "../utils/helper"
import { useAppBridge } from "@shopify/app-bridge-react";
import {apps} from "../../../utils/constant";

const SoldCounterPlanPage = () => {
    const app = useAppBridge();
    const dashboardData = useSelector((state) => state.dashboard);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchDashboardData({ app }));
    }, []);

    return (
        <Plans
            dashboardData={dashboardData}
            appName="sold-counter"
            plansOptions={plansOptions}
            freeHeading="Basic features to help showcase Sold Count on your store"
            primeHeading="Premium features to customize Sold Count & skyrocket your sales."
        />
    );
};

export default SoldCounterPlanPage;
