import {BUILD_HASH, DEPLOYMENT_ENV, BUILD_BRANCH} from "../../settings";

const Version = () => {
    const style = {
        position: "fixed",
        top: "10px",
        right: "20px",
        zIndex: 99999,
        background: "#504f4e38",
        padding: "5px",
        color: "#ffff",
        borderRadius: "5px"
    }
    if(DEPLOYMENT_ENV === "beta") {
        return (
            <div style={style}>
                <span>Beta: {BUILD_HASH}({BUILD_BRANCH})</span>
            </div>
        )
    } else {
        return <></>
    }
}

export default Version;