import React, { useEffect, useState } from "react";
import {
    Card,
    Button,
    InlineStack,
    Text,
    Avatar,
    Badge,
    EmptyState,
    Pagination,
    Checkbox,
    Link,
    Thumbnail, Box,
} from "@shopify/polaris";
import { ChevronDownIcon, ChevronUpIcon, ImageIcon } from "@shopify/polaris-icons";
import TableHeader from "./Header/index";
import ExpandedRow from "./ExpandRow";
import { extractVariantInfo } from "../../utils/helper";

import classes from "./styles.module.css";
import { fetchStatus } from "../../../../utils/constant";
import TableSkeleton from "./Skelton/tableSkelton";
import { useNavigate } from "react-router-dom";

const StockTable = (props) => {
    const {
        selectedProductIds,
        setSelectedProductIds,
        productFetchStatus,
        totalProductLength,
        product,
        setSearchValue,
        setCurrentPage,
        setSearching,
        currentPage,
        setExpandedRows,
        searching,
        searchValue,
        expandedRows,
        currentPlan,
        widgetEnabledCountState,
    } = props;
    const [initialLoading, setInitialLoading] = useState(true);
    const pageSize = Number(props.pageSize);

    useEffect(() => {
        if (productFetchStatus === fetchStatus.resolved) {
            setInitialLoading(false);
        }
    }, [productFetchStatus]);

    const handleCheckbox = (checked, id) => {
        if (checked) {
            setSelectedProductIds((prev) => [...prev, id]);
        } else {
            const updateProductIds = selectedProductIds.slice();
            const filteredResult = updateProductIds.filter(
                (storedID) => id !== storedID
            );
            setSelectedProductIds(filteredResult);
        }
    };
    const handleSearchClick = () => {
        setSearching(true);
    };

    const handleCancelClick = () => {
        setSearching(false);
        setSearchValue("");
    };

    const handleSearchChange = (value) => {
        if (currentPage > 0) {
            setCurrentPage(0);
        }
        setSearchValue(value);
    };

    const toggleRow = (rowId) => {
        setExpandedRows((prevState) => ({
            ...prevState,
            [rowId]: !prevState[rowId],
        }));
    };

    const currentStartIndex = currentPage * pageSize + 1;
    const currentEndIndex = Math.min(
        (currentPage + 1) * pageSize,
        totalProductLength
    );

    const bulkSelectHandler = (checked) => {
        if (checked) {
            const ids = product.map(({ id }) => id);
            setSelectedProductIds(ids);
        } else {
            setSelectedProductIds([]);
        }
    };

    const decideToDisable = (data) => {
        if (widgetEnabledCountState < 3) {
            return false
        } else {
            return !data.widgetEnabled;
        }
    }

    const result = `${currentStartIndex}-${currentEndIndex} of ${totalProductLength} products`;

    return (
        <React.Fragment>
            {initialLoading ? <TableSkeleton /> :

                <Card padding={0}>
                    <TableHeader
                        searching={searching}
                        searchValue={searchValue}
                        handleSearchClick={handleSearchClick}
                        handleCancelClick={handleCancelClick}
                        handleSearchChange={handleSearchChange}
                    />
                    <table className="Polaris-IndexTable__Table">
                        <thead>
                            <tr>
                                <th className="Polaris-IndexTable__TableHeading Polaris-IndexTable__TableHeading">
                                    <Checkbox
                                        disabled={currentPlan === 'BASIC'}
                                        checked={
                                            selectedProductIds.length === product.length
                                                ? true
                                                : selectedProductIds.length === 0
                                                    ? false
                                                    : "indeterminate"
                                        }
                                        onChange={bulkSelectHandler}
                                    />
                                </th>
                                <th className="Polaris-IndexTable__TableHeading Polaris-IndexTable__TableHeading">
                                    Product
                                </th>
                                <th className="Polaris-IndexTable__TableHeading Polaris-IndexTable__TableHeading">
                                    Inventory
                                </th>
                                <th className="Polaris-IndexTable__TableHeading Polaris-IndexTable__TableHeading">
                                    Units sold
                                </th>
                                <th className="Polaris-IndexTable__TableHeading Polaris-IndexTable__TableHeading">
                                    Widget
                                </th>
                                <th className="Polaris-IndexTable__TableHeading Polaris-IndexTable__TableHeading">
                                    Countdown
                                </th>
                                <th className="Polaris-IndexTable__TableHeading Polaris-IndexTable__TableHeading">
                                    Aggregate variant counts
                                </th>
                                <th className="Polaris-IndexTable__TableHeading Polaris-IndexTable__TableHeading"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {product.length === 0 &&
                                !searching &&
                                productFetchStatus === fetchStatus.resolved && (
                                    <tr>
                                        <td colSpan={6} style={{ textAlign: "center" }}>
                                            <EmptyState
                                                heading="No result found, try adjusting your search"
                                                image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
                                            ></EmptyState>
                                        </td>
                                    </tr>
                                )}

                            {product.length === 0 && searching && fetchStatus.resolved && (
                                <tr>
                                    <td colSpan={6} style={{ textAlign: "center" }}>
                                        <EmptyState
                                            heading="No result found, try adjusting your search"
                                            image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
                                        ></EmptyState>
                                    </td>
                                </tr>
                            )}
                            {product.length > 0 &&
                                product.map((product) => {
                                    const { totalVariants, totalInStock, totalUnitsSold } =
                                        extractVariantInfo(product.variants);
                                    const disableRow = currentPlan === 'BASIC' ? decideToDisable(product) : false;
                                    return (
                                        <React.Fragment key={product.id}>
                                            <tr className="Polaris-IndexTable__TableRow" style={{ cursor: 'default', background: disableRow ? '#dedede' : '' }}>
                                                <td className="Polaris-IndexTable__TableCell Polaris-IndexTable__TableCell--first">
                                                    <Checkbox
                                                        disabled={disableRow}
                                                        checked={selectedProductIds.includes(product.id)}
                                                        onChange={(checked) =>
                                                            handleCheckbox(checked, product.id)
                                                        }
                                                    />
                                                </td>

                                                <td className="Polaris-IndexTable__TableCell Polaris-IndexTable__TableCell--second">
                                                    <InlineStack
                                                        gap={200}
                                                        as="div"
                                                        direction="row"
                                                        blockAlign="center"
                                                    >
                                                        <Thumbnail
                                                            transparent={true}
                                                            size="small"
                                                            source={product.images && product.images.length > 0 ? product.images[0] : ImageIcon}
                                                            alt={product.title}
                                                        />
                                                        <Box width="200px" overflow="hidden">
                                                            <Link url={product.link} monochrome removeUnderline external>
                                                                <Text as="span" truncate>{product.title}</Text>
                                                            </Link>
                                                        </Box>
                                                    </InlineStack>
                                                </td>
                                                <td className="Polaris-IndexTable__TableCell ">
                                                    {totalInStock} in stock / {totalVariants} variants
                                                </td>
                                                <td className="Polaris-IndexTable__TableCell">
                                                    {totalUnitsSold}
                                                </td>
                                                <td className="Polaris-IndexTable__TableCell">
                                                    {product.widgetEnabled ? (
                                                        <Badge tone={disableRow ? 'default' : "success"}>Active</Badge>
                                                    ) : (
                                                        <Badge>Inactive</Badge>
                                                    )}
                                                </td>
                                                <td className="Polaris-IndexTable__TableCell">
                                                    {product.countdownEnabled ? (
                                                        <Badge tone={disableRow ? 'default' : "success"}>Active</Badge>
                                                    ) : (
                                                        <Badge>Inactive</Badge>
                                                    )}
                                                </td>
                                                <td className="Polaris-IndexTable__TableCell">
                                                    {product.aggregateVariants ? (
                                                        <Badge tone={disableRow ? 'default' : "success"}>Active</Badge>
                                                    ) : (
                                                        <Badge>Inactive</Badge>
                                                    )}
                                                </td>
                                                <td>
                                                    <InlineStack>
                                                        <td className="Polaris-IndexTable__TableCell Polaris-IndexTable__TableCell--first">
                                                            <Button
                                                                disabled={disableRow}
                                                                onClick={() => toggleRow(product.id)}
                                                                icon={
                                                                    expandedRows[product.id]
                                                                        ? ChevronUpIcon
                                                                        : ChevronDownIcon
                                                                }
                                                                variant="plain"
                                                            />
                                                        </td>
                                                        <div
                                                            style={{
                                                                display: "b",
                                                                opacity: "0",
                                                                width: "12px",
                                                                height: "0px",
                                                                background: "red",
                                                            }}
                                                        />
                                                    </InlineStack>
                                                </td>
                                            </tr>
                                            {expandedRows[product.id] && (
                                                <ExpandedRow variants={product.variants} />
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                        </tbody>
                    </table>
                    {product.length > 0 && (
                        <div className={classes.paginationWrapperCss}>
                            <Pagination
                                hasNext={currentEndIndex < totalProductLength}
                                hasPrevious={currentPage > 0}
                                onPrevious={() => {
                                    if (currentPage > 0) {
                                        setCurrentPage((prev) => prev - 1);
                                    }
                                }}
                                onNext={() => {
                                    if (currentEndIndex < totalProductLength) {
                                        setCurrentPage((prev) => prev + 1);
                                    }
                                }}
                                type="table"
                                label={result}
                            />
                        </div>
                    )}
                </Card>
            }
        </React.Fragment>
    );
};

export default StockTable;
