import React from "react";
import {
    Box,
    Button,
    InlineStack,
    TextField,
} from "@shopify/polaris";
import { SearchIcon } from "@shopify/polaris-icons";
import classes from "./styles.module.css";
const Header = ({
    searching,
    searchValue,
    handleSearchClick,
    handleCancelClick,
    handleSearchChange,
}) => {
    return (
        <Box fullWidth>
            {searching ? (
                <div className={classes.headerWrapperCss}>
                    <TextField
                        value={searchValue}
                        onChange={handleSearchChange}
                        placeholder="Search"
                        autoFocus
                        fullWidth
                        clearButton
                    />
                    <Button onClick={handleCancelClick}>Cancel</Button>
                </div>
            ) : (
                <InlineStack align="end">
                    <Box padding={200}>
                        <Button
                            icon={SearchIcon}
                            onClick={handleSearchClick}
                            accessibilityLabel="Search"
                            size="slim"
                        />
                    </Box>
                </InlineStack>
            )}
        </Box>
    );
};

export default Header;