import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Plans from "../../../components/Plan/Plans";
import { fetchDashboardData } from "../slices/InstaGenieSlices/dashboardSlice";
import { plansOptions } from "../utils/constant";
import { useAppBridge } from "@shopify/app-bridge-react";
import {apps} from "../../../utils/constant";
const InstageniePlanPage = () => {
    const app = useAppBridge();
    const dashboardData = useSelector((state) => state.dashboard);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchDashboardData({ app }));
    }, []);

    return (
        <Plans
            dashboardData={dashboardData}
            appName="instagenie"
            plansOptions={plansOptions}
            freeHeading="Basic features to help showcase Instagram Feed in your store."
            primeHeading="Premium features to customize your Instagram Feed & skyrocket your sales."
        />
    );
};

export default InstageniePlanPage;
