// Assuming you have Redux Toolkit set up

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { fetchStatus } from '../../utils/constant';
import { getShopifySessionToken } from '../../../../utils/authUtils';
import { INSTAGENIE_BASE_URL } from "../../../../settings";
// Define the initial state
const initialState = {
  linkMedia: {
    fetchStatus: fetchStatus.idle,
    error: null,
  },
  unlinkMedia: {
    fetchStatus: fetchStatus.idle,
    error: null,
  },
};

// Create an async thunk to handle the PUT request
export const linkMedia = createAsyncThunk(
  'widget/linkMedia',
  async ({ payload, app }) => {
    try {
      const bearerToken = await getShopifySessionToken(app);
      const response = await axios.put(
        `${INSTAGENIE_BASE_URL}/media/link`,
        payload,
        {
          ...(bearerToken && { headers: { Authorization: `Bearer ${bearerToken}` } }),
        }
      );
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  }
);

export const unlinkMedia = createAsyncThunk(
  'widget/unlinkMedia',
  async ({ payload, app }) => {
    try {
      const bearerToken = await getShopifySessionToken(app);
      const response = await axios.put(
        `${INSTAGENIE_BASE_URL}/media/unlink`,
        payload,
        {
          ...(bearerToken && { headers: { Authorization: `Bearer ${bearerToken}` } }),
        }
      );
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  }
);

// Create a slice
const mediaSlice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    resetLinkMedia: (state) => {
      state.linkMedia.fetchStatus = fetchStatus.idle;
      state.linkMedia.error = null;
    },
    resetUnLinkMedia: (state) => {
      state.linkMedia.fetchStatus = fetchStatus.idle;
      state.linkMedia.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(linkMedia.pending, (state) => {
        state.linkMedia.fetchStatus = fetchStatus.pending;
      })
      .addCase(linkMedia.fulfilled, (state) => {
        state.linkMedia.fetchStatus = fetchStatus.resolved;
      })
      .addCase(linkMedia.rejected, (state, action) => {
        state.linkMedia.fetchStatus = fetchStatus.rejected;
        state.linkMedia.error = action.error.message;
      })

      .addCase(unlinkMedia.pending, (state) => {
        state.unlinkMedia.fetchStatus = fetchStatus.pending;
      })
      .addCase(unlinkMedia.fulfilled, (state) => {
        state.unlinkMedia.fetchStatus = fetchStatus.resolved;
      })
      .addCase(unlinkMedia.rejected, (state, action) => {
        state.unlinkMedia.fetchStatus = fetchStatus.rejected;
        state.unlinkMedia.error = action.error.message;
      });
  },
});

export default mediaSlice.reducer;
export const { resetLinkMedia, resetUnLinkMedia } = mediaSlice.actions; // Corrected action name
