import React from 'react';
import ContactUs from '../../../components/Contact/ContactUs';
import { useDispatch, useSelector } from 'react-redux';
import { contactUsDetails, resetContactFormDetails } from '../slices/SoldCounterSlices/contactFormSlice';
const SoldCounterContactPage = () => {
    const contactUsUploadStatus = useSelector((state) => state.contactForm.fetchStatus);
    const dispatch = useDispatch();
    return (
        <ContactUs
            contactUsUploadStatus={contactUsUploadStatus}
            contactUsDetails={contactUsDetails}
            resetContactFormDetails={resetContactFormDetails}
            dispatch={dispatch}

        />
    )
}

export default SoldCounterContactPage;