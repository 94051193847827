import React from 'react';
import classes from './styles.module.css';
import { Badge, Banner, Spinner, Text, Icon, InlineStack } from '@shopify/polaris';
import { fetchStatus } from '../../../../../utils/constant';
import { MaximizeIcon, MinimizeIcon } from '@shopify/polaris-icons';
const WidgetWrapper = (props) => {
    const { isWidgetVisible = true,
        dashboardFetchStatus,
        showBadge = true,
        minimize = false,
        handleMinimize = () => { }
    } = props;
    return (
        <div className={classes.widgetWrapperCss}>
            {showBadge &&
                <InlineStack align='space-between' direction='row' as="div">
                    <div>   <Badge tone='success'>Widget preview</Badge></div>
                    <div className={classes.expandBtnCss} onClick={handleMinimize}>
                        {minimize ? <Icon source={MinimizeIcon} /> : <Icon source={MaximizeIcon} />}
                    </div>
                </InlineStack>
            }
            {
                dashboardFetchStatus === fetchStatus.pending && <div className={classes.spinLoaderCss}>
                    <Spinner />
                    <Text>Loading widget</Text>
                </div>
            }
            {dashboardFetchStatus === fetchStatus.resolved && isWidgetVisible && <div className={showBadge ? classes.childrenWrapperCss : ''}>
                {props.children}
            </div>
            }

            {
                dashboardFetchStatus === fetchStatus.resolved && !isWidgetVisible &&
                <div className={classes.bannerWrapperCss}>
                    <Banner
                        title='To preview widget please enable it'
                    >
                    </Banner>
                </div>
            }

            {dashboardFetchStatus === fetchStatus.rejected &&
                <div className={classes.bannerWrapperCss}>
                    <Banner
                        title="Failed to load widget"
                        onDismiss={() => { }}
                        tone="critical"

                    >
                        <p>
                            Please refresh the page
                        </p>
                    </Banner>
                </div>
            }

        </div>
    )
}

export default WidgetWrapper;